.faq-outer-container {
	background-color: var(--beige-beige-01);
}

.faq-inner-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	width: 90vw;
}

.faq-inner-container h1 {
	text-align: center;
}

.faq-list-container {
	list-style-type: none;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 90%;
	max-width: 600px;
	margin-top: 50px;
	padding: 0px;
}

.faq-element {
	margin-bottom: 40px;
}

.faq-element h2 {
	text-align: left;
	margin-top: 0px;
	margin-bottom: 16px;
	font-weight: 500;
}

.faq-element p {
	text-align: left;
	margin: 0px;
	white-space: pre-line;
	font-size: 14px;
	line-height: 22px;
}