.neighbourhood-data-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 440px;
}

.neighbourhood-data-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.neighbourhood-score-element-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.neighbourhood-score-title-container {
    padding-top: 48px;
}

.neighbourhood-data-badge {
    padding: 4px 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
}

.neighbourhood-data-row-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.neighbourhood-data-beige-element {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 50%;
    background-color: var(--grayscale-white);
}

.neighbourhood-data-beige-inner-element {
    padding: 8px 16px;
}

.neighbourhood-data-beige-element:first-of-type {
    margin-right: 8px;
}

.neighbourhood-data-divider-line {
    width: 100%;
    background-color: var(--grayscale-gray-02);
    height: 1px;
}

.neighbourhood-data-economics-grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px 16px;
    width: 100%;
}

.neighbourhood-data-economics-container,
.neighbourhood-data-market-container,
.neighbourhood-data-metrics-container,
.neighbourhood-data-property-attributes-container {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.economics-vals-element-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.economics-vals-comparison-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

.economics-vals-inner-comparison-container {
    padding: 4px 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.economics-vals-comparison-icon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.neighbourhood-data-grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px 16px;
    width: 100%;
}

.neighbourhood-data-grid-element {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.neighbourhood-data-grid-element-title-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.neighbourhood-data-tooltip-icon-container {
    margin-left: 4px;
}

.neighbourhood-data-tooltip-icon {
    width: 16px;
}

.neighbourhood-data-outer-container .default-tooltip-container {
    width: 200px;
    right: unset;
    left: -50px;
    top: 20px;
}

.neighbourhood-data-outer-container .neighbourhood-data-tooltip-left-position {
    right: unset;
    left: -200px;
}

.economics-vals-element-container .default-tooltip-container {
    left: -60px;
}

.economics-vals-element-container:first-of-type .default-tooltip-container {
    right: unset;
    left: -100px;
}

.neighbourhood-data-upgrade-lock-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.neighbourhood-data-upgrade-button {
    /* max-width: 165px; */
}

.neighbourhood-data-property-attributes-container {
    padding-bottom: 48px;
}

.neighbourhood-data-overlay-button {
    position: absolute;
    z-index: 1;
    bottom: 0%;
    left: 0px;
    right: 0px;
    margin: auto;
    max-width: 212px;
}

@media only screen and (max-width: 999px) {
    .neighbourhood-data-economics-grid-container {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 24px;
    }
}