.god-mode-outer-container .loading-container {
    position: fixed;
    top: 20%;
    max-width: 150px;
}

.god-mode-outer-row-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
}

.god-mode-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(100% - 248px);
    margin-left: 32px;
    margin-bottom: 120px;
    margin-top: 32px;
    min-height: 80vh;
}

.god-mode-options-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 24px 0px;
    gap: 8px;
}

.god-mode-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.god-mode-body-container {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}

.god-mode-waiting-room-body-container {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.god-mode-city-list-container {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}

.god-mode-city-list-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.god-mode-button-container {
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
    padding-top: 24px;
}

.god-mode-results-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto;
}

.god-mode-last-five-subs-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

@media only screen and (max-width: 999px) {
    .god-mode-outer-row-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .god-mode-inner-container {
        width: calc(100% - 32px);
        margin: 0 auto;
        margin-bottom: 120px;
        margin-top: 32px;
    }
}