.tabs-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.tabs-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.tabs-element-container {
    padding: 16px;
    padding-bottom: 8px;
}

.tabs-element-container:hover {
    background-color: var(--beige-beige-03);
    border-radius: 4px;
}

.tabs-element-selected-container {
    border-bottom: 2px solid var(--grayscale-black);
}

.tabs-element-selected-container:hover {
    border-radius: unset;
    background-color: unset;
}