.unit-layout-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 158px;
    padding: 16px;
    border-radius: 4px;
    border: 1px solid var(--grayscale-gray-02);
}

.unit-layout-outer-container-margin-right:first-of-type {
    margin-right: 16px;
}

.unit-layout-inner-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.unit-layout-image {
    width: 32px;
}

.unit-sub-layout-image {
    width: 16px;
}