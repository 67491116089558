.favourites-table-outer-container {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: hidden;
}

.favourites-body-table {
    overflow-x: scroll;
    display: block;
    width: 100%;
}

.favourites-table-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

/* .favourites-table-element::-webkit-scrollbar {
    display: none;
} */

.favourites-table-outer-container table,
.favourites-table-outer-container th,
.favourites-table-outer-container td {
    border-collapse: collapse;
}

.favourites-table-label-cell {
    padding: 8px 16px;
    min-width: 183px;
}

.favourites-table-label-cell span {
    white-space: nowrap;
}

.favourites-table-value-cell {
    padding: 0px 16px;
    height: 53px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-top: 0.25px solid var(--grayscale-gray-01);
    border-right: 0.25px solid var(--grayscale-gray-01);
    background-color: var(--beige-beige-01);
    min-width: 183px;
}

.favourites-table-value-row {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
}

.favourites-table-value-row:last-of-type .favourites-table-value-cell,
.favourites-table-value-row:last-of-type .favourites-table-value-checkbox-outer-container {
    border-bottom: 0.25px solid var(--grayscale-gray-01);
}

.favourites-table-checkbox-header-container {
    min-width: 52px;
    padding: 0px;
}

.favourites-table-value-checkbox-outer-container {
    padding: 0px 16px;
    height: 53px;
    border-top: 0.25px solid var(--grayscale-gray-01);
    border-right: 0.25px solid var(--grayscale-gray-01);
    background-color: var(--beige-beige-01);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.favourites-table-checkbox-container {
    border-radius: 2px;
    border: 1.5px solid var(--blue-blue-01);
    background: var(--grayscale-white);
    box-shadow: 0px 0px 8px 0px rgba(152, 151, 147, 0.40);
    width: 16px;
    height: 16px;
    position: relative;
    min-width: 0px;
    cursor: pointer;
}

.favourites-table-checkbox {
    background-color: var(--grayscale-black);
	width: 12px;
	height: 12px;
	border-radius: 2px;
	position: absolute;
	z-index: 1;
	left: 2px;
	right: 0px;
	top: 0px;
	bottom: 0px;
	margin-top: auto;
	margin-bottom: auto;
	margin-right: auto;
}

.favourites-fixed-container {
    background-color: var(--beige-beige-01);
}

.favourites-table-right-shadow {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 16px;
    background: linear-gradient(90deg, rgba(223, 217, 211, 0.00) 17.23%, rgba(223, 217, 211, 0.40) 100%);
}

.favourites-table-value-row-selected .favourites-table-value-cell,
.favourites-table-value-row-selected .favourites-table-value-checkbox-outer-container {
    background-color: var(--beige-beige-03);
}