.profile-outer-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: stretch;
	overflow: hidden;
	height: calc(100vh - 56px);
}

.profile-inner-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding-top: 48px;
	padding-bottom: 32px;
	margin-left: 32px;
	position: relative;
	width: calc(100% - 248px);
	min-height: 80vh;
	overflow-y: scroll;
}

.profile-outer-container .modal-outer-container {
	position: fixed;
	top: 20%;
	z-index: 14;
	left: 0px;
	right: 0px;
	margin-left: auto;
	margin-right: auto;
}

.profile-inner-max-width-container {
	width: 100%;
	max-width: 1160px;
}

.profile-options-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
}

.stripe-setup-form-container {
	position: relative;
}

.profile-option-element-selected {
	border-radius: 1000px;
	background: var(--beige-beige-03);
}

.profile-payments-subscriptions-container {
	width: 100%;
}

.profile-payment-method-text-container {
	padding-bottom: 32px;
}

.profile-add-payment-method-button-container {
	width: 100%;
	max-width: 240px;
}

.profile-account-information-container {
	margin: 0 auto;
	width: 100%;
}

.profile-separator-container {
	border-top: 3px solid var(--beige-beige-03);
	margin: 0 auto;
	width: 100%;
}

.profile-subheading-container {
	margin-top: 24px;
}

.profile-title-container {
	width: 100%;
}

.profile-save-button-container {
	width: 158px;
}

.profile-subheading-container-row {
	margin: 0 auto;
	margin-top: 32px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
}

.profile-account-settings-profile-image-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding-bottom: 32px;
	border-bottom: 0.5px solid var(--grayscale-gray-01);
}

.profile-account-settings-profile-image-container .uploaded-image {
	width: 96px;
	height: 96px;
	border-radius: 4px;
}

.profile-element-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
}

.profile-element-edit-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
}

.profile-element-edit-input-container {
	width: 100%;
}

.profile-element-google-icon {
	width: 16px;
	display: block;
	margin-right: 8px;
}

.profile-element-full-edit-container {
	width: 100%;
}

.profile-account-settings-edit-save-container {
	width: 105px;
}

.profile-element-container:first-of-type {
	border-bottom: 0.5px solid var(--grayscale-gray-01);
	padding-bottom: 32px;
}

.profile-element-text-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: calc(100% - 64px);
}

.profile-active-subscription-label {
	font-family: var(--font-family);
	font-style: italic;
	font-weight: 400;
	color: var(--grayscale-black);
	margin-top: 8px;
	display: block;
}

.profile-active-subscription-label-bold {
	font-weight: 700;
}

.profile-logout-container {
	margin-top: 32px;
	width: 97px;
}

.profit-action-button-container {
	margin-top: 32px;
}

.profile-refund-container {
	margin-top: 32px;
}

.profile-refund-text {
	color:var(--blue-blue-03);
	font-family: var(--font-family);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	text-decoration-line: none;
}

.profile-outer-container .loading-container {
	top: 25%;
	position: fixed;
}

.profile-account-settings-container {
	padding: 32px 0px;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.profile-account-input-settings-container {
	max-width: 600px;
}

.profile-account-input-settings-container input::-webkit-outer-spin-button,
.profile-account-input-settings-container input::-webkit-inner-spin-button,
.profile-account-settings-city-element-setting-container input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.profile-account-input-settings-container input[type=number],
.profile-account-settings-city-element-setting-container input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.profile-account-input-settings-container .MuiInputBase-input,
.profile-account-input-settings-container .MuiInputAdornment-positionEnd,
.profile-account-settings-city-element-setting-container .MuiInputBase-input,
.profile-account-settings-city-element-setting-container .MuiInputAdornment-positionEnd {
	margin-top: 0px !important;
}

.profile-account-input-settings-container .MuiOutlinedInput-root,
.profile-account-settings-city-element-setting-container .MuiOutlinedInput-root {
	padding-bottom: 0px !important;
}

.profile-account-settings-financials-input {
	width: 100%;
}

.profile-account-switch-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.profile-account-switch-container .MuiSwitch-root {
	margin: 0px;
}

.profile-account-switch-container .MuiFormControlLabel-root {
	margin-left: 0px;
	margin-right: 4px;
}

.profile-account-settings-payment-type-container {
	max-width: 600px;
}

.profile-category-title-container {
	margin-top: 32px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.profile-parent-save-button-normal-container {
	width: 82px;
}

.profile-account-settings-find-city-container {
	max-width: 400px;
	position: relative;
}

.financial-settings-city-filtering-container {
	margin-top: -40px;
}

.profile-account-settings-cities-column-container {
	padding-top: 16px;
	border-top: 1px solid var(--grayscale-gray-01);
	margin-top: 32px;
}

.profile-account-settings-city-element-title-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.profile-account-settings-city-element-buttons-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	/* min-width: 115px; */
}

.profile-account-settings-city-element-setting-container {
	/* width: 100%; */
	border-radius: 4px;
	border: 1px solid var(--grayscale-gray-01);
	background-color: var(--grayscale-white);
	padding: 16px 12px;
	margin-top: 16px;
	max-width: 466px;
}

.profile-account-settings-city-value-input-container {
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.profile-account-settings-city-exit {
	position: absolute;
	z-index: 1;
	top: 10px;
	right: 10px;
	width: 16px;
	cursor: pointer;
}

.profile-account-settings-city-element-add-setting-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 4px 8px;
	width: 100px;
}

.profile-account-settings-plus-icon {
	width: 16px;
	margin-right: 4px;
}

.profile-account-settings-city-element-container {
	padding-bottom: 32px;
	border-bottom: 1px solid var(--grayscale-gray-01);
	padding-top: 32px;
}

.profile-account-settings-city-element-container:first-of-type {
	padding-top: 0px;
}

.error-message-margin {
	margin-top: 8px;
}

.profile-payments-subscriptions-container .loading-container {
	position: fixed;
	z-index: 2;
	top: 20%;
}

.profile-payment-methods-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.profile-autobilling-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.profile-account-rehab-costs-container .MuiInputAdornment-positionStart {
	margin-bottom: auto;
}

.profile-account-settings-cash-payment {
	padding-bottom: 16px;
}

.profile-payment-settings-outer-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding-left: 32px;
}

.profile-logout-outer-container {
	padding-left: 32px;
	padding-top: 64px;
}

@media only screen and (min-width: 1000px) {
	.profile-inner-width-container {
		width: calc(100% - 64px);
		max-width: 508px;
		margin-left: 32px;
	}

	.profile-option-element {
		padding: 4px 8px;
		margin-bottom: 32px;
	}
	
	.profile-option-element:last-of-type {
		margin-bottom: 0px;
	}

	.profile-desktop-body-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		width: 100%;
	}

	.profile-mobile-body-container {
		display: none;
	}

	.profile-title-text {
		font-size: 40px;
		font-weight: 700;
	}

	.profile-subheading-text {
		font-size: 16px;
		font-weight: 600;
	}

	.profile-label-text {
		font-size: 16px;
		font-weight: 600;
	}

	.profile-value-text {
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;
	}

	.profile-active-subscription-label {
		font-size: 14px;
		line-height: 18px;
	}

	.profile-account-settings-costs-input-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		width: 100%;
	}

	.profile-account-settings-default-cost-explanation {
		width: 300px;
	}

	.profit-action-button-container {
		max-width: 135px;
	}

	.profile-account-settings-city-element-setting-container-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		width: 100%;
	}

	.profile-account-settings-city-element-setting-left,
	.profile-account-settings-city-element-setting-right {
		width: 45%;
	}

	.profile-account-switch-container {
		width: 300px;
	}

	.profile-parent-save-button-container {
		width: 82px;
		position: sticky;
		position: -webkit-sticky;
		top: 80px;
		margin-left: auto;
		opacity: 0;
	}

	.profile-parent-save-button-normal-container {
		margin-right: -32px;
	}

	.profile-mobile-sticky-save-button {
		display: none;
	}

	.profile-user-settings-width-container .profile-account-information-container {
		margin-top: 0px;
	}
}

@media only screen and (max-width: 999px) {
	.profile-outer-row {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		overflow: unset;
		height: unset;
	}

	.profile-outer-container .modal-outer-container {
		top: 80px;
	}

	.profile-outer-container .profile-upgrade-modal .modal-body-container {
		max-height: calc(100dvh - 450px);
		overflow-y: scroll;
	}
	
	.profile-outer-container .profile-upgrade-modal .modal-secondary-button-element {
		display: none;
	}

	.profile-inner-container {
		padding-top: 0px;
		padding-bottom: 0px;
		margin-left: 0px;
		width: calc(100% - 32px);
		margin: 0 auto;
		margin-bottom: 64px;
		margin-top: 32px;
	}

	.profile-inner-max-width-container {
		width: 100%;
		max-width: 700px;
	}

	.profile-element-edit-row {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
	}

	.profile-mobile-options-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		width: 100%;
		gap: 16px;
	}

	.profile-desktop-body-container {
		display: none;
	}

	.profile-mobile-body-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		width: 100%;
	}

	.profile-element-edit-input-container {
		margin-bottom: 16px;
		margin-right: 0px !important;
		width: 100%;
	}

	.profile-options-row,
	.profile-inner-width-container {
		width: 100%;
	}

	.profile-options-row {
		display: none;
	}

	.profile-inner-mobile-width-container {
		margin-bottom: 64px;
	}

	.profile-account-information-container {
		margin-left: 0px;
		width: calc(100% - 32px);
	}

	.profile-user-settings-width-container .profile-title-container,
	.profile-user-settings-width-container .profile-options-row,
	.profile-user-settings-width-container .profile-account-information-container {
		/* margin: 0 auto; */
		width: 80%;
		max-width: 700px;
	}

	.profile-user-settings-width-container .profile-title-container,
	.profile-user-settings-width-container .profile-inner-width-container {
		margin: 0 auto;
	}

	.profile-user-settings-width-container .profile-title-container .profile-inner-width-container {
		width: 100%;
	}

	.profile-option-element {
		padding: 4px 8px;
		margin-bottom: 8px;
	}
	
	.profile-option-element:last-of-type {
		margin-bottom: 0px;
	}

	.profile-title-text {
		font-size: 24px;
		font-weight: 600;
	}

	.profile-subheading-text {
		font-size: 12px;
		font-weight: 500;
	}

	.profile-label-text {
		font-size: 14px;
		font-weight: 600;
	}

	.profile-value-text {
		font-size: 14px;
		font-weight: 400;
		line-height: 18px;
	}

	.profile-active-subscription-label {
		font-size: 12px;
		line-height: normal;
	}

	.profit-action-button-container {
		max-width: none;
	}

	.profile-subheading-inner-title-container {
		width: 75%;
	}

	.profile-account-settings-default-cost-explanation {
		margin-top: 8px;
	}

	.profile-account-settings-find-city-container input::placeholder {
		font-size: 10px;
	}

	.profile-parent-save-button-container,
	.profile-parent-save-button-normal-container {
		display: none;
	}

	.profile-mobile-sticky-save-button {
		position: fixed;
		bottom: 59px;
		width: calc(100% - 32px);
		background-color: var(--grayscale-white);
		box-shadow: 0px 2px 12px 0px rgba(207, 188, 173, 0.50);
		padding: 16px;
		left: 0px;
	}

	.profile-mobile-sticky-inner-container {
		padding: 16px 32px;
		background: var(--beige-beige-01, #FFF9F2);
		max-width: 180px;
		margin: 0 auto;
	}

	.profile-outer-container .loading-container {
		top: 15%;
		position: fixed;
		width: 100px;
	}

	.profile-account-settings-city-element-add-setting-container {
		width: auto;
	}

	.profile-payment-settings-outer-container {
		padding-left: 0px;
	}

	.profile-logout-outer-container {
		padding-left: 0px;
		padding-bottom: 4px;
	}
}