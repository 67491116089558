.quick-report-panel-outer-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 328px;
    padding: 40px var(--corner-large) var(--corner-large) var(--corner-large);
    gap: 32px;
    border-radius: var(--corner-regular);
    background-color: var(--grayscale-white);
    box-shadow: 0px 8px 24px 8px rgba(41, 38, 33, 0.15);
    position: absolute;
    z-index: 11;
    top: 50px;
    left: 0px;
}

.quick-report-panel-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
}

.quick-report-panel-title-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.quick-report-panel-title-right-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.quick-report-panel-credits-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}

.quick-report-panel-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 16px;
}

@media only screen and (max-width: 999px) {
    .quick-report-panel-outer-container {
        right: 0px;
        left: unset;
    }
}