.buy-box-card-outer-container {
    border-radius: var(--corner-regular);
    background-color: var(--grayscale-white);
    box-shadow: 0px 0px 8px 0px rgba(152, 151, 147, 0.40);
    width: 100%;
}

.buy-box-card-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px;
    width: calc(100% - 32px);
}

.buy-box-card-inner-container .loading-container {
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
    max-width: 75px;
}

.buy-box-card-left-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.buy-box-card-left-top-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
}

.buy-box-card-title-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.buy-box-card-left-row-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.buy-box-card-left-element {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 2px;
}

.buy-box-card-specs-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}

.buy-box-card-specs-element {
    display: flex;
    padding: var(--corner-small) var(--corner-regular);
    align-items: center;
    gap: var(--corner-small);
    border-radius: var(--corner-regular);
    background-color: var(--grayscale-gray-00);
}

.buy-box-card-specs-icon {
    width: 16px;
}

.buy-box-card-right-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.buy-box-card-right-container .dropdown-options-container {
    width: 150px;
}

.buy-box-card-right-container .dropdown-outer-container {
    width: 90px;
    height: 14px;
}

.buy-box-card-action-dropdown .button-icon-medium {
    transform: rotate(180deg);
}

.buy-box-card-right-container .combo-list-outer-container {
    right: 0px;
    width: 202px;
}

.buy-box-spacing-border {
    border-radius: 100px;
    background-color: var(--grayscale-gray-01);
    width: 100%;
    margin: 8px 0px;
    height: 1px;
}

@media only screen and (max-width: 999px) {
    .buy-box-card-inner-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 16px;
    }

    .buy-box-card-left-container {
        width: 100%;
    }

    .buy-box-card-left-top-container {
        gap: 16px;
        width: 100%;
    }

    .buy-box-card-title-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
    }

    .buy-box-card-mobile-title-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
    }

    .buy-box-card-update-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
    }

    .buy-box-card-left-row-container {
        gap: 4px;
        flex-wrap: wrap;
    }

    .buy-box-card-specs-row {
        gap: 8px;
        flex-wrap: wrap;
    }
}