.recommendations-inner-container {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 48px;
    padding-top: 64px;
    padding-bottom: 64px;
}

.recommendations-body-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
    width: 100%;
    max-width: 370px;
}

.recommendations-title-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
}

.recommendations-divider-container {
    width: 100%;
    height: 1px;
    background-color: var(--grayscale-gray-00);
}

.recommendations-element-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
}

.recommendations-radio-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
}

.recommendations-button-container-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    width: 100%;
}

.recommendations-button-container {
    width: 100%;
    max-width: 120px;
}

@media only screen and (max-width: 999px) {
    .recommendations-inner-container {
        width: calc(100% - 32px);
        margin: 0 auto;
        padding-bottom: 120px;
        padding-top: 16px;
    }

    .recommendations-radio-mobile {
        gap: 16px;
    }

    .recommendations-button-container-row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: sticky;
        bottom: 0px;
        background-color: var(--grayscale-white);
        padding: 8px 0px;
    }

    .recommendations-button-container {
        max-width: none;
    }

    .recommendations-button-container-row .text-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}