.deal-optimiser-loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.deal-optimiser-loading-circle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    border-radius: 28px;
}

.deal-optimiser-loading-icon {
    width: 32px;
    height: 32px;
}

.deal-optimiser-text-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
}