.property-list-button-container {
    position: absolute;
    z-index: 3;
    bottom: 75px;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    max-width: 180px;
}

.property-list-small-button-container {
    max-width: 90px;
}

.property-list-button-icon {
    width: 16px;
    height: 16px;
}

@media only screen and (min-width: 1000px) {
    .property-list-button-container {
        display: none;
    }
}