.upgrade-metro-container {
    max-width: 199px;
    width: 100%;
    position: fixed;
    z-index: 1;
    margin-top: 16px;
    margin-left: 120px;
}

.upgrade-metro-container button {
    font-size: 14px;
    height: 36px;
}

.upgrade-metro-container button:hover {
    opacity: 1;
}

@media only screen and (max-width: 1399px) {
    .upgrade-metro-container {
        display: none;
    }
}