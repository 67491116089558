.payment-success-inner-container {
	width: 90%;
	margin: 0 auto;
	max-width: 300px;
	padding-top: 40px;
	padding-bottom: 80px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.payment-records-success-outer-container .payment-success-inner-container {
	max-width: 550px;
}

.payment-success-icon-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.payment-success-icon-container {
	width: 100%;
}

@media only screen and (min-width: 999px) {
	.payment-success-inner-container {
		min-height: 650px;
	}
}