.buy-box-frequency-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
}

.buy-box-frequency-title-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}

.buy-box-frequency-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

@media only screen and (max-width: 999px) {
    .buy-box-frequency-container {
        padding-bottom: 100px;
    }
    
    .buy-box-frequency-column {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 16px;
        width: calc(100% - 4px);
    }
}