.comps-filter-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: var(--grayscale-white);
    border-radius: 4px;
    box-shadow: 0px 0px 8px 0px rgba(152, 151, 147, 0.40);
}

.comps-filter-inner-container {
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(100% - 16px);
}

.comps-filter-title-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.comps-filter-slider-container {
    width: 100%;
}