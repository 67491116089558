.mfh-house-hack-multi-unit-type-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.mfh-house-hack-multi-unit-type-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.mfh-house-hack-multi-unit-type-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
}

.mfh-house-hack-multi-unit-type-card-element {
    border-radius: 8px;
    border: 1px solid var(--blue-blue-01);
    cursor: pointer;
}

.mfh-house-hack-multi-unit-type-card-element-selected {
    border: 2px solid var(--blue-blue-03);
}

.mfh-house-hack-multi-unit-type-icon {
    width: 32px;
    height: 32px;
}

.mfh-house-hack-multi-unit-type-element {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
}

.mfh-house-hack-multi-unit-type-inputs-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.mfh-house-hack-multi-unit-type-inner-card-element {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;
}

.mfh-house-hack-multi-unit-type-total-container {
    width: calc(50% - 8px);
}

.mfh-house-hack-multi-unit-type-total-label-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.mfh-house-hack-multi-unit-type-dot {
    position: absolute;
    z-index: 1;
    left: -10px;
    height: 8px;
    width: 8px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
}