@media only screen and (max-width: 999px) {
    .city-mobile-pricing-overlay-container {
        position: sticky;
        bottom: 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        left: 0px;
        z-index: 15;
    }

    .city-mobile-pricing-overlay-inner-container {
        width: 100%;
    }

    .city-mobile-pricing-overlay-header-container {
        border-top: 1px solid var(--beige-beige-01);
        background: var(--beige-beige-04);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 24px 16px;
        position: relative;
        z-index: 2;
    }

    .city-selection-mobile-coffee {
        width: 50px;
    }

    .city-mobile-pricing-overlay-header-text-container {
        width: calc(100% - 122px);
    }

    .city-mobile-pricing-overlay-chevron-container {
        width: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .city-mobile-pricing-overlay-chevron-container svg {
        width: 20px;
    }

    .city-selection-mobile-cities-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 24px 16px;
        /* max-height: calc(100vh - 300px);
        overflow-y: scroll; */
    }

    .city-selection-mobile-totals-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0px 16px;
    }

    .city-selection-mobile-totals-padding {
        padding-top: 8px;
    }

    .city-selection-mobile-continue-button-container {
        width: calc(100% - 32px);
        margin: 0 auto;
        margin-bottom: 36px;
    }

    .city-selection-hide-mobile-accordion {
        height: 0px;
        opacity: 0;
        padding: 0px;
        animation: 0.5s ease-in-out normal city-selection-slide-out;
    }

    @keyframes city-selection-slide-out {
        from {
            height: auto;
            opacity: 1;
            padding: 24px 16px;
        }
        to {
            height: 0px;
            opacity: 0;
            padding: 0px;
        }
    }

    .city-selection-show-mobile-accordion {
        animation: 0.5s ease-in-out normal city-selection-slide-in;
    }

    @keyframes city-selection-slide-in {
        from {
            height: 0px;
            opacity: 0;
            padding: 0px;
        }
        to {
            height: auto;
            opacity: 1;
            padding: 24px 16px;
        }
    }

    .city-selection-mobile-reverse svg {
        transform: rotate(180deg);
    }
}