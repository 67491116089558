.strategy-score-card-element {
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: var(--corner-regular);
    background-color: var(--grayscale-gray-00);
    width: 139px;
}

.strategy-score-card-element-selected {
    border: 1px solid var(--purple-purple-02);
}

@media only screen and (max-width: 999px) {
    .strategy-score-card-element {
        width: calc(100% - 16px);
    }
}