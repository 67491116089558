.map-search-address-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 16px;
    padding: 0;
    position: fixed;
    margin-left: 64px;
    z-index: 1;
    background-color: var(--grayscale-white);
    width: 40px;
    height: 40px;
    border-radius: var(--corner-regular);
    box-shadow: 0px 0px 8px 0px rgba(152, 151, 147, 0.40);
}

.map-search-address-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.map-search-address-icon {
	width: 16px;
	height: 16px;
	cursor: pointer;
}

.map-search-address-input-container {
    position: relative;
    width: 250px;
    left: 100px;
}

.map-search-address-active-input-container {
	animation: 0.5s ease-in-out normal address-search-slide-in;
	display: block;
}

.map-search-address-outer-container-active .map-search-address-icon {
    display: none;
}

@keyframes address-search-slide-in {
	from {
		width: 0px;
        left: 0px;
	}
	to {
		width: 250px;
        left: 100px;
	}
}

.map-search-address-inactive-input-container {
	animation: 0.5s ease-in-out normal address-search-slide-out;
	display: none;
}

@keyframes address-search-slide-out {
	from {
		width: 250px;
		display: block;
        left: 100px;
	}
	to {
		width: 0px;
		display: none;
        left: 0px;
	}
}

@media only screen and (max-width: 1399px) {
    .map-search-address-outer-container {
        display: none;
    }
}