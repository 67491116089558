@import "~react-image-gallery/styles/css/image-gallery.css";

.image-grid-outer-container .image-gallery {
	z-index: 1;
}

@media only screen and (min-width: 2000px) {
	.image-grid-outer-container {
		width: 100%;
	}
	
	.image-grid-inner-container-mobile {
		display: none;
	}

	.property-drawer-open .image-grid-inner-container-mobile {
		display: block;
	}

	.property-drawer-open .image-grid-inner-container {
		display: none;
	}

	.image-grid-layout {
		display: grid;
		width: 100%;
		grid-template-rows: repeat(2, 1fr);
		grid-template-columns: repeat(4, 1fr);
		grid-auto-flow: dense;
		grid-gap: 16px;
	}
	  
	.image-grid-layout > *:first-child {
		grid-column: span 2;
		grid-row: span 2;
	}
	
	.image-grid-three-layout {
		display: grid;
		width: 100%;
		grid-template-rows: repeat(2, 1fr);
		grid-template-columns: repeat(3, 1fr);
		grid-auto-flow: dense;
		grid-gap: 16px;
	}
	
	.image-grid-three-layout > *:first-child {
		grid-column: span 2;
		grid-row: span 2;
	}
	
	.image-double-layout {
		display: grid;
		width: 100%;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 16px;
	}
	
	.image-grid-inner-container img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		display: block;
	}
	
	.image-grid-layout .image-grid-other-images {
		max-height: 242px;
	}
	
	.image-grid-layout .image-grid-first-image {
		max-height: 508px;
	}
	
	.image-grid-three-layout .image-grid-first-image {
		max-height: 508px;
	}
	
	.image-grid-three-layout .image-grid-other-images {
		max-height: 242px;
	}
	
	.image-double-layout .image-grid-image {
		max-height: 508px;
	}
	
	.image-grid-image-container {
		position: relative;
		cursor: pointer;
	}
	
	.image-grid-black-overlay {
		position: absolute;
		z-index: 1;
		top: 0px;
		left: 0px;
		height: 100%;
		width: 100%;
		background-color: #292621CC;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		max-height: 242px;
	}
	
	.image-grid-black-overlay span {
		font-family: var(--font-family);
		font-size: 36px;
		font-weight: 600;
		line-height: 43px;
		letter-spacing: 0em;
		text-align: left;
		color: #F5F6FA;
		display: block;
	}
	
	.image-modal-container {
		position: absolute;
		z-index: 14;
		width: 100%;
		left: 0px;
		top: 130px;
		background-color: #292621CC;
		height: 100%;
	}

	.property-drawer-open .image-gallery-thumbnails-wrapper {
		width: 100%;
		margin: 0 auto;
	}
	
	.property-drawer-closed .image-gallery-thumbnails-wrapper {
		position: fixed;
		bottom: 30px;
		z-index: 2;
		left: 0px;
		right: 0px;
		margin-left: auto;
		margin-right: auto;
	}
	
	.image-modal-thumbnail-element img {
		width: auto;
		height: 80px;
		cursor: pointer;
	}
	
	.property-drawer-closed .image-gallery-content {
		padding-top: 40px;
	}
	
	.image-modal-element {
		/* max-height: 600px !important; */
	}
	
	.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
		border: 4px solid var(--grayscale-white);
	}
	
	.image-gallery-thumbnail:hover {
		border: 4px solid var(--blue-blue-02);
	}
	
	.image-gallery-icon:hover {
		color: var(--blue-blue-02);
	}
	
	.image-gallery-slide .image-gallery-image {
		width: 75%;
	}
	
	.blurry-image-grid-container .image-grid-image-blurry {
		max-height: 242px;
		filter: blur(3px);
		cursor: default;
	}
	
	.blurry-image-double-container .image-grid-image-blurry {
		filter: blur(3px);
		cursor: default;
		max-height: 508px;
	}
	
	.no-scroll {
		position: fixed;
		width: 100%;
	}
	
	.image-grid-cta-container {
		position: absolute;
		z-index: 3;
		top: 0px;
		bottom: 0px;
		left: 0px;
		right: 0px;
		margin: auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 50%;
	}
	
	.image-gallery-thumbnails .image-gallery-thumbnails-container {
		cursor: default;
	}
	
	.property-preview-images-container .blurry-image-double-container .image-grid-image-blurry,
	.property-preview-images-container .image-grid-first-image,
	.property-preview-images-container .image-grid-image {
		max-height: 238px;
	}
	
	.property-preview-images-container .blurry-image-grid-container .image-grid-image-blurry,
	.property-preview-images-container .image-grid-layout .image-grid-other-images,
	.property-preview-images-container .image-grid-three-layout .image-grid-other-images {
		max-height: 115px;
	}
}

@media only screen and (max-width: 999px) {
	.image-gallery-thumbnails-wrapper {
		display: none;
	}

	.image-grid-outer-container {
		position: relative;
	}

	.mobile-image-grid-action-button {
		max-width: 130px;
		position: absolute;
		z-index: 2;
		top: 8px;
		right: 8px;
	}

	.new-property-lock {
		margin-right: 4px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1999px) {
	.image-grid-outer-container {
		width: 100%;
	}
	
	.image-grid-inner-container-mobile {
		display: none;
	}

	.property-drawer-open .image-grid-inner-container-mobile {
		display: block;
	}

	.property-drawer-open .image-grid-inner-container {
		display: none;
	}

	.image-grid-layout {
		display: grid;
		width: 100%;
		grid-template-rows: repeat(2, 1fr);
		grid-template-columns: repeat(4, 1fr);
		grid-auto-flow: dense;
		grid-gap: 16px;
	}
	  
	.image-grid-layout > *:first-child {
		grid-column: span 2;
		grid-row: span 2;
	}
	
	.image-grid-three-layout {
		display: grid;
		width: 100%;
		grid-template-rows: repeat(2, 1fr);
		grid-template-columns: repeat(3, 1fr);
		grid-auto-flow: dense;
		grid-gap: 16px;
	}
	
	.image-grid-three-layout > *:first-child {
		grid-column: span 2;
		grid-row: span 2;
	}
	
	.image-double-layout {
		display: grid;
		width: 100%;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 16px;
	}
	
	.image-grid-inner-container img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		display: block;
	}
	
	.image-grid-layout .image-grid-other-images {
		max-height: 242px;
	}
	
	.image-grid-layout .image-grid-first-image {
		max-height: 508px;
	}
	
	.image-grid-three-layout .image-grid-first-image {
		max-height: 508px;
	}
	
	.image-grid-three-layout .image-grid-other-images {
		max-height: 242px;
	}
	
	.image-double-layout .image-grid-image {
		max-height: 508px;
	}
	
	.image-grid-image-container {
		position: relative;
		cursor: pointer;
	}
	
	.image-grid-black-overlay {
		position: absolute;
		z-index: 1;
		top: 0px;
		left: 0px;
		height: 100%;
		width: 100%;
		background-color: #292621CC;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		max-height: 242px;
	}
	
	.image-grid-black-overlay span {
		font-family: var(--font-family);
		font-size: 36px;
		font-weight: 600;
		line-height: 43px;
		letter-spacing: 0em;
		text-align: left;
		color: #F5F6FA;
		display: block;
	}
	
	.image-modal-container {
		position: absolute;
		z-index: 14;
		width: 100%;
		left: 0px;
		top: 130px;
		background-color: #292621CC;
		height: 100%;
	}

	.property-drawer-open .image-gallery-thumbnails-wrapper {
		width: 100%;
		margin: 0 auto;
	}
	
	.property-drawer-closed .image-gallery-thumbnails-wrapper {
		position: fixed;
		bottom: 30px;
		z-index: 2;
		left: 0px;
		right: 0px;
		margin-left: auto;
		margin-right: auto;
	}
	
	.image-modal-thumbnail-element img {
		width: auto;
		height: 80px;
		cursor: pointer;
	}
	
	.property-drawer-closed .image-gallery-content {
		padding-top: 40px;
	}
	
	.image-modal-element {
		max-height: 600px !important;
	}
	
	.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
		border: 4px solid var(--grayscale-white);
	}
	
	.image-gallery-thumbnail:hover {
		border: 4px solid var(--blue-blue-02);
	}
	
	.image-gallery-icon:hover {
		color: var(--blue-blue-02);
	}
	
	.image-gallery-slide .image-gallery-image {
		width: auto;
		/* object-fit: none; */
	}
	
	.blurry-image-grid-container .image-grid-image-blurry {
		max-height: 242px;
		filter: blur(3px);
		cursor: default;
	}
	
	.blurry-image-double-container .image-grid-image-blurry {
		filter: blur(3px);
		cursor: default;
		max-height: 508px;
	}
	
	.no-scroll {
		position: fixed;
		width: 100%;
	}
	
	.image-grid-cta-container {
		position: absolute;
		z-index: 3;
		top: 0px;
		bottom: 0px;
		left: 0px;
		right: 0px;
		margin: auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 50%;
	}
	
	.image-gallery-thumbnails .image-gallery-thumbnails-container {
		cursor: default;
	}
	
	.property-preview-images-container .blurry-image-double-container .image-grid-image-blurry,
	.property-preview-images-container .image-grid-first-image,
	.property-preview-images-container .image-grid-image {
		max-height: 238px;
	}
	
	.property-preview-images-container .blurry-image-grid-container .image-grid-image-blurry,
	.property-preview-images-container .image-grid-layout .image-grid-other-images,
	.property-preview-images-container .image-grid-three-layout .image-grid-other-images {
		max-height: 115px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1399px) {
	.image-gallery-content .image-gallery-slide .image-gallery-image {
		height: 60vh;
	}
}

@media only screen and (min-width: 768px) {
	.mobile-image-grid-action-button {
		display: none;
	}

	.image-grid-first-image {
		border-radius: var(--corner-regular) 0px 0px var(--corner-regular);
	}

	.image-grid-container-other-image-3 img {
		border-radius: 0px 8px 0px 0px;
	}

	.image-grid-container-other-image-5 img,
	.image-grid-container-other-image-5 .image-grid-black-overlay {
		border-radius: 0px 0px 8px 0px;
	}
}

@media only screen and (max-width: 767px) {
	.image-grid-inner-container {
		display: none;
	}

	.image-grid-inner-container-mobile {

	}

	.image-grid-outer-container {
		width: 100%;
	}
}