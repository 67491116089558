.strategy-scores-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
}

.strategy-scores-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    gap: 16px;
    flex-wrap: wrap;
}

@media only screen and (max-width: 999px) {
    .strategy-scores-row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 8px;
        width: 100%;
    }
}