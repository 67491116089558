.location-outer-container {
	width: calc(100% - 96px);
	margin: 0 auto;
}

.location-inner-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 16px;
	width: 100%;
}

.location-google-map-container {
	box-shadow: 0px 0px 10px 0px #42210B40;
	width: 100%;
}

.location-crime-map-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.location-chips-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
}

.location-top-selector-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	gap: 16px;
}

.location-upgrade-prompt-container {
	margin-right: 16px;
}

.location-locked-neighbourhood-grade-container {
	position: absolute;
	z-index: 1;
	right: 16px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

@media only screen and (max-width: 999px) {
	.location-outer-container {
		width: calc(100% - 32px);
	}

	.location-inner-container {
		padding: 0px;
	}

	.location-top-selector-container {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.location-upgrade-prompt-container {
		margin-right: 0px;
		margin-bottom: 16px;
	}
}