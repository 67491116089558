.mobile-tab-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 12px 4px 8px 4px;
    gap: 4px;
    align-self: stretch;
    width: calc(100% - 8px);
}

.mobile-tab-line-element {
    border-radius: 32px;
    background-color: var(--grayscale-black);
    width: 100%;
    height: 2px;
}