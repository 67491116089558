.forgot-password-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 432px;
    margin: 0 auto;
    padding-top: 64px;
    min-height: 80vh;
    gap: 40px;
}

.forgot-password-inner-container .loading-container {
    top: 15%;
}

.forgot-password-body-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: 100%;
}

.forgot-password-input-container {
	margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
}

.forgot-password-button-container {
    width: 100%;
	margin: 0 auto;
}

@media only screen and (max-width: 999px) {
    .forgot-password-inner-container {
        width: calc(100% - 32px);
        padding-top: 24px;
    }

    .forgot-password-button-container {
        max-width: 432px;
    }
}