.compare-property-details-outer-container {
    background-color: var(--beige-beige-02);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 24px;
    border-top: 0.5px solid var(--grayscale-gray-02);
}

.compare-property-details-inner-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 24px 0px;
}

.compare-property-details-inner-container .MuiButtonBase-root {
    justify-content: flex-start;
    padding: 8px 16px;
    margin-left: 0px;
    min-height: 0px;
}

.compare-property-details-inner-container .MuiAccordionSummary-content {
    margin: 0px;
}

.compare-property-details-container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   padding: 8px 16px;
}

.compare-property-element-icon {
    width: 16px;
    margin-right: 8px;
}

.compare-property-details-element {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-top: 16px;
    padding: 8px 0px;
}

.compare-property-details-element:first-of-type {
    margin-top: 0px;
}

.compare-property-details-sub-element {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex: 2;
}

.compare-property-grid-element {
    flex: 1;
    padding: 8px 0px;
}

.compare-property-sub-grid-element {
    width: 45%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px;
}

@media only screen and (max-width: 999px) {
    .compare-property-details-element {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-top: 0px;
    }

    .compare-property-details-sub-element {
        flex: unset;
        width: 100%;
    }
}