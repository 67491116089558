.header-outer-container {
	width: 100%;
	margin: 0 auto;
	padding: 8px 0px;
	box-shadow: 0px 1px 4px 0px rgba(207, 188, 173, 0.30);
	position: sticky;
	top: 0px;
	z-index: 1400;
	background-color: var(--grayscale-white);
}

.header-onboarding-container {
	box-shadow: none;
	padding: 24px 0px;
}

.header-outer-container .modal-outer-container {
	left: 0px;
	right: 0px;
	margin-left: auto;
	margin-right: auto;
	top: 100px;
}

.header-outer-container .loading-container {
	position: fixed;
	max-width: 150px;
	top: 20%;
	z-index: 100;
}

.new-property-mobile-bar-active .header-outer-container {
	z-index: 4;
}

.header-inner-container {
	width: calc(100% - 32px);
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	/* max-width: 1800px; */
}

.header-logo-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 35%;
}

.pick-city-search-icon {
	width: 16px;
	position: absolute;
	z-index: 1;
	right: 12px;
	top: 0px;
	bottom: 0px;
	margin-top: auto;
	margin-bottom: auto;
	cursor: pointer;
}

.pick-city-exit-icon {
	width: 8px;
	position: absolute;
	z-index: 1;
	right: 12px;
	top: 0px;
	bottom: 0px;
	margin-top: auto;
	margin-bottom: auto;
	cursor: pointer;
}

.header-explore-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-left: 16px;
}

.header-explore-container svg {
	margin-right: 4px;
}

.header-all-properties-container {
	margin-left: 24px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding-right: 16px;
}

.header-property-search-container {
	margin-left: 24px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.header-central-icon-container {
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.header-central-container {
	width: 35%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 8px;
}

.header-central-inner-container {
	max-width: 456px;
	width: 100%;
	position: relative;
}

.header-profile-row {
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	padding: 4px 8px;
}

.header-property-search-container:hover,
.header-all-properties-container:hover,
.header-profile-row:hover,
.header-favourites-container:hover,
.header-heart-container:hover {
	background-color: var(--beige-beige-03);
	border-radius: 1000px;
	cursor: pointer;
}

.header-selected-state .colour-link {
	font-weight: 600;
}

.header-all-properties-text {
	font-family: var(--font-family);
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
	color: var(--grayscale-black);
	display: block;
	cursor: pointer;
}

.header-logo {
	height: 24px;
	cursor: pointer;
}

.header-my-profile {
	font-family: var(--font-family);
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
	color: var(--grayscale-black);
	display: block;
	cursor: pointer;
}

.header-my-profile-selected {
	font-weight: 700;
}

.header-coffee-bean-container {
	box-shadow: 0px 0px 4px 0px #00000040;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 50px;
	border-radius: 25px;
	margin-left: 10px;
}

.header-right-side-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	width: 35%;
	gap: 16px;
}

.header-right-side-inner-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 4px;
}

.header-favourites-container {
	margin-right: 30px;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding-right: 16px;
}

.header-favourites-text {
	font-family: var(--font-family);
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
	color: var(--grayscale-black);
	display: block;
}

.header-favourites-text-selected,
.header-all-properties-text-selected {
	font-weight: 700;
}

.header-favourites-icon {
	width: 20px;
}

.header-icon-background {
	width: 40px;
	height: 40px;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-right: 12px;
	cursor: pointer;
}

.header-heart-container {
	padding: 8px;
	margin-right: 24px;
}

.header-heart-icon {
	width: 16px;
	display: block;
	cursor: pointer;
}

.header-free-trial-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 4px;
}

.header-free-trial-margin-text {
	margin-right: 4px !important;
	display: block;
}

.header-free-trial-button-container {
	width: 115px;
	margin-left: 8px;
}

.header-no-subscription-container {
	width: 115px;
}

.header-tutorial-video-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.header-tutorial-video-container .default-tooltip-container {
	width: 200px;
	right: unset;
	left: 0px;
	display: none;
}

.header-tutorial-video-container:hover .default-tooltip-container {
	display: block;
}

.header-tutorial-video-container .default-tooltip-beak {
	left: 20%;
}

.header-tutorial-video-container .default-tooltip-container .body-semibold {
	display: none;
}

.header-right-side-inner-container .tooltip-outer-container {
	top: 50px;
	left: -100px;
	width: 200px;
	position: absolute;
	z-index: 1;
}

@media only screen and (min-width: 1000px) and (max-width: 1599px) {
	.header-central-container {
		width: 40%;
	}

	.header-right-side-container,
	.header-logo-row {
		width: 30%;
	}

	.header-heart-container {
		margin-right: 8px;
	}
}

@media only screen and (min-width: 1000px) {
	.header-mobile {
		display: none;
	}

	.header-no-subscription-container {
		padding-right: 16px;
	}

	.header-partner-profile {
		margin-right: 8px;
	}
}

@media only screen and (max-width: 999px) {
	.header-desktop {
		display: none !important;
	}

	.header-outer-container {
		padding: 16px 0px;
	}

	.header-inner-container {
		width: calc(100% - 32px);
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 8px;
	}

	.header-outer-container .modal-body-container {
		max-height: calc(100dvh - 425px);
		overflow-y: scroll;
	}

	.header-outer-container .predictive-search-container {
		top: 46px;
	}
}

@media only screen and (max-width: 499px) {
	.header-outer-container .modal-outer-container  .modal-cancel-button {
		display: none;
	}

	.header-outer-container .modal-outer-container .modal-buttons-secondary-cta {
		justify-content: center;
		margin: 0 auto;
	}
}