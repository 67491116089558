.settings-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    min-height: 80vh;
}

.settings-back-arrow {
    width: 16px;
    height: 16px;
    transform: rotate(90deg);
}

.settings-body-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 32px;
    width: calc(100% - 280px);
    height: 100%;
    overflow: hidden; 
}

.settings-inner-body-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
}

.settings-inner-body-container .loading-container {
    position: fixed;
    width: 100px;
    top: 40%;
}

.settings-title-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
}

.settings-title-tabs-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    border-bottom: 1px solid var(--grayscale-gray-01);
}

.settings-title-tabs-row .horizontal-tab-unselected-container {
    border-bottom: unset;
}

.settings-section-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
}

.settings-left-section-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    width: 30%;
}

.settings-left-section-title-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
}

.settings-left-section-panels-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 164px;
}

.settings-right-section-container {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 80px;
    overflow-y: scroll;
    height: calc(100vh - 215px);
}

.settings-custom-dropdown-open .settings-right-section-container {
    padding-bottom: 200px;
}

.settings-outer-container .exit-screen-container {
    height: 100%;
}

@media only screen and (max-width: 999px) {
    .settings-inner-container {
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: 8px;
        padding-bottom: 32px;
    }

    .settings-body-container {
        padding: 0px;
        margin: 0 auto;
        width: calc(100% - 32px);
    }

    .settings-inner-body-container {
        gap: 24px;
    }

    .settings-section-row {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
    }

    .settings-left-section-container {
        width: 100%;
        gap: 8px;
        margin: 0 auto;
        max-width: 572px;
    }

    .settings-custom-mobile-section-active .settings-left-section-container {
        width: calc(100% - 32px)
    }

    .settings-right-section-container {
        width: 100%;
        height: unset;
        overflow-y: unset;
        gap: 24px;
        max-width: 572px;
        margin: 0 auto;
    }

    .settings-element-right-container {
        width: 100%;
    }

    .custom-setting-mobile-modal {
        position: fixed;
        bottom: 0px;
        width: 100%;
        z-index: 15;
        margin-left: auto;
        margin-right: auto;
        left: 0px;
        right: 0px;
    }

    .settings-custom-dropdown-open .settings-right-section-container {
        padding-bottom: 0px;
    }
}