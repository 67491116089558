.footer-outer-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: var(--grayscale-black);
	width: 100vw;
	position: relative;
	z-index: 2;
}

.footer-inner-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	width: calc(100% - 200px);
	padding: 100px 0px;
}

.footer-logo {
	width: 204px;
}

.footer-left-container {
	max-width: 318px;
}

.footer-social-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.footer-social-icon {
	width: 24px;
}

.footer-right-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
}

.footer-right-element-links-container {
	padding-left: 0px;
	margin: 0px;
}

.footer-right-element-container {
	margin-right: 64px;
}

.footer-right-element-container:last-of-type {
	margin-right: 0px;
}

.footer-right-element-links-container .margin-medium:last-of-type {
	margin-bottom: 0px;
}

.footer-right-link-element {
	list-style-type: none;
	cursor: pointer;
}

.footer-bottom-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	width: calc(100% - 200px);
	padding-bottom: 32px;
}

.footer-bottom-legal-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

@media only screen and (max-width: 999px) {
	.footer-inner-container {
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;
		width: calc(100% - 50px);
	}

	.footer-right-element-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-right: 0px;
		width: 134px;
	}

	.footer-right-element-container .label-regular-caps,
	.footer-right-link-element {
		text-align: center;
	}

	.footer-right-container {
		padding-bottom: 64px;
		justify-content: space-between;
		align-items: flex-start;
		width: 100%;
		max-width: 500px;
	}

	.footer-left-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.footer-left-container .body-regular {
		text-align: center;
		display: block;
	}

	.footer-left-links-container .body-regular-underline {
		text-align: center;
	}

	.footer-social-row {
		justify-content: space-between;
		width: 224px;
		margin-top: 32px;
	}

	.footer-social-row .subtext-margin-right-x-large {
		margin-right: 0px;
	}

	.footer-bottom-container {
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;
		width: calc(100% - 50px);
	}

	.footer-bottom-legal-container {
		padding-bottom: 32px;
		flex-direction: row-reverse;
	}

	.footer-bottom-legal-container .subtext-margin-right-x-x-large {
		margin-left: 24px;
		margin-right: 0px;
	}
}