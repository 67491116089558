.popular-badge-outer-container {
    border-radius: 100px;
    position: absolute;
    z-index: 1;
    top: -12px;
    right: -8px;
}

.popular-badge-inner-container {
    padding: 4px 8px;
}

@media only screen and (max-width: 999px) {
    .popular-badge-outer-container {
        left: 0px;
        right: 0px;
        margin-left: auto;
        margin-right: auto;
        width: 190px;
    }
}