.badge-element-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.badge-element-normal-size {
    width: 16px;
    height: 16px;
    border-radius: var(--corner-regular);
}

.badge-element-free-size {
    border-radius: var(--corner-small);
    padding: 2px 8px;
}

.badge-accent-bg {
    background-color: var(--purple-purple-02);
}

.badge-standard-bg {
    background-color: var(--blue-blue-03);
}

.badge-standard-bg-border {
    background-color: var(--blue-blue-03);
    border: 1px solid var(--grayscale-white);
    position: absolute;
    z-index: 1;
    width: 14px;
    height: 14px;
    top: -4px;
    right: -8px;
}

@media only screen and (max-width: 999px) {
    .badge-element-normal-size {
        width: 24px;
        height: 24px;
        border-radius: 12px;
    }
}