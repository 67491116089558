.property-outer-container {
	background-color: var(--grayscale-white);
}

.property-loading-container {
	height: calc(100vh - 56px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.property-spacing-border {
	border-radius: 100px;
    background-color: var(--grayscale-gray-00);
    height: 1px;
    width: 100%;
	margin: 8px 0px;
}

.property-outer-container .modal-outer-container {
	z-index: 13;
	left: 0px;
	right: 0px;
	margin-left: auto;
	margin-right: auto;
	top: 70px;
	position: fixed;
	max-height: 708px;
}

.property-outer-container .modal-outer-container .deal-optimiser-container {
	flex: 1;
	overflow-y: auto;
	max-height: calc(100vh - 300px);
}

.property-outer-container .onboarding-complete-modal {
	top: 20%;
}

.onboarding-complete-redirect {
	width: 16px;
	height: 16px;
}

.property-outer-container .share-agent-modal {
	top: 25%;
}

.deal-optimise-icon {
    width: 16px;
	height: 16px;
}

.property-spacing-border-dark {
	border-bottom: 1px solid var(--grayscale-gray-01);
	width: 100%;
}

.property-loading-container div {
	width: 150px;
	margin: 0 auto;
}

.property-body-container {
	width: calc(100% - 96px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding-top: 80px;
	margin: 0 auto;
	max-width: 1350px;
	min-height: calc(100vh - 56px);
	padding-bottom: 100px;
	gap: 24px;
}

.property-no-property-container {
	justify-content: flex-start;
	align-items: center;
	max-width: 400px;
}

.property-inner-body-container {
	max-width: 1400px;
	margin: 0 auto;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	gap: 16px;
}

.property-outer-container .filter-spacing-container {
	margin: 0 auto;
}

.property-inner-body-left-container {
	width: 100%;
}

.property-double-inner-body-left-container {
	width: calc(100% - 96px);
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 24px;
}

.property-tabs-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	overflow-x: scroll;
	white-space: nowrap;
}

.property-bottom-body-container {
	margin: 0 auto;
	width: 100%;
}

.property-not-found-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	height: calc(100vh - 56px);
	padding-top: 48px;
}

.property-outer-container .toast-outer-container {
	position: fixed;
	bottom: 10%;
	right: 32px;
	z-index: 100;
	min-width: 250px;
}

.new-property-not-found-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	height: calc(100vh - 56px);
	padding-top: 48px;
}

.new-property-not-found-back-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 8px;
}

.new-property-not-found-back-container:hover {
	background-color: var(--beige-beige-03);
	border-radius: 1000px;
}

@media only screen and (min-width: 1000px) and (max-width: 1299px) {
	.property-inner-body-left-container {
		width: 40%;
	}
}

@media only screen and (max-width: 999px) {
	.property-body-container {
		width: 100%;
	}

	.property-inner-body-left-container {
		width: calc(100% - 32px);
		margin: 0 auto;
	}

	.property-tabs-row {
		overflow-x: scroll;
		white-space: nowrap;
	}

	.property-double-inner-body-left-container {
		width: 100%;
	}

	.property-outer-container .onboarding-complete-modal {
		top: unset;
		bottom: 0px;
	}

	.property-outer-container .onboarding-complete-modal .modal-body-container {
		max-height: calc(100dvh - 350px);
		overflow-y: scroll;
	}
}