.plan-card-outer-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 4px;
    background-color: var(--beige-beige-01);
    box-shadow: 0px 0px 8px 0px rgba(152, 151, 147, 0.40);
    cursor: pointer;
}

.plan-card-outer-container-selected {
    border: 2px solid var(--blue-blue-02);
}

.plan-card-inner-container {
    padding: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 48px);
}

.plan-card-left-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.plan-card-right-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

@media only screen and (max-width: 999px) {
    .plan-card-outer-container {
        background-color: var(--grayscale-white) !important;
    }

    .plan-card-inner-container {
        align-items: flex-start;
        padding: 32px 24px;
    }

    .plan-card-left-container {
        align-items: flex-start;
    }

    .plan-card-text-container .button-row {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .plan-card-text-container .button-row h2 {
        margin-bottom: 8px;
    }

    .plan-card-radio-container {
        margin-right: 16px !important;
    }
}