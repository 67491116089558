.property-section-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
}

.property-section-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.property-section-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;
}

.property-section-row .new-property-card-outer-container {
    max-width: 270px;
}

.property-section-arrow-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

@media only screen and (max-width: 999px) {
    .property-section-type-lists .property-section-row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;
    }

    .property-section-type-favourites .property-section-row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;
    }

    .property-section-row .new-property-card-outer-container,
    .property-section-row .list-card-outer-container {
        max-width: unset;
    }
}

@media only screen and (max-width: 499px) {
    .property-section-type-lists .property-section-row {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 24px;
    }

    .property-section-type-favourites .property-section-row {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 24px;
    }
}