.rent-tab-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: calc(100% - 2px);
}

.rent-tab-inner-container  {
    width: 100%;
}

.rent-tab-value-add-opportunity-container {
    width: 100%;
}

.value-add-opportunity-inner-row-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.value-add-opportunity-element-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: 16px;
    width: 50%;
}

.value-add-opportunity-inner-element-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.value-add-opportunity-element-container:last-of-type {
    margin-right: 0px;
    padding-right: 0px;
}

.value-add-checkbox-container {
    border-radius: 2px;
    border: 1.5px solid var(--blue-blue-01);
    background: var(--grayscale-white);
    box-shadow: 0px 0px 8px 0px rgba(152, 151, 147, 0.40);
    width: 16px;
    height: 16px;
    position: relative;
    margin-right: 8px;
    cursor: pointer;
}

.value-add-checkbox {
    background-color: var(--grayscale-black);
    width: 12px;
    height: 12px;
    border-radius: 2px;
    position: absolute;
    z-index: 1;
    left: 2px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: auto;
}

.value-add-opportunity-icon {
    margin-right: 8px;
    width: 16px;
}

.sfh-rent-input-container {
    max-width: 115px;
}

.rent-tab-fix-flip {
    opacity: 0.5;
}

.rent-tab-fix-flip-exclamation-container {
    position: absolute;
    z-index: 1;
    left: -24px;
}

.value-add-opportunity-tooltip-icon {
    cursor: pointer;
}

.value-add-opportunity-element-container .default-tooltip-beak {
    display: none;
}

.value-add-opportunity-extra-input-container input[type=number] {
    -moz-appearance: textfield;
}

.value-add-opportunity-extra-input-container input::-webkit-outer-spin-button,
.value-add-opportunity-extra-input-container input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.rent-tab-reset-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 4px;
}

.value-add-opportunity-tooltip-container .default-tooltip-container {
    max-width: 200px;
    right: unset;
    left: -150px;
}

.rent-tab-single-family-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
}

.rent-tab-rent-comps-container {
    margin-top: 8px;
}

@media only screen and (min-width: 1000px) and (max-width: 1399px) {
    .value-add-opportunity-inner-row-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .value-add-opportunity-element-container {
        margin-right: 0px;
        padding-right: 0px;
        margin-bottom: 16px;
    }

    .value-add-opportunity-element-container:last-of-type {
        margin-bottom: 0px;
    }
}