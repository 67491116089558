.emerging-area-modal-outer-container {
    border-radius: 4px;
    background-color: var(--beige-beige-01, #FFF9F2);
    box-shadow: 0px 2px 12px 0px rgba(207, 188, 173, 0.50);
    width: 95%;
    max-width: 596px;
    position: absolute;
    z-index: 100;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    margin: auto;
    max-height: 300px;
}

.emerging-area-modal-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 32px;
    padding-bottom: 0px;
}

.emerging-area-modal-exit-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    top: 24px;
    right: 24px;
    cursor: pointer;
}

.emerging-area-modal-exit-container svg {
    width: 20px;
}

.emerging-area-modal-buttons-container {
    padding: 16px 0px;
    width: 100%;
}

.emerging-areas-modal-buttons-inner-container {
    padding: 0px 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.emerging-area-modal-action-button-container {
    width: 212px;
}

@media only screen and (max-width: 999px) {
    .emerging-area-modal-outer-container {
        height: auto;
        top: 10%;
        bottom: unset;
        margin: unset;
        margin-left: auto;
        margin-right: auto;
        max-height: none;
    }

    .emerging-area-modal-inner-container {
        padding: 32px 16px;
        padding-bottom: 0px;
    }

    .emerging-areas-modal-buttons-inner-container {
        padding-left: 0px;
        padding-right: 0px;
    }

    .emerging-area-modal-action-button-container {
        width: 212px;
    }
}