.progress-bar-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.progress-bar-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;   
}