.days-on-market-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
    padding-bottom: 32px;
    width: 100%;
}

.days-on-market-selector-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
}

.days-on-market-title-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}

.days-on-market-options-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.days-on-market-switch-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
}

@media only screen and (max-width: 999px) {
    .new-buy-box-inner-body-container .days-on-market-container {
        padding-bottom: 100px;
    }

    .days-on-market-options-container {
        flex-wrap: wrap;
    }
}