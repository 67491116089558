.sign-up-inner-container {
	max-width: 500px;
	margin: 0 auto;
	padding-top: 64px;
	min-height: 80vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 40px;
}

.sign-up-title-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 8px;
}

@media only screen and (max-width: 999px) {
	.sign-up-inner-container {
		width: calc(100% - 32px);
		padding-top: 16px;
		padding-bottom: 80px;
	}	
}