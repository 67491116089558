.text-loading-container {
    position: absolute;
    z-index: 1;
    top: 25%;
    margin: 0 auto;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.text-loading-animation-container {
    max-width: 100px;
}

.text-loading-inner-container {
    margin: 0 auto;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

@media only screen and (max-width: 999px) {
    .text-loading-container {
        position: absolute;
        z-index: 1;
        top: 25%;
        margin: 0 auto;
        left: 0px;
        right: 0px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90%;
    }
}