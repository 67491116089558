.off-market-badges-tooltip-container {
    position: absolute;
	z-index: 20;
	background-color: var(--grayscale-white);
	box-shadow: 0px 0px 10px 0px #99959180;
	padding: 24px 16px;
	width: 220px;
	left: -20px;
	top: 40px;
    border-radius: 14px;
}

.off-market-badges-tooltip-beak {
    left: 9%;
    top: -15px;
    position: absolute;
    z-index: 2;
    width: 20px;
}

@media only screen and (min-width: 2250px) {
    .new-property-card-outer-container:nth-of-type(3n) .off-market-badges-tooltip-container {
        left: -200px;
    }
    
    .new-property-card-outer-container:nth-of-type(3n) .off-market-badges-tooltip-beak {
        left: 79%;
    }
}

@media only screen and (max-width: 2249px) {
    .new-property-card-outer-container:nth-of-type(2n) .off-market-badges-tooltip-container {
        left: -200px;
    }
    
    .new-property-card-outer-container:nth-of-type(2n) .off-market-badges-tooltip-beak {
        left: 79%;
    }
}

.compare-property-card-outer-container:nth-of-type(2n) .off-market-badges-tooltip-container {
    left: -180px;
}

.compare-property-card-outer-container:nth-of-type(2n) .off-market-badges-tooltip-beak {
    left: 71%;
}

.off-market-badges-tooltip-list-container {
    padding: 0px;
}

.off-market-badges-tooltip-list-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
}

.off-market-tooltip-badge-emoji-container {
    width: 55px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.off-market-badges-tooltip-list-item:last-of-type {
    margin-bottom: 0px;
}