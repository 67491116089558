.non-disclosure-state-modal-outer-container {
    border-radius: 3px;
    background-color: var(--beige-beige-01);
    box-shadow: 0px 0px 14px 0px rgba(49, 27, 2, 0.17);
    max-width: 468px;
    width: 90%;
    position: absolute;
    z-index: 13;
    top: 80px;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
}

.non-disclosure-state-modal-inner-container {
    padding-top: 32px;
    width: calc(100% - 96px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    margin: 0 auto;
}

.non-disclosure-state-modal-exit-container {
    position: absolute;
    z-index: 1;
    right: 0px;
    top: 16px;
}

.non-disclosure-state-modal-list-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    margin: 0 auto;
    list-style-type: none;
}

.non-disclosure-state-modal-button {
    margin-left: auto;
    width: 172px;
}

@media only screen and (max-width: 999px) {
    .non-disclosure-state-modal-inner-container {
        width: calc(100% - 32px);
    }

    .non-disclosure-state-modal-list-container {
        align-items: flex-start;
        margin: 0px;
    }

    .non-disclosure-state-modal-button {
        width: 100%;
    }
}