.percent-off-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    position: absolute;
    z-index: 1;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    top: -14px;
    border-radius: 100px;
    background-color: var(--grayscale-white);
    border: 2px solid var(--beige-beige-04);
    width: 84px;
}

.percent-off-red-container {
    border-radius: 100px;
    border: 2px solid var(--grayscale-white, #FFFEFC);
    background: var(--red-red-02);
    width: 176px;
}

.percent-off-container span {
    font-family: var(--font-family);
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 160% */
}