.toast-outer-container {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: var(--corner-regular);
    flex-shrink: 0;
    border-radius: var(--corner-medium);
    border: 1px solid var(--grayscale-white);
    background-color: var(--grayscale-black);
    box-shadow: 0px 8px 24px 8px rgba(41, 38, 33, 0.15);
}

.toast-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.toast-left-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.toast-tick-icon {
    width: 16px;
}

.toast-right-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.toast-exit-icon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
}

.toast-exit-icon {
    width: var(--corner-regular);
    flex-shrink: 0;
}