.custom-setting-element-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: var(--corner-regular);
    background-color: var(--grayscale-white);
    box-shadow: 0px 0px 8px 0px rgba(152, 151, 147, 0.40);
    width: calc(100% - 16px);
    margin: 0 auto;
}

.custom-setting-element-inner-container {
    width: calc(100% - 64px);
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.custom-setting-exit-icon-button {
    position: absolute;
    z-index: 1;
    top: 8px;
    right: 16px;
}

.custom-setting-exit-icon {
    width: 16px;
    height: 16px;
}

.custom-setting-element-body-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 16px;
}

.custom-setting-element-body-container .dropdown-options-container {
    width: 200px;
}

@media only screen and (max-width: 999px) {
    .settings-custom-mobile-section-active .settings-body-container {
        width: 100%;
    }

    .settings-custom-mobile-section-active .settings-element-container {
        height: auto !important;
    }

    .settings-custom-mobile-section-active .settings-section-container {
        overflow-y: scroll;
    }

    .settings-custom-mobile-section-active .custom-setting-element-container {
        box-shadow: none;
        width: 100%;
    }

    .settings-custom-mobile-section-active .custom-setting-element-inner-container {
        padding-top: 0px;
    }

    .custom-setting-element-add-setting {
        width: 100%;
        padding-bottom: 80px;
    }

    .custom-setting-element-body-container .dropdown-options-container {
        width: 250px;
        top: 46px;
    }

    .custom-setting-element-dropdown-container {
        /* height: 300px; */
    }
}