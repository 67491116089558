.legal-inner-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin: 0 auto;
	margin-top: 32px;
	width: 90%;
	max-width: 800px;
}

.legal-inner-container h1 {
	text-align: left !important;
}

.legal-text-container {
	margin-top: 40px;
	width: 100%;
}

.legal-text-element {
	margin-bottom: 50px;
}

.legal-inner-container a {
	color: #000000;
	font-weight: 500;
	text-decoration-line: none;
}