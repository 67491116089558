.more-location-filter-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
}

.more-location-filter-floodzone-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
}

.more-location-filter-hoa-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
}

@media only screen and (max-width: 999px) {
    .new-buy-box-inner-body-container .more-location-filter-container {
        padding-bottom: 100px;
        width: 100%;
    }
}