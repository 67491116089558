.selector-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    z-index: 11;
    width: 170px;
    top: 30px;
    left: 10px;
    border-radius: 3px;
    box-shadow: 0px 0px 8px 0px rgba(153, 149, 145, 0.50);
}

.selector-inner-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.selector-option-element-container {
    padding: 8px 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: calc(100% - 64px);
}

.selector-option-element {
    background-color: var(--grayscale-white);
}

.selector-option-element:hover {
    background-color: var(--blue-blue-01);
}

.selector-option-element-selected {
    background-color: var(--blue-blue-02);
}