.login-inner-container {
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 40px;
	min-height: 80vh;
	padding-top: 40px;
}

@media only screen and (max-width: 999px) {
	.login-inner-container {
		width: calc(100% - 32px);
	}	
}