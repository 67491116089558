.mfh-rent-one-unit-outer-container {
    width: 100%;
}

.mfh-rent-one-unit-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
}

.mfh-rent-one-unit-body-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
}

.mfh-rent-one-unit-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    width: 100%;
}

.mfh-rent-one-unit-financials-element {
    width: 100%;
}

.mfh-rent-multi-unit-type-icon {
    width: 32px;
    height: 32px;
}

.mfh-rent-one-unit-total-container {
    width: calc(50% - 8px);
}

.mfh-rent-one-unit-total-label-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.mfh-rent-one-unit-total-label-container .default-tooltip-beak {
    display: none;
}