.summary-tab-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 8px;
}

.summary-tab-spacing-container {
    border-radius: 100px;
    background-color: var(--grayscale-gray-00);
    height: 1px;
    width: 100%;
    margin: 8px 0px;
}