.off-market-card-image-container {
    border-radius: 8px;
    background: var(--grayscale-gray-01);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 250px;
    width: 100%;
}

.compare-property-card-outer-container .off-market-card-image-container {
    height: 250px;
    max-width: 275px;
}

.off-market-card-icon {
    width: 32px;
}

.off-market-card-text-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.off-market-card-text-row:hover span {
    color: var(--blue-blue-03);
    text-decoration-line: underline;
    display: block;
}

.off-market-card-redirect-arrow {
    width: 6px;
    margin-left: 4px;
}

.off-market-card-body-container {
    padding-top: 12px;
    width: 100%;
}

.off-market-card-value-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.off-market-card-value-element {
    margin-right: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.off-market-card-value-element:last-of-type {
    margin-right: 0px;
}

.off-market-card-body-container .new-property-characteristics-outer-container {
    margin-top: 12px;
}

.off-market-badges-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 18px;
    width: calc(100% - 32px);
    margin-bottom: 16px;
}

.off-market-extra-badges-container {
    margin-left: 4px;
    margin-top: 4px;
    position: relative;
}

.distress-tooltip-outer-container-position {
    opacity: 1 !important;
}

@media only screen and (min-width: 2250px) {
    .off-market-extra-badges-container {
        margin-left: 8px;
        margin-top: 8px;
    }

    .new-property-card-outer-container  .off-market-badge-container:nth-of-type(4n) {
        display: none;
    }
}

@media only screen and (max-width: 1599px) {
    .off-market-extra-badges-container {
        margin-left: 8px;
        margin-top: 8px;
    }

    .new-property-card-outer-container .off-market-badge-container:nth-of-type(4n) {
        display: none;
    }
}

@media only screen and (max-width: 1499px) and (min-width: 1000px) {
    .off-market-card-value-element {
        margin-right: 0px;
    }

    .off-market-card-value-element:first-of-type {
        margin-right: 48px;
    }
    
    .off-market-card-value-element:last-of-type {
        display: none;
        margin-right: 0px;
    }

    .new-property-card-outer-container .off-market-badge-container:nth-of-type(3n) {
        display: none;
    }

    .off-market-card-value-row .heading-small-semibold {
        font-size: 28px;
        line-height: 34px;
    }
}

@media only screen and (max-width: 749px) {
    .off-market-card-value-element:last-of-type {
        display: none;
        margin-right: 0px;
    }

    .off-market-card-value-row .heading-small-semibold {
        font-size: 28px;
        line-height: 34px;
    }
}

@media only screen and (max-width: 899px) {
    .new-property-card-outer-container .off-market-badge-container:nth-of-type(3n) {
        display: none;
        margin-right: 0px;
    }

    .compare-property-card-outer-container .off-market-badge-container {
        display: none;
        margin-right: 0px;
    }
}