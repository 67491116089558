.property-characteristics-outer-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 6px;
	margin-bottom: 10px;
}

.property-characteristics-element-container {

}

.property-characteristics-element-container span {
	font-family: var(--font-family);
	font-size: 12px;
	font-weight: 400;
	line-height: 14px;
	letter-spacing: 0em;
	text-align: left;
	color: var(--grayscale-black);
}