.zip-code-filter-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 24px;
}

.zip-code-filter-title-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
}

.zip-code-filter-codes-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.zip-code-filter-icon {
    width: 8px;
    height: 8px;
}

.zip-code-filter-container .dropdown-outer-container {
    width: calc(100% - 20px);
}

@media only screen and (max-width: 999px) {
    .zip-code-filter-container .dropdown-options-container {
        top: 46px;
    }
}