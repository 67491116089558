.upgrade-city-inner-container {
    max-width: 1000px;
    margin: 0 auto;
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: calc(100vh - 100px);
}

.upgrade-city-title-container {
    max-width: 543px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.upgrade-city-body-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    max-width: 1100px;
    padding-bottom: 80px;
}

.upgrade-city-input-container {
    width: 323px;
    margin-right: 24px;
}

.upgrade-city-metro-area-upgrade {
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.upgrade-city-divider {
    background-color: var(--grayscale-gray-01);
    height: 1px;
}

.upgrade-city-listings-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px 0px;
    row-gap: 16px;
    flex-wrap: wrap;
}

.upgrade-city-listings-element {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 16px;
}

.upgrade-city-listings-element:last-of-type {
    margin-right: 0px;
}

.upgrade-city-zips-container {
    padding-top: 16px;
}

.upgrade-city-zips-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 4px 16px;
}

.upgrade-city-map-container {
    width: 100%;
    max-width: 550px;
}

.upgrade-city-search-icon {
	width: 16px;
	position: absolute;
	z-index: 1;
	right: 12px;
	top: 0px;
	bottom: 0px;
	margin-top: auto;
	margin-bottom: auto;
	cursor: pointer;
}

.upgrade-city-exit-icon {
	width: 8px;
	position: absolute;
	z-index: 1;
	right: 12px;
	top: 0px;
	bottom: 0px;
	margin-top: auto;
	margin-bottom: auto;
	cursor: pointer;
}

.upgrade-city-input-text-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.upgrade-city-input-text-container .new-property-list-loading-container {
    max-width: 60px;
    margin-left: 0px;
    margin-right: auto;
}

.upgrade-city-map-button {
    max-width: 210px;
    width: 100%;
    margin-left: auto;
    margin-right: 0px;
}

.upgrade-city-metro-cities-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.upgrade-city-metro-cities-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 4px 16px;
}

.upgrade-city-metro-cities-element {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.upgrade-city-category-zips-element .MuiAccordionSummary-content,
.upgrade-city-category-zips-element .MuiAccordionSummary-root {
    margin: 0px !important;
    min-height: unset !important;
}

.upgrade-city-category-zips-element .css-mg12o6-MuiPaper-root-MuiAccordion-root:before,
.upgrade-city-category-zips-element .css-1qzt32c::before {
    height: unset !important;
    display: none !important;
    background-color: unset !important;
}

.upgrade-city-category-zips-element .MuiAccordionDetails-root {
    padding-bottom: 0px !important;
}

@media only screen and (max-width: 999px) {
    .upgrade-city-inner-container {
        width: calc(100% - 32px);
    }

    .upgrade-city-body-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        padding-bottom: 32px;
    }

    .upgrade-city-input-container {
        width: 100%;
        max-width: none;
        margin-right: 0px;
        margin-bottom: 32px;
    }

    .upgrade-city-map-container {
        width: 100%;
    }

    .upgrade-city-zips-grid {
        grid-template-columns: repeat(4, 1fr);
    }

    .upgrade-city-details-container {
        width: 100%;
        max-width: 460px;
    }

    .pick-plan-county-left-container {
        max-width: 460px;
    }

    .choose-counties-pick-county-container .pick-plan-county-left-container {
        margin: 0 auto;
    }

    .upgrade-city-inner-input-container {
        max-width: 460px;
        width: 100%;
    }

    .upgrade-city-map-button {
        position: sticky;
        z-index: 1;
        bottom: 55px;
        width: 100%;
        max-width: none;
        margin: unset;
        box-shadow: 0px -3px 15.8px 0px rgba(38, 41, 33, 0.15);
    }

    .upgrade-city-mobile-button-container {
        width: calc(100% - 32px);
        padding: 16px 0px;
        margin: 0 auto;
    }

    .city-selection-mobile-back-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
}