.chip-container {
    border-radius: 100px;
	background-color: var(--grayscale-white);
	box-shadow: 0px 0px 8px 0px rgba(153, 149, 145, 0.50);
	margin-right: 8px;
	padding: 8px 12px;
    cursor: pointer;
    position: relative;
    /* min-width: 180px; */
}

.chip-container:last-of-type {
    margin-right: 0px;
}

.chip-selected {
    background-color: var(--grayscale-gray-01);
    box-shadow: none;
}

.chip-container span {
    white-space: nowrap;
}

.chip-change-icon {
    width: 8px;
    margin-left: 4px;
}

.chip-disabled {
    opacity: 0.5;
    background-color: var(--grayscale-white);
    cursor: not-allowed;
}