.map-legend-outer-container {
    position: fixed;
    z-index: 1;
    right: 16px;
    margin-top: 16px;
    border-radius: 32px;
    border: 2px solid var(--grayscale-white);
    background-color: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(207, 188, 173, 0.50);
    overflow: hidden;
}

.location-gentrification-map-container .map-legend-outer-container {
    position: absolute;
    right: 16px;
}

.map-legend-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.map-legend-element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 32px;
    cursor: pointer;
}

.map-legend-selected-element {
    border: 1px solid var(--blue-blue-03) !important;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.map-legend-element-gentrification {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 32px;
    padding: 0px 24px;
    cursor: pointer;
}

.map-legend-deselected {
    opacity: 0.6;
}

.location-gentrification-map-container .map-legend-element-gentrification {
    cursor: default;
}

.map-legend-element:last-of-type,
.map-legend-element-gentrification:last-of-type {
    border-right: none;
}

.map-legend-element-gentrification:first-of-type,
.map-legend-element:first-of-type {
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
}

.map-legend-element-gentrification:last-of-type,
.map-legend-element:last-of-type {
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
}

.map-legend-hover-element {
    border: 3px solid var(--blue-blue-02) !important;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    opacity: 1;
}

.map-legend-gradient-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 200px;
    padding: 8px 4px;
}

@media only screen and (min-width: 1440px) {
    .map-legend-move-in {
        animation: 0.75s ease-in-out normal map-legend-move;
        right: 350px;
    }

    @keyframes map-legend-move {
        from {
            right: 60px;
        }
        to {
            right: 350px;
        }
    }

    .map-legend-default-position {
        animation: 0.75s ease-in-out normal map-legend-restore;
        right: 60px;
    }

    @keyframes map-legend-restore {
        from {
            right: 350px;
        }
        to {
            right: 60px;
        }
    }
}

@media only screen and (min-width: 1000px) {
    .map-legend-hover-element {
        border: 3px solid var(--blue-blue-02) !important;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        opacity: 1;
    }
}

@media only screen and (max-width: 999px) {
    .map-legend-element {
        width: 34px;
        height: 28px;
    }

    .map-legend-element-gentrification {
        width: 56px;
        height: 28px;
        padding: 0px 8px;
    }

    .map-legend-element-gentrification .body-semibold {
        font-size: 8px;
        line-height: 14px;
    }

    .map-legend-selected-element .body-semibold {
        font-size: 6px;
    }
}

@media only screen and (min-width: 400px) and (max-width: 999px) {
    .location-outer-container .map-legend-element {
        width: 60px;
        height: 32px;
    }
}