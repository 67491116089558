.filter-chip-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px var(--corner-medium);
    gap: var(--corner-small);
    border-radius: var(--corner-regular);
}

.filter-chip-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.filter-chip-unselected {
    background-color: var(--grayscale-gray-00);
}

.filter-chip-unselected:hover {
    background-color: var(--blue-blue-0130);
    transition: background-color 0.3s ease-in-out;
}

.filter-chip-unselected.disabled:hover {
    background-color: var(--grayscale-gray-00);
}

.filter-chip-selected {
    background-color: var(--blue-blue-02);
}

.filter-chip-left-icon {
    width: 16px;
}

.filter-chip-right-icon {
    width: 16px;
}