.delete-card-modal-outer-container {
    border-radius: 3px;
    box-shadow: 0px 0px 14px 0px rgba(49, 27, 2, 0.17);
    position: fixed;
    z-index: 1000000;
    top: 20%;
}

.delete-card-modal-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 32px 48px;
    position: relative;
}

.delete-card-modal-exit-container {
    position: absolute;
    z-index: 1;
    right: 16px;
    top: 16px;
}

.delete-card-modal-exit {
    width: 16px;
    cursor: pointer;
}

.delete-card-modal-body-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.delete-card-modal-image-container {
    margin-right: 16px;
}

.delete-card-modal-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.delete-card-modal-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.delete-card-modal-white-button {
    width: 95px;
    margin-right: 16px;
}

.delete-card-modal-action-button {
    width: 127px;
}

@media only screen and (max-width: 999px) {
    .delete-card-modal-outer-container {
        width: 90%;
        max-width: 500px;
    }

    .profile-inner-width-container .delete-card-modal-outer-container {
        width: calc(100% - 32px);
    }

    .delete-card-modal-inner-container {
        padding: 16px 24px;
    }
}