.map-container {
	width: 97.5%;
	height: 620px;
	margin-left: auto;
	position: relative;
	box-shadow: 0px 0px 10px 0px #42210B40;
}

.interactive-map-overlay {
	background-color: #FFFFFF;
	border-radius: 3px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 0px 10px 0px #4E2A0040;
}

.interactive-map-element-hover {
	border: 2px solid #42210B !important;
	background-color: var(--beige-beige-02) !important;
}

.interactive-map-element-parent-hover {
	z-index: 10;
}

.interactive-map-overlay:hover {
	border: 2px solid #42210B !important;
	background-color: var(--beige-beige-02);
}

.interactive-map-overlay span {
	font-family: var(--font-family);
	font-size: 14px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0em;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	padding: 10px;
}

.interactive-map-overlay-locked {
	background-color: #748EA08A;
	width: 124px;
	height: 124px;
	border-radius: 62px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.interactive-map-overlay-locked .interactive-map-coffee-icon {
	width: 50px;
}

.interactive-map-coffee-icon {
	width: 32px;
}

.location-map-overlay,
.multi-location-map-overlay {
	background-color: var(--grayscale-white);
	border: 2px solid var(--brown-brown-01);
	border-radius: 3px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 0px 10px 0px #4E2A0040;
}

.location-map-overlay-inverse {
	background-color: var(--brown-brown-01);
	border: 2px solid var(--grayscale-white);
	border-radius: 3px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 0px 10px 0px #4E2A0040;
	padding: 8px;
}

.location-map-overlay span {
	font-family: var(--font-family);
	font-size: 14px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0em;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	padding: 8px 9px;
}

.multi-location-map-overlay {
	padding: 4px 8px;
}

.location-map-comparable-overlay {
	background-color: var(--blue-blue-02);
	border-radius: 3px;
	height: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 0px 10px 0px #4E2A0040;
	padding: 0px 12px;
}

.location-map-comparable-overlay span {
	font-family: var(--font-family);
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
	color: var(--grayscale-white);
}

.location-map-container {
	width: 100%;
	height: 300px;
	margin: 0 auto;
	border-radius: var(--corner-small);
}

.location-gentrification-map-container {
	position: relative;
}

.map-container-small {
	height: 200px;
	animation: 0.5s ease-in-out normal reduce;
}

@keyframes reduce {
	from {
	  height: 600px;
	}
  
	to {
	  height: 200px;
	}
}

.map-container-slide-in {
	height: 600px;
	animation: 0.5s ease-in-out normal enlarge;
}

@keyframes enlarge {
	from {
	  height: 200px;
	}
  
	to {
	  height: 600px;
	}
}

.property-location-map-container .location-map-container {
	width: 100%;
	height: 400px;
}

.location-top-bar-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
}

.property-location-map-container {
	width: 100%;
}

.property-location-map-container .map-legend-outer-container {
	position: absolute;
	top: 0px;
	right: 0px;
}

.interactive-map-map-coffee-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

@media only screen and (max-width: 999px) {
	.location-top-bar-container {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.location-title-container {
		margin-bottom: 16px;
	}
}