.mfh-rent-multi-unit-type-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.mfh-rent-multi-unit-type-inputs-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
}

.mfh-rent-multi-unit-type-element-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
}

.mfh-rent-multi-unit-type-text-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
}

.mfh-rent-multi-unit-type-icon {
    width: 32px;
    height: 32px;
}

.mfh-rent-multi-unit-type-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    width: 100%;
}

.mfh-rent-multi-unit-type-input-container {
    width: 100%;
}

.mfh-rent-multi-unit-type-total-container {
    width: calc(50% - 8px);
}

.mfh-rent-multi-unit-type-total-label-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.mfh-rent-multi-unit-type-total-label-container .default-tooltip-beak {
    display: none;
}

.mfh-rent-reset-container {
    margin-left: 48px;
}

.mfh-rent-multi-unit-type-dot {
    position: absolute;
    z-index: 1;
    left: -10px;
    height: 8px;
    width: 8px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
}