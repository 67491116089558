.new-buy-box-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    overflow: hidden;
    height: calc(100vh - 56px);
}

.new-buy-box-body-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 32px;
    padding-bottom: 0px;
    overflow-y: scroll;
    width: calc(100% - 280px);
    max-width: 1400px;
}

.new-buy-box-body-container .loading-container {
    top: 20%;
}

.new-buy-box-inner-body-container {
    max-width: 1220px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.new-buy-box-top-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 8px;
}

.new-buy-box-title-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.new-buy-box-heading-left-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
}

.new-buy-box-heading-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.new-buy-box-chevron-container {
    margin-left: -12px;
}

.buy-box-spacing-border {
    border-radius: 100px;
    background-color: var(--grayscale-gray-00);
    height: 1px;
    width: calc(100% - 2px);
	margin: 8px 0px;
}

.new-buy-box-options-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
}

.new-buy-box-options-left-container {
    width: 184px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.new-buy-box-details-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.new-buy-box-details-row .buy-box-name-container {
    width: 377px;
}

.new-buy-box-details-row .buy-box-frequency-container {
    width: 199px;
}

.new-buy-box-options-right-container {
    width: calc(100% - 184px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    max-width: 572px;
    max-height: calc(100vh - 201px);
    overflow-y: scroll;
    padding-bottom: 32px;
}

.new-buy-box-inner-container .year-built-outer-container,
.new-buy-box-inner-container .estimated-interest-filter-outer-container {
    padding-bottom: 0px;
}

.property-type-selectors-container .choicebox-outer-container {
    max-width: 164px;
}

.new-buy-box-options-right-container .condition-filter-container {
    padding-left: 0px;
    width: calc(100% - 2px);
}

.new-buy-box-button-container {
    width: 100%;
    max-width: 100px;
}

@media only screen and (max-width: 999px) {
    .new-buy-box-outer-container .footer-outer-container {
        display: none;
    }

    .new-buy-box-chevron {
        width: 16px;
        height: 16px;
        transform: rotate(90deg);
    }

    .new-buy-box-inner-container {
        width: 100%;
        height: unset;
        overflow: unset;
    }

    .new-buy-box-top-container {
        align-items: center;
        max-width: 572px;
        margin: 0 auto;
    }

    .new-buy-box-body-container {
        padding: 8px 16px;
        width: calc(100% - 32px);
        padding-bottom: 0px;
        overflow-y: unset;
    }

    .new-buy-box-options-row {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    
    .new-buy-box-indicator-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        padding-bottom: 16px;
        width: 100%;
        max-width: 572px;
        margin: 0 auto;
    }

    .new-buy-box-options-right-container {
        width: 100%;
        padding-bottom: 0px;
        max-height: unset;
        overflow-y: unset;
        align-items: center;
    }

    .new-buy-box-mobile-button {
        width: calc(100% - 32px);
        position: sticky;
        z-index: 1;
        bottom: 52px;
        background-color: var(--grayscale-white);
        padding: 16px;
    }
}