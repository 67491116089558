.side-navigation-outer-container {
    border-right: 0.5px solid var(--grayscale-gray-01);
    width: 216px;
    height: calc(100vh - 56px);
    position: sticky;
    z-index: 15;
}

.side-navigation-inner-container {
    padding-top: 16px;
    height: calc(100% - 16px);
    max-height: 930px;
    min-height: 300px;
}

.side-navigation-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 16px;
}

.side-navigation-divider-container {
    margin: 8px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.side-navigation-options-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 0px;
    margin: 0px;
}

.side-navigation-element-container {
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: calc(100% - 16px);
}

.side-navigation-element-inner-container {
    width: calc(100% - 16px);
    padding: 8px 8px;
}

.side-navigation-element-inner-container:hover {
    border-radius: 4px;
    background-color: var(--beige-beige-03);
}

.side-navigation-option-active {
    border-radius: 4px;
    background-color: var(--blue-blue-0150);
}

.side-navigation-option-active:hover {
    background-color: var(--blue-blue-0150);
}

.side-navigation-option-icon {
    width: 16px;
    margin-right: 12px;
}

.side-navigation-account-container {
    position: absolute;
    z-index: 1;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.side-navigation-account-pic {
   width: 32px;
   height: 32px;
   border-radius: 16px;
   margin-right: 12px;
}

.side-navigation-partner-container svg {
    margin-right: 12px;
}

@media only screen and (max-width: 999px) {
    .side-navigation-outer-container {
        display: none;
    }

    .side-navigation-inner-container {
        padding-top: 0px;
        min-height: unset;
        overflow-x: hidden;
        height: unset;
        max-height: unset;
        width: 100%;
    }

    .side-navigation-element-inner-container {
        padding: 8px 4px;
    }

    .side-navigation-element-inner-container .text-link {
        color: var(--grayscale-gray-02);
    }

    .side-navigation-element-inner-container span {
        display: block;
        text-align: center;
    }

    .side-navigation-option-grey-icon {
        filter: invert(48%) sepia(7%) saturate(293%) hue-rotate(359deg) brightness(87%) contrast(91%);
    }

    .side-navigation-title-container {
        display: none;
    }

    .side-navigation-options-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        overflow-x: scroll;
        margin: 0px;
        box-shadow: 0px 2px 3px 0px rgba(207, 188, 173, 0.50);
        padding: 0px 10px;
    }

    .side-navigation-options-container::-webkit-scrollbar {
        display: none;
    }

    .side-navigation-option-active,
    .side-navigation-option-active:hover,
    .side-navigation-element-inner-container:hover {
        background-color: unset;
    }

    .side-navigation-divider-container {
        display: none;
    }

    .side-navigation-element-container {
        padding: 0px;
        width: auto;
        /* min-width: 150px; */
    }

    .side-navigation-option-active {
        min-width: 150px;
    }

    .side-navigation-element-container .button-row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .side-navigation-element-container .button-row .body-regular {
        text-align: center;
        white-space: nowrap;
    }

    .side-navigation-option-icon {
        margin-right: 0px;
        margin-bottom: 4px;
    }

    .side-navigation-account-container {
        display: none;
    }
}

.side-navigation-outer-container .modal-outer-container {
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    top: 10% !important;
    position: fixed;
    z-index: 100 !important;
}