.mobile-link-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 16px);
    padding: 8px;
}

.mobile-link-chevron {
    width: 16px;
    height: 16px;
    transform: rotate(-90deg);
}