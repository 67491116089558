.live-counties-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
    width: 100%;
}

.live-counties-title-container,
.live-counties-featured-markets {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
}

.live-counties-body-container {
    column-count: 4;
    column-gap: 32px;
    width: 100%;
}

.live-counties-featured-market-grid {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    gap: 16px;
    flex-wrap: wrap;
}

.live-counties-featured-element-container {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: var(--corner-regular);
    border: 1px solid var(--blue-blue-03);
}

.live-counties-element-container {
    break-inside: avoid;
    margin-bottom: 32px;
}

.live-counties-msa-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
}

.live-counties-spacing {
    width: 100%;
    height: 1px;
    background-color: var(--grayscale-gray-01);
}

.live-counties-market-selection-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 4px;
}

.live-counties-market-selection-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}

.live-counties-redirect-arrow {
    width: 8px;
    height: 8px;
}

@media only screen and (max-width: 999px) {
    .live-counties-body-container {
        column-count: 2;
    }
}

@media only screen and (max-width: 499px) {
    .live-counties-body-container {
        column-count: 1;
    }

    .live-counties-featured-element-container {
        width: calc(100% - 48px);
    }
}