.upload-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.upload-image-border-container {
    border-radius: 3px;
    border: 0.5px solid var(--blue-blue-01);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 90px;
    cursor: pointer;
}

.uploaded-image-container {
    position: relative;
    cursor: pointer;
}

.upload-image-icon {
    width: 32px;
}

.uploaded-image {
    width: 64px;
    height: 64px;
    border-radius: 32px;
}

.upload-image-icon-overlay {
    position: absolute;
    z-index: 1;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
}