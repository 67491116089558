.new-map-overlay-property-card-container {
	background-color: var(--beige-beige-01);
	box-shadow: 0px 0px 10px 0px rgba(206, 209, 200, 0.50);
	border-radius: 8px;
	padding: 8px;
	width: 300px;
	position: relative;
	z-index: 4;
}

.new-map-overlay-property-card-container .hide-icon-container {
	right: 22%;
}

.new-property-card-outer-container {
	position: relative;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
}

.new-property-card-inner-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 8px;
}

.new-property-card-images-swiper-container {
	width: 100%;
}

.new-property-card-images-swiper-container .custom-swiper-image-container {
	border-radius: 8px;
}

.new-property-card-image-container {
	background-repeat: no-repeat;
	object-fit: cover;
	width: 100%;
	height: 250px;
	position: relative;
	border-radius: var(--corner-regular);
}

.new-map-overlay-property-card-container .custom-swiper-outer-container {
	width: 100%;
}

.new-property-card-price-container {
	margin-bottom: 0px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
}

.new-property-card-price-text {
	font-size: 28px;
	font-family: var(--font-family);
	font-style: normal;
	font-weight: 600;
	line-height: 33px;
	color:var(--blue-blue-03);
	margin: 0px;
}

.new-property-card-badges-container {
	margin-top: 12px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	gap: 16px;
}

.new-property-card-cash-on-cash-badge-container {
	background-color: var(--green-green-01);
	border-radius: 3px;
	box-shadow: 0px 0px 10px 0px rgba(78, 42, 0, 0.10);
}

.new-property-card-cash-on-cash-badge-inner-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 6px;
}

.new-property-card-arv-badge-container {
	background-color: var(--green-green-01);
	border-radius: 3px;
	box-shadow: 0px 0px 10px 0px rgba(78, 42, 0, 0.10);
}

.expenses-covered-badge-outer-container {
	border-radius: 3px;
	box-shadow: 0px 0px 10px 0px rgba(78, 42, 0, 0.10);
}

.new-property-card-arv-badge-inner-container,
.expenses-covered-badge-inner-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 6px;
}

.arv-badge-container {
	border-radius: 3px;
	box-shadow: 0px 0px 10px 0px rgba(78, 42, 0, 0.10);
}

.arv-badge-inner-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 6px;
}

.new-property-card-cash-on-cash-positive {
	background-color: var(--green-green-01);
}

.new-property-card-cash-on-cash-negative {
	background-color: var(--red-red-01);
}

.new-property-card-cash-on-cash-badge-label {
	font-size: 12px;
	font-family: var(--font-family);
	font-style: normal;
	font-weight: 600;
	line-height: 18px;
	color: var(--grayscale-black);
}

.new-property-card-cash-on-cash-badge-value {
	font-size: 12px;
	font-family: var(--font-family);
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	color: var(--grayscale-black);
}

.new-property-card-top-picks-badge-container {
	border-radius: 3px;
	background-color: var(--grayscale-white);
	box-shadow: 0px 0px 10px 0px rgba(78, 42, 0, 0.10);
	padding: 5px 6px;
}


.new-property-card-top-picks-badge-text {
	font-size: 14px;
	font-family: var(--font-family);
	font-style: normal;
	font-weight: 600;
	line-height: 18px;
	color: var(--grayscale-black);
}

.hide-icon-container {
	position: absolute;
	z-index: 4;
	right: 28%;
	top: 2%;
	background-color: var(--beige-beige-01);
	box-shadow: 0px 0px 10px 0px #00000040;
	height: 40px;
	width: 40px;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	opacity: 1;
	cursor: pointer;
}

.new-property-card-viewed-property {
	opacity: 0.65;
}

.new-property-card-on-market .off-market-card-image-container {
	justify-content: center;
	height: 250px;
}

.new-property-card-partner-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 12px;
	padding-top: 0px;
	width: calc(100% - 24px);
}

.new-property-card-partner-edit-container {
	padding: 4px 8px;
}

.new-property-card-partner-copy-link-container {
	padding: 4px 8px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.new-property-card-partner-copy-link-icon {
	width: 16px;
}

.new-property-card-partner-right-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}

.new-property-card-partner-tick-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.new-property-card-partner-tick {
	width: 16px;
	margin-left: 4px;
}

.new-property-card-image-hover-light-bg {
	position: absolute;
	top: 3px;
	left: 3px;
	width: calc(100% - 6px);
	height: 244px;
	background-color: rgba(255, 255, 255, 0.50);
	transition: background-color 300ms ease-in-out;
	border-radius: 4px;
	z-index: 4;
}

.new-property-card-image-hover-light-bg .property-select-container {
	opacity: 1;
	visibility: visible;
}

.new-property-card-image-container .new-property-card-image-hover-light-bg {
	top: 0px;
	left: 0px;
	width: 100%;
	height: 244px;
}

.new-property-card-image-hover-dark-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 250px;
	background-color: rgba(0, 0, 0, 0);
	transition: background-color 300ms ease-in-out;
	border-radius: var(--corner-regular);
	z-index: 4;
}
  
.new-property-card-image-hover-dark-bg.view-none {
	background-color: rgba(0, 0, 0, 0);
}
  
.new-property-card-image-hover-dark-bg:hover {
	background-color: rgba(0, 0, 0, 0.5);
}

.new-property-card-image-hover-dark-bg .light-button,
.new-property-card-image-hover-dark-bg .tertiary-button {
	opacity: 0;
	visibility: hidden;
	transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
	position: absolute;
	bottom: -8px;
	width: calc(100% - 16px);
	margin-left: auto;
	margin-right: auto;
	left: 0px;
	right: 0px;
	animation: 0.3s ease-in-out normal offer-button-move;
}

.new-property-card-image-hover-dark-bg:hover .property-select-container,
.new-property-card-image-hover-dark-bg:hover .light-button,
.new-property-card-image-hover-dark-bg:hover .tertiary-button {
	opacity: 1;
	visibility: visible;
}

.new-property-card-image-hover-dark-bg:hover .light-button,
.new-property-card-image-hover-dark-bg:hover .tertiary-button {
	bottom: 8px;
}

@keyframes offer-button-move {
	from {
		bottom: -8px;
	}
	to {
		bottom: 8px;
	}
}

.new-property-card-selected {
	border-radius: var(--corner-regular);
	border: 3px solid var(--purple-purple-02);
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

.new-property-card-selected .new-property-card-image-hover-dark-bg {
	left: 3px;
	top: 3px;
	border-radius: 4px;
	width: calc(100% - 6px);
	height: 244px;
}

.new-property-card-image-container.new-property-card-selected .new-property-card-image-hover-dark-bg {
	left: 0px;
	top: 0px;
	width: 100%;
	height: 244px;
}

.new-property-card-selected .custom-swiper-image-container {
	border-radius: 5px;
	height: 244px;
}

.new-property-card-info-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 4px;
}

.new-property-card-bottom-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 8px;
	width: 100%;
}

.new-property-card-roi-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 4px;
}

.new-property-card-inner-container .flag-container {
	top: 210px;
}

@media only screen and (min-width: 1000px) {
	.new-property-card-badges-onboarding .drip-score-badge-outer-container {
		position: relative;
		z-index: 13;
	}
}

@media only screen and (max-width: 767px) {
	.new-property-card-partner-container {
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
	}

	.new-property-card-partner-left-container {
		margin-bottom: 8px;
	}
}