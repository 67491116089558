.join-affiliate-outer-container {

}

.join-affiliate-inner-container {
    min-height: 80vh;
    width: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
    padding-top: 56px;
}

.join-affiliate-inner-container .loading-container {
    position: fixed;
    top: 15%;
}

.join-affiliate-title-container {
    max-width: 664px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.join-affiliate-body-container {
    max-width: 438px;
    width: 100%;
    padding-bottom: 160px;
}

.join-affiliate-button-container {
    max-width: 272px;
    margin: 0 auto;
}

@media only screen and (max-width: 999px) {
    .join-affiliate-inner-container {
        padding-top: 16px;
    }
}