.partner-splash-screen-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: calc(100vh - 56px);
    position: fixed;
    z-index: 20;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
}

.partner-splash-screen-inner-container {
    max-width: 543px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 48px;
}

.partner-splash-body-container {
    max-width: 445px;
    margin-left: auto;
    margin-right: auto;
}

.partner-splash-body-container .partner-banner-container {
    margin-bottom: 0px;
}

.partner-splash-screen-button-container {
    width: 234px;
}

@media only screen and (max-width: 999px) {
    .partner-splash-screen-inner-container {
        width: calc(100% - 32px);
    }
}