.mortgage-type-filter-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 32px;
}

.mortgage-type-filter-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 8px;

}

.mortgage-type-title-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 4px;
}

.mortgage-type-body-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    width: 100%;
}

@media only screen and (max-width: 999px) {
    .new-buy-box-inner-body-container .mortgage-type-filter-container {
        padding-bottom: 120px;
    }
}