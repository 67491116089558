.onboarding-hover-outer-container {
	border-radius: 14px;
	background-color: var(--grayscale-white);
	box-shadow: 0px 0px 10px 0px rgba(116, 142, 160, 0.50);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	position: absolute;
	z-index: 13;
	left: -20px;
	width: 310px;
	cursor: default;
}

.onboarding-hover-top-pick-outer-container {
	left: 0px;
	top: 50px;
}

.onboarding-hover-csv-outer-container {
	right: 0px;
	left: unset;
	top: 50px;
}

.onboarding-hover-help-outer-container {
	right: 0px;
	left: unset;
	top: 50px;
}

.onboarding-hover-inner-container {
	padding: 24px 16px;
}

.onboarding-hover-title-text {
	font-family: var(--font-family);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	color: var(--grayscale-black);
	margin-top: 0px;
	margin-bottom: 8px;
}

.onboarding-hover-body-text {
	color: var(--grayscale-black);
	font-family: var(--font-family);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	margin: 0px;
}

.onboarding-hover-button-container {
	margin-top: 16px;
}

.onboarding-hover-button-element {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}

.onboarding-hover-top-pick-outer-container .onboarding-hover-button-container,
.onboarding-hover-csv-outer-container .onboarding-hover-button-container,
.onboarding-hover-help-outer-container .onboarding-hover-button-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.onboarding-hover-button-text {
	font-family: var(--font-family);
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 18px;
	color: var(--blue-blue-03);
	text-align: center;
	margin-right: 8px;
	cursor: pointer;
}

.onboarding-hover-button-back-text {
	font-family: var(--font-family);
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 18px;
	color: var(--blue-blue-02);
	text-align: center;
	cursor: pointer;
}

.onboarding-hover-button-chevron {
	height: 8px;
}

.onboarding-hover-button-chevron-back {
	height: 8px;
	transform: rotate(180deg);
	margin-right: 8px;
}

.new-filters-icon-download-container-onboarding {
	background-color: var(--beige-beige-01);
	z-index: 13;
	padding: 12px;
	border-radius: 20px;
	position: relative;
	margin-right: 16px;
}

@media only screen and (min-width: 1900px) {
	.onboarding-hover-outer-container {
		left: -30%;
	}
}

@media only screen and (max-width: 999px) {
	.onboarding-hover-outer-container {
		display: none;
	}	
}