.comps-tooltip-outer-container {
    top: 60px;
}

.comps-tooltip-outer-container .default-tooltip-beak {
    left: 20%;
}

.comps-tooltip-row-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.comps-tooltip-left-container {
    margin-right: 8px;
}

.comps-tooltip-emoji-container {
    border-radius: 32px;
    background-color: var(--beige-beige-02);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
}

.comps-tooltip-emoji {
    font-size: 16px;
}

.comps-tooltip-text-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 8px;
}

.comps-tool-badge-container {
    border-radius: 4px;
    background-color: var(--indigo-indigo-00);
    padding: 2px 8px;
}

.comps-tooltip-exit-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}