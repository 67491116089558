.reset-password-inner-container {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 32px;
    padding-top: 40px;
}

.reset-password-inner-container .loading-container {
    top: 20%;
}

.reset-password-body-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 500px;
    gap: 16px;
}

.reset-password-input-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.reset-password-button-container {
    max-width: 130px;
    width: 100%;
}

.repeat-password-button-container {
    width: 100%;
    max-width: 170px;
}

@media only screen and (max-width: 999px) {
    .reset-password-inner-container {
        padding-top: 24px;
        width: calc(100% - 32px);
        margin: 0 auto;
    }

    .repeat-password-button-container {
        width: 100%;
        max-width: 200px;
    }
}