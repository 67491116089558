.property-list-top-bar-outer-container {
    position: relative;
}

.property-list-top-bar-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: var(--corner-regular) 16px;
    border-bottom: 1px solid var(--grayscale-gray-00);
    background-color: var(--grayscale-white);
}

.property-list-top-bar-left-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.property-list-top-bar-filters-icon {
    width: 16px;
    height: 16px;
}

.property-list-top-bar-right-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
}

.property-list-top-bar-dropdown-container {
    width: 221px;
}

.property-list-top-bar-active-filters-container .secondary-button {
    border: 2px solid var(--blue-blue-03);
}

.property-list-top-bar-sort-container .dropdown-options-container {
    width: 180px;
}

.property-list-top-bar-left-container .tooltip-context-outer-container {
    left: 0px;
}

@media only screen and (max-width: 999px) {
    .property-list-top-bar-inner-container {
        padding: 4px 8px;
    }

    .property-list-top-bar-left-container {
        width: 100%;
        justify-content: space-between;
    }

    .property-list-top-bar-mobile-tabs-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0px;
    }

    .property-list-top-bar-inner-container .badge-standard-bg-border {
        top: 0px;
        right: 0px;
    }
}