.buy-boxes-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    overflow: hidden;
    height: calc(100vh - 56px);
}

.buy-boxes-body-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 32px;
    overflow-y: scroll;
    width: calc(100% - 280px);
}

.buy-boxes-body-container .loading-container {
    top: 20%;
}

.buy-boxes-inner-body-container {
    max-width: 1220px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
}

.buy-boxes-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.buy-boxes-title-right-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}

.buy-boxes-search-icon {
    width: 16px;
}

.buy-boxes-sort-container {
    width: 91px;
}

.buy-boxes-new-buy-box-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 137px;
}

.buy-boxes-sort-container .dropdown-options-container {
    width: 120px;
}

.buy-boxes-cards-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    width: 100%;
}

.buy-boxes-inner-container .modal-outer-container {
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    top: 20%;
    position: fixed;
    z-index: 13;
}

.buy-boxes-cards-container .primary-button {
    max-width: 172px;
}

.buy-boxes-inner-container .toast-outer-container {
    position: fixed;
    z-index: 2;
    right: 32px;
    bottom: 32px;
    min-width: 200px;
}

@media only screen and (max-width: 999px) {
    .buy-boxes-inner-container {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        overflow: unset;
        padding-bottom: 140px;
    }

    .buy-boxes-body-container {
        padding: 16px;
        width: calc(100% - 32px);
        overflow-y: unset;
    }

    .buy-boxes-cards-container .primary-button {
        max-width: 230px;
    }

    .buy-boxes-sticky-button {
        position: fixed;
        z-index: 1;
        bottom: 52px;
        left: 0px;
        width: calc(100% - 32px);
        padding: 16px;
        background-color: var(--grayscale-white);
    }

    .buy-boxes-outer-container .footer-outer-container {
        display: none;
    }

    .buy-boxes-mobile-modal-open .modal-outer-container {
        bottom: 0px;
        top: unset;
    }

    .buy-boxes-mobile-modal-open .mobile-bottom-nav-outer-container {
        display: none;
    }
}