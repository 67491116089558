.new-property-map-outer-container {
	width: 100%;
	overflow-x: hidden;
}

.new-property-map-inner-container {
	width: 100%;
	position: relative;
}

.new-property-map-element {
	height: calc(100vh - 111px);
	margin-left: auto;
	position: relative;
	position: relative;
}

.new-property-map-overlay-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 4px 8px;
	border-radius: 100px;
	border: 2px solid var(--brown-brown-01);
	background: var(--grayscale-white);
	box-shadow: 0px 0px 8px 0px rgba(153, 149, 145, 0.50);
	z-index: 2;
}

.new-property-map-overlay-text {
	color: var(--brown-brown-01);
	text-align: center;
	font-size: 12px;
	font-family: var(--font-family);
	font-style: normal;
	font-weight: 600;
	line-height: 14px;
}

.new-property-map-overlay-container-viewed {
	background-color: var(--grayscale-gray-01);
}

.new-property-map-overlay-container-selected {
	background-color: var(--brown-brown-01);
}

.new-property-map-overlay-container-selected .new-property-map-overlay-text {
	color: var(--grayscale-white);
}

.gm-fullscreen-control {
	display: none !important;
}

.property-search-card-image-container {
	justify-content: center !important;
	align-items: center !important;
}

.metro-map-container {
	position: relative;
}

.metro-map-container .toast-message-outer-container {
	bottom: unset;
	top: 15%;
	left: 0px;
	right: 0px;
	margin-left: auto;
	margin-right: auto;
	max-width: 515px;
	width: 80%;
	position: absolute;
}