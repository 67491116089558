.image-grid-outer-container .light-button {
    position: absolute;
    z-index: 2;
    left: 8px;
    bottom: 8px;
    max-width: 95px;
}

.street-view-location-pin {
    width: 8px;
}

.street-view-redirect-arrow {
    width: 8px;
}

@media only screen and (max-width: 999px) {
    .image-grid-outer-container .light-button {
        max-width: 130px;
    }
}