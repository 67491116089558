.upload-component-container {
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 40px;
}

.upload-icon {
	width: 75px;
}

.upload-pof-button-container {
	margin-top: 40px;
}