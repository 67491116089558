.deal-optimiser-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.deal-optimiser-section-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.deal-optimiser-section-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    width: calc(100% - 2px);
}

.deal-optimiser-section-row-small {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
}

.deal-optimiser-short-input-container {
    max-width: 131px;
}

.deal-optimiser-section-row .deal-optimiser-section-row-small .financials-input-wrapper,
.deal-optimiser-section-row .deal-optimiser-section-row-long .financials-input-wrapper,
.deal-optimiser-section-row .deal-optimiser-long-input-container .financials-input-wrapper,
.deal-optimiser-section-row-small .deal-optimiser-short-input-container .financials-input-wrapper {
    max-width: 113px;
}

.deal-optimiser-medium-input-container {
    width: 50%;
}

.deal-optimiser-container .filter-spacing-container {
    padding: 0px;
}

.deal-optimiser-separator-container {
    margin-top: auto;
    margin-bottom: 8px;
}