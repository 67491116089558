.history-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(100% - 96px);
    margin: 0 auto;
}

.history-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
}

.history-chips-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
}

.history-body-container {
    width: 100%;
}

.history-table-container {
    width: 100%;
}

.history-table-heading-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.history-table-heading,
.history-table-body {
    padding: 16px;
    float: left;
    width: calc(25% - 32px);
    text-align: left;
    border-bottom: 1px solid var(--grayscale-gray-00);
    background-color: var(--grayscale-white);
}

.history-table-body-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.history-table-body-element {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.history-table-body {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}

@media only screen and (max-width: 999px) {
    .history-outer-container {
        width: 100%;
    }

    .history-body-container {
        overflow-x: scroll;
    }

    .history-table-heading-container {
        white-space: nowrap;
    }
}