.pagination-component-container {
	/* position: fixed; */
	bottom: 0px;
	z-index: 5;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	background-color: var(--beige-beige-01);
}

.pagination-outer-container {
	width: 100%;
	padding: 24px 0px;
}

.pagination-inner-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	flex-wrap: wrap;
	gap: 4px;
}

.pagination-element {
	cursor: pointer;
	text-decoration-line: none;
	color: var(--grayscale-black);
	height: 30px;
	width: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.pagination-element-selected {
	background-color: var(--blue-blue-01);
	height: 30px;
	width: 30px;
	display: block;
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.pagination-element-dots {
	cursor: default;
}

.pagination-element:hover {
	background-color: var(--blue-blue-01);
	height: 30px;
	width: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 15px;
}

.pagination-element-dots:hover {
	background-color: unset;
}

.pagination-element-dots:hover span {
	color: var(--grayscale-black);
	font-weight: 400;
}

.pagination-chevron-left {
	transform: rotate(90deg);
	width: 16px;
	height: 16px;
}

.pagination-chevron-right {
	transform: rotate(-90deg);
	width: 16px;
	height: 16px;
}

@media only screen and (max-width: 999px) {
	.pagination-outer-container {
		padding-bottom: 140px;
	}
}