.available-markets-inner-container {
    min-height: 80vh;
    padding-top: 40px;
    gap: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 96px);
    margin: 0 auto;
    padding-bottom: 120px;
    max-width: 1140px;
}

.available-markets-inner-container .loading-container {
    top: 20%;
}

.available-markets-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.available-markets-border {
    border-radius: 100px;
    background-color: var(--grayscale-gray-01);
    width: 100%;
    height: 1px;
}

.available-markets-body-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.available-markets-inner-container .modal-outer-container {
    position: fixed;
    z-index: 15;
    top: 10%;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 999px) {
    .available-markets-inner-container {
        padding-top: 24px;
        width: calc(100% - 32px);
        padding-bottom: 80px;
    }

    .available-markets-inner-container .modal-outer-container {
        top: 80px;
    }

    .available-markets-inner-container .modal-outer-container .modal-body-container {
        max-height: calc(100dvh - 370px);
		overflow-y: scroll;
    }
}

@media only screen and (max-width: 499px) {
    .available-markets-inner-container .secondary-button {
        display: none;
    }
}