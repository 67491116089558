.clear-filters-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: var(--corner-regular);
    background-color: var(--grayscale-gray-00);
    width: 100%;
}

.clear-filters-inner-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    max-width: 433px;
}