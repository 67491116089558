@media only screen and (min-width: 1000px) {
    .mobile-strategies-panel-outer-container {
        display: none;
    }
}

@media only screen and (max-width: 999px) {
    .mobile-strategies-panel-outer-container {
        height: 75vh;
        width: 100%;
        position: absolute;
        z-index: 11;
        bottom: 0%;
        background-color: var(--grayscale-white);
        border-radius: 24px 24px 0px 0px;
        box-shadow: 0px 8px 44.9px 50px rgba(38, 41, 33, 0.40);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        left: 0px;
    }

    .mobile-financials-outer-container-hide-overlay {
        bottom: -1400%;
        animation: 0.5s ease-in-out normal mobile-financials-overlay-slideout;
        border-radius: 24px 24px 0px 0px;
        overflow-y: hidden;
        box-shadow: 0px 8px 44.9px 50px rgba(38, 41, 33, 0.40);
    }
    
    .mobile-strategies-panel-inner-container {
        width: calc(100% - 32px);
        padding: 16px 0px;
        padding-bottom: 160px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        overflow-x: hidden;
        margin-left: 16px;
        margin-right: auto;
        overflow-y: scroll;
    }

    .mobile-strategies-panel-max-width-container {
        max-width: 430px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        margin: 0 auto;
    }

    .mobile-strategies-panel-financials-container {
        width: 100%;
    }

    .mobile-strategies-panel-selector-container {
        width: 100%;
    }

    .mobile-strategies-panel-message-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .mobile-strategies-panel-message-container .text-button {
        margin: 0 auto;
    }

    .mobile-strategies-panel-message-container svg {
        margin-right: 4px;
    }

    .mobile-strategies-panel-separator {
        border-radius: 100px;
        background-color: var(--grayscale-gray-01);
        height: 1px;
        width: 100%;
    }

    .mobile-strategies-panel-financials-element {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .mobile-strategies-panel-financials-inner-element {
        padding: 16px;
        width: calc(100% - 32px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .mobile-strategies-panel-mobile-accordion .rent-accordion-row,
    .rent-estimates-value {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .mobile-strategies-panel-mobile-accordion .bg-colour-light {
        background-color: var(--blue-blue-0150);
    }

    .mobile-strategies-panel-mobile-accordion .rent-accordion-element-container {
        margin-right: 0px;
        margin-bottom: 16px;
    }

    .property-inner-body-container .exit-screen-container {
        top: -50vh;
        height: 1000vh;
    }

    .mobile-strategies-panel-column {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}