.partner-banner-container {
    max-width: 1234px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 3px;
    background-color: var(--grayscale-white);
    box-shadow: 0px 1px 4px 0px rgba(207, 188, 173, 0.30);
    width: 90%;
    margin: 0 auto;
}

.dashboard-inner-container .partner-banner-container {
    margin-left: 0px;
}

.partner-inner-banner-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 32px 0px;
    width: calc(100% - 64px);
    margin: 0 auto;
}

.partner-image-container {
    margin-right: 32px;
}

.partner-image-loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 96px;
    height: 96px;
    border-radius: 3px;
    border: 0.5px solid var(--blue-blue-01);
}

.partner-profile-image {
    width: 96px;
    height: 96px;
    border-radius: 3px;
    border: 0.5px solid var(--blue-blue-01);
}

.partner-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.partner-banner-copy-link-container {
	padding: 4px 8px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
    margin-left: 16px;
}

.partner-company-info-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.partner-banner-info-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
}

.partner-company-logo {
    width: 24px;
    margin-right: 8px;
}

.partner-company-info-contact-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.partner-company-phone-number-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.partner-company-email-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.partner-company-email-container .body-regular:hover {
    text-decoration-line: underline;
}

@media only screen and (max-width: 499px) {
    .partner-banner-container {
        align-items: center;
    }
    
    .partner-inner-banner-container {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 32px 0px;
        width: calc(100% - 32px);
    }

    .partner-image-container {
        margin-right: 0px;
        margin-bottom: 16px;
    }
}

.property-outer-container .partner-banner-container {
    width: 100%;
    max-width: 432px;
    margin-right: auto;
    margin-left: 0px;
    box-shadow: none;
}

.partner-company-info-link:hover span {
    text-decoration-line: underline;
}

.property-outer-container .partner-inner-banner-container {
    width: calc(100% - 32px);
    padding: 16px 0px;
}

.property-outer-container .partner-image-container {
    margin-right: 16px;
}

.contact-agent-inner-container .partner-banner-container {
    margin-bottom: 0px;
}

.partner-banner-extra-info-container {
    border-top: 1px solid var(--grayscale-gray-02);
    padding: 16px 0px;
    width: calc(100% - 32px);
    margin: 0 auto;
}

.parnter-banner-message-container {
    font-family: var(--font-family);
    color: var(--grayscale-black);
}

.parnter-banner-message-container a {
    color: var(--blue-blue-03);
    font-family: var(--font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.parnter-banner-message-container p {
    font-family: var(--font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}