.zero-state-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: var(--corner-regular);
    background-color: var(--grayscale-gray-00);
    width: 100%;
}

.zero-state-inner-container {
    padding: var(--corner-xl) var(--corner-large);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--corner-regular);
    width: calc(100% - var(--corner-large) * 2);
    max-width: 496px;
}

.zero-state-icon {
    width: 32px;
    height: 32px;
}