.price-cut-outer-container {
    position: relative;
    height: 100%;
}

.price-cut-container {
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 8px;
    z-index: 2;
    bottom: 20px;
    border-radius: 100px;
    background-color: rgba(41, 33, 33, 0.50);
}

.price-cut-icon {
    width: 8px;
}