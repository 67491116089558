.lease-option-rent-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
}

.lease-option-price-element {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    width: 50%;
}

.lease-option-price-element .tooltip-outer-container {
    top: 40px;
    right: -122px;
    position: absolute;
    z-index: 2;
    width: 300px;
}

.lease-option-rent-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.lease-option-option-fee-element {
    width: 65%;
}

.lease-option-option-fee-element .tooltip-outer-container {
    top: 40px;
    left: 0px;
    position: absolute;
    z-index: 2;
    width: 300px;
}

.lease-option-option-fee-element .tooltip-beak-container,
.lease-option-interest-element .tooltip-beak-container {
    display: none;
}

.lease-option-interest-element {
    width: 35%;
}

.lease-option-interest-element .tooltip-outer-container {
    top: 40px;
    right: 0px;
    position: absolute;
    z-index: 2;
    width: 300px;
}

.lease-option-results-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    width: 100%;
}

.lease-option-disclaimer-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}