.default-user-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--beige-beige-03);
    margin-right: 12px;
}

.default-user-image-large {
    width: 40px;
    height: 40px;
    border-radius: 20px;
}

.default-user-image-small {
    width: 28px;
    height: 28px;
    border-radius: 14px;
}