.last-updated-outer-container {
    width: calc(100% - 96px);
    margin: 0 auto;
}

.last-updated-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
}

.last-updated-body-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}

.last-updated-zillow-link-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}

@media only screen and (max-width: 999px) {
	.last-updated-outer-container {
		width: calc(100% - 32px);
	}

    .last-updated-body-row {
        flex-direction: column;
        align-items: flex-start;
    }
}