.sale-status-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    border-radius: 100px;
    background-color: var(--grayscale-gray-05);
    gap: 4px;
}

.sale-status-right-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2px;
}

.sale-status-arrow {
    width: 16px;
    height: 16px;
}