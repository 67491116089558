.custom-swiper-outer-container {
	width: 250px;
}

.custom-swiper-image-container {
	background-repeat: no-repeat;
	object-fit: cover;
	width: 100%;
	height: 250px; 
    overflow: hidden;
	position: relative;
	border-radius: 3px 0px 0px 3px;
}

.custom-swiper-image {
	width: 100%;
	height: 100%;
    object-position: center;
    object-fit: cover;
}

.swiper-pagination-bullet {
	background-color: var(--beige-beige-01);
	opacity: 1;
	margin-top: 5px !important;
	margin-bottom: 5px !important;
}

.swiper-pagination-bullets-dynamic {
	background-color: transparent;
}

.swiper-pagination {
	height: 16px;
	width: 65px;
	border-radius: 10px 10px 0px 0px;
	bottom: 0px !important;
}

.swiper-button-next, .swiper-button-prev {
	color: var(--grayscale-black) !important;
	background-color: var(--grayscale-white);
	border-radius: 12px;
	height: 24px;
	width: 24px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.swiper-button-next:after, .swiper-button-prev:after {
	/* font-size: 16px; */
	display: none !important;
}

.swiper-button-next .custom-swiper-icon,
.swiper-button-prev .custom-swiper-icon {
	width: 8px;
}

.swiper-button-prev .custom-swiper-icon {
	transform: rotate(180deg);
}

@media only screen and (min-width: 1000px) {
	.custom-swiper-module-container .swiper-button-prev,
	.custom-swiper-module-container .swiper-button-next {
		opacity: 0;
		animation: 0.25s ease-in-out normal hide-swiper-buttons;
	}

	.custom-swiper-module-container-hover .swiper-button-prev,
	.custom-swiper-module-container-hover .swiper-button-next {
		opacity: 1;
		animation: 0.25s ease-in-out normal show-swiper-buttons;
	}

	@keyframes show-swiper-buttons {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	@keyframes hide-swiper-buttons {
		from {
			opacity: 1;
		}
		to {
			opacity: 0;
		}
	}
}