.fsbo-only-outer-container {
    margin-top: 32px;
    width: 40%;
}

.fsbo-only-inner-container {
    width: calc(100% - 32px);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

@media only screen and (max-width: 999px) {
    .fsbo-only-outer-container {
        margin-top: 0px;
        margin-left: 0px;
        width: 100%;
    }
}