.double-tap-message-outer-container {
	box-shadow: 0px 0px 10px 0px #748EA080;
	background-color: var(--grayscale-white);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 95%;
	position: absolute;
	z-index: 2;
	top: 8px;
	left: 0px;
	right: 0px;
	margin-left: auto;
	margin-right: auto;
	border-radius: 14px;
	max-width: 400px;
}

.double-tap-message-inner-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 24px 0px;
	width: 90%;
}

.double-tap-message-text-container {
	width: 80%;
}

.double-tap-message-text {
	font-family: var(--font-family);
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: 0em;
	text-align: left;
	color: var(--grayscale-black);
}

.double-tap-message-cta-container {
	width: 20%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
}

.double-tap-message-cta-text {
	font-family: var(--font-family);
	font-size: 12px;
	font-weight: 600;
	line-height: 14px;
	letter-spacing: 0em;
	text-align: left;
	color: var(--blue-blue-03);
	cursor: pointer;
}

@media only screen and (min-width: 768px) {
	.double-tap-message-outer-container {
		display: none;
	}	
}