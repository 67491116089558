.rent-accordion-outer-container .MuiAccordionSummary-root {
    min-height: unset !important;
}

.rent-accordion-outer-container .MuiAccordionSummary-content {
    margin: 0px;
}

.rent-accordion-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 8px;
}

.rent-accordion-title-container-reverse svg {
    transform: rotate(180deg);
}

.rent-accordion-element-container {
    margin-right: 16px;
}

.rent-accordion-element-container:last-of-type {
    margin-right: 0px;
}