.onboarding-tooltip-outer-container {
    display: flex;
    padding: var(--corner-large) 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--corner-regular);
    border-radius: 14px;
    background-color: var(--grayscale-white);
    box-shadow: 0px 8px 24px 8px rgba(41, 38, 33, 0.15);
    max-width: 308px;
}

.onboarding-tooltip-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 8px;
}

.onboarding-tooltip-text-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
}

.onboarding-tooltip-beak-container {
    width: 100%;
}

.onboarding-tooltip-beak {
    position: absolute;
    z-index: 1;
}

.onboarding-tooltip-beak-left {
    left: -30px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    transform: rotate(90deg);
}

.onboarding-tooltip-beak-right {
    right: -30px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    transform: rotate(270deg);
}

.onboarding-tooltip-beak-top {
    top: -38px;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    transform: rotate(180deg);
}

.onboarding-tooltip-beak-bottom {
    bottom: -38px;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
}

.onboarding-tooltip-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 8px;
}

.onboarding-tooltip-button-row-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}

.onboarding-tooltip-inner-container .dots-container {
    gap: 4px;
}

.onboarding-tooltip-bottom-left-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}