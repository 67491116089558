.apply-estimate-modal-outer-container {
    border-radius: 4px;
    box-shadow: 0px 2px 12px 0px rgba(207, 188, 173, 0.50);
    max-width: 521px;
    width: 100%;
    position: absolute;
    z-index: 15;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
    max-height: 496px;
}

.apply-estimate-modal-rent-container {
    max-height: 350px;
}

.apply-estimate-inner-container {
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.apply-estimate-exit-container {
    top: 32px;
    right: 32px;
    position: absolute;
    z-index: 1;
}

.apply-estimate-exit-icon {
    width: 20px;
}

.apply-estimate-body-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.apply-estimate-input-container .MuiInputAdornment-root {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.apply-estimate-was-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 40px;
}

.apply-estimate-radios-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.apply-estimate-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.apply-estimate-original-button-container {
    width: calc(50% - 8px);
}

.apply-estimate-save-button-container {
    width: calc(50% - 8px);   
}