.no-buy-box-card-outer-container {
    border-radius: 8px;
    background-color: var(--beige-beige-01);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 369px;
    max-width: 400px;
}

.no-buy-box-card-inner-container {
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: calc(100% - 64px);
}

.no-buy-box-card-plus-container {
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.no-buy-box-card-plus-container svg {
    width: 20px;
}

.no-buy-box-card-text-container {
    margin-top: auto;
}

@media only screen and (max-width: 999px) {
    .no-buy-box-card-outer-container {
        background-color: var(--grayscale-white);
    }
}