.premium-modal-outer-container {
    border-radius: 4px;
    background-color: var(--beige-beige-01);
    box-shadow: 0px 2px 12px 0px rgba(207, 188, 173, 0.50);
    max-width: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    z-index: 10000;
    top: 20%;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
}

.premium-modal-inner-container {
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.premium-modal-close-container {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0px;
    top: 0px;
}

.premium-modal-list-container {
    padding-left: 0px;
}

.premium-modal-list-element {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.premium-modal-list-element svg {
    width: 16px;
    margin-right: 4px;
}

.premium-modal-buttons-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0px;
    width: 100%;
}

.premium-modal-button-container {
    width: 200px;
}

@media only screen and (max-width: 999px) {
    .premium-modal-outer-container {
        width: calc(100% - 32px);
        top: 10%;
    }

    .premium-modal-inner-container {
        padding: 16px;
    }

    .premium-modal-close-container {
        right: 16px;
    }
}