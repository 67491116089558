.schools-outer-container {
    padding: 48px 0px;
	border-bottom: 0.5px solid var(--grayscale-gray-02);
    width: 100%;
    max-width: 440px;
}

.schools-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.schools-subheading-container {
    margin-top: 8px;
    width: 100%;
}

.schools-body-container {
    margin-top: 24px;
}

.schools-element-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 8px;
}

.schools-element-left-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
}

.schools-element-right-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.schools-element-container:last-of-type {
    margin-bottom: 0px;
}

.schools-element-text-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.schools-element-right-container .schools-score-text {
    margin-left: 8px;
    display: block;
}

@media only screen and (max-width: 999px) {
    .schools-outer-container {
        max-width: none;
    }

    .schools-element-container {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .schools-element-left-container {
        width: 100%;
    }
    
    .schools-element-right-container {
        width: 100%;
        padding-left: 0px;
    }
}