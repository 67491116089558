.bulk-action-outer-container {
    display: flex;
    max-width: 572px;
    width: 100%;
    padding: 16px var(--corner-large);
    justify-content: space-between;
    align-items: center;
    border-radius: var(--corner-large);
    border: 1px solid var(--grayscale-white);
    background-color: var(--grayscale-black);
    box-shadow: 0px 8px 24px 8px rgba(41, 38, 33, 0.15);
    position: sticky;
    z-index: 12;
    bottom: 0%;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
}

.bulk-action-side-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}