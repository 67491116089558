.price-change-container {
    margin-left: 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.price-change-label-container {
    margin-right: 4px;
}

.price-change-icon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 2px;
}

.price-change-property-page .price-change-icon-container {
    margin-right: 4px;
}

.price-change-icon {
    width: 8px;
}

.price-change-text-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.price-change-price-text {
    margin-right: 2px !important;
}

.price-change-property-page .price-change-price-text {
    margin-right: 4px !important;
}

@media only screen and (max-width: 332px) {
    .price-change-container {
        margin-left: 0px;
        margin-top: 8px;
    }
}