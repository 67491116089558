.about-outer-container {
	background-color: var(--beige-beige-01);
}

.about-inner-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	width: 90vw;
	padding-bottom: 400px;
	max-width: 600px;
}

.about-inner-container h1 {
	text-align: center;
}

.about-text-container {
	line-height: 24px;
}