.edit-listings-upload-images-row .uploaded-image-container {
    margin-bottom: 8px;
    margin-right: 8px;
}

.uploaded-image-element {
    height: 120px;
    width: auto;
    display: block;
}

.uploaded-image-delete-container {
    position: absolute;
    z-index: 1;
    top: 8px;
    right: 8px;
    width: 24px;
    height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
}