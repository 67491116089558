.emerging-area-tooltip-container {
    overflow: hidden;
}

.emerging-area-tooltip-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 16px;
}

.emerging-area-tooltip-image-container {
    background-color: var(--beige-beige-02);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    width: 48px;
    height: 48px;
    margin-right: 8px;
}

.emerging-area-tooltip-image {
    width: 32px;
}

.emerging-area-tooltip-text-container {
    margin-right: 8px;
}

.emerging-area-tooltip-beak {
    bottom: -15px;
    position: absolute;
    z-index: 1;
    transform: rotate(180deg);
}

.new-property-map-inner-container .gm-style-iw button {
    height: 28px !important;
    width: 28px !important;
}

.new-property-map-inner-container .gm-style-iw button span {
    margin: 0px auto !important;
}

.emerging-area-tooltip-container .icon-button-element {
    position: absolute;
    z-index: 1;
    top: 4px;
    right: 4px;
}