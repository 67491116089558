.pricing-cta-long-switch-element-container {
	background-color: var(--blue-blue-01);
	height: 26px;
	border-radius: 100px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: relative;
}

.pricing-cta-long-switch-element-inner-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: baseline;
	padding: 8px;
	/* width: calc(100% - 16px); */
}

.pricing-cta-long-switch-left-container {
	padding-right: 8px;
}

.pricing-cta-long-switch-right-container {
	padding-left: 8px;
}

.pricing-cta-long-switch-left-container .label-semibold,
.pricing-cta-long-switch-right-container .label-semibold {
	line-height: 20px;
}

.pricing-cta-thumb-element {
	background-color: var(--grayscale-white);
	width: 75px;
	height: 22px;
	border-radius: 100px;
	position: absolute;
	z-index: 1;
	left: 2px;
	cursor: pointer;
}

.pricing-cta-long-switch-text {
	position: relative;
	z-index: 2;
	cursor: pointer;
}

.pricing-cta-move-right {
	animation: 0.5s ease-in-out normal pricing-switch-right;
	left: 78px;
	width: 90px;
}

@keyframes pricing-switch-right {
	from {
		left: 2px;
		width: 78px;
	}
	to {
		left: 71px;
		width: 90px;
	}
}

.pricing-cta-move-left {
	animation: 0.5s ease-in-out normal pricing-switch-left;
	left: 2px;
}

@keyframes pricing-switch-left {
	from {
		left: 71px;
		width: 90px;
	}
	to {
		left: 2px;
		width: 78px;
	}
}

.pricing-cta-long-switch-element-container .label-semibold {
	animation: 0.5s ease-in-out normal pricing-switch-text-bold;
}

@keyframes pricing-switch-text-bold {
	from {
		font-weight: 400;
	}
	to {
		font-weight: 600;
	}
}

.pricing-cta-long-switch-element-container .label-regular {
	animation: 0.5s ease-in-out normal pricing-switch-text-regular;
}

@keyframes pricing-switch-text-regular {
	from {
		font-weight: 600;
	}
	to {
		font-weight: 400;
	}
}

@media only screen and (max-width: 999px) {
	.pricing-switch-container .pricing-cta-long-switch-element-container {
		height: 48px;
	}

	.pricing-switch-container .pricing-cta-long-switch-element-inner-container {
		padding: 8px 16px;
	}
}