.predictive-search-container {
    position: absolute;
    z-index: 3;
    top: 38px;
    background-color: var(--grayscale-white);
    width: 100%;
    max-height: 250px;
    overflow-y: scroll;
    border-radius: var(--corner-regular);
    box-shadow: 0px 0px 8px 0px rgba(153, 149, 145, 0.50);
}

.predictive-search-label-container,
.predictive-search-loading-container {
    width: 100%;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: calc(100% - 16px);
    border-radius: var(--corner-regular);
}

.predictive-search-element-container {
    width: 100%;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: calc(100% - 16px);
    border-radius: var(--corner-regular);
}

.predictive-search-element-container:hover {
    background-color: var(--blue-blue-01);
}