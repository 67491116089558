.add-comp-outer-container {
    position: absolute;
    z-index: 13;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
    max-width: 600px;
    width: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-height: 335px;
    border-radius: 4px;
    box-shadow: 0px 2px 12px 0px rgba(207, 188, 173, 0.50);
}

.add-comp-inner-container {
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(100% - 64px);
}

.add-comp-exit-container {
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 16px;
    cursor: pointer;
}

.add-comp-exit-container svg {
    width: 20px;
}

.add-comp-input-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.add-comp-selected-comp-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.add-comp-selected-comp-attribute {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; 
}

.add-comp-icon {
    width: 16px;
    display: block;
    margin-right: 8px;
}

.add-comp-loading-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.add-comp-loading-container .new-property-list-loading-container {
    margin-right: 8px;
    width: 24px;
}

.add-comp-ctas-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 32px;
}

.add-comp-button-container {
    max-width: 200px;
    width: 100%;
}