.comps-analysis-outer-container {
    position: absolute;
    z-index: 12;
    top: 132px;
    left: 0px;
    width: 100%;
}

.comps-analysis-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.comps-analysis-left-container {
    width: 312px;
    position: fixed;
    z-index: 2;
    left: 0px;
    background-color: var(--beige-beige-01);
    overflow-y: scroll;
    height: 100%;
}

.comps-analysis-left-inner-container {
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 700px;
}

.tall-address-container {
    height: 38px;
}

.comps-analysis-subject-property-images-container .off-market-card-image-container,
.comps-analysis-selected-comp-image-container .off-market-card-image-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    height: 200px;
}

.comps-analysis-subject-property-images-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.comps-analysis-subject-property-images-container .custom-swiper-image-container,
.comps-analysis-selected-comp-image-container .custom-swiper-image-container {
    border-radius: 8px;
    height: 200px;
}

.comps-analysis-subject-property-images-container .custom-swiper-outer-container,
.comps-analysis-selected-comp-image-container .custom-swiper-outer-container {
    width: 100%;
    max-width: 264px;
}

.comps-analysis-subject-property-address-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.comps-analysis-arv-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 102px;
    background-color: var(--grayscale-white);
}

.comps-analysis-arv-inner-container {
    padding: 16px;
    width: calc(100% - 32px);
    margin: auto 0;
}

.comps-analysis-details-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px 24px;
    margin: 0 auto;
    width: 100%;
}

.comps-analysis-details-element {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.comps-analysis-map-container {
    width: calc(100% - 312px);
    height: calc(100vh - 132px);
    position: relative;
    z-index: 1;
    margin-left: auto;
}

.comps-analysis-map-element {
    width: 100%;
    height: 100%;
}

.comps-analysis-coffee-icon {
    position: absolute;
    z-index: 1;
    left: 0px;
    right: 0px;
    bottom: 30px;
    margin-left: auto;
    margin-right: auto;
}

.comps-analysis-comp-map-element {
    border-radius: 100px;
    border: 2px solid var(--brown-brown-01);
    box-shadow: 0px 0px 8px 0px rgba(153, 149, 145, 0.50);
    padding: 4px 8px;
    position: relative;
    z-index: 0;
}

.comps-analysis-comp-map-excluded-element {
    border-radius: 100px;
    box-shadow: 0px 0px 8px 0px rgba(153, 149, 145, 0.50);
    padding: 4px 8px;
    position: relative;
    z-index: 0;
    opacity: 0.7;
}

.comps-analysis-comp-map-excluded-element span {
    text-decoration-line: line-through;
    font-style: italic;
}

.comps-analysis-subject-property-map-element {
   z-index: 1;
}

.comps-analysis-selected-comp-outer-container {
    position: absolute;
    z-index: 2;
    border-radius: 8px;
    background-color: var(--beige-beige-01);
    box-shadow: 0px 2px 12px 0px rgba(207, 188, 173, 0.50);
    margin-left: 12px;
    margin-top: 16px;
    height: calc(100% - 32px);
    width: 312px;
    min-height: 312px;
    overflow-y: scroll;
}

.comps-analysis-selected-comp-inner-container {
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.comps-analysis-selected-comp-title-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.comps-analysis-selected-comp-image-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.comp-analysis-price-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 102px;
}

.comp-analysis-price-inner-container {
    padding: 16px;
    width: calc(100% - 32px);
}

.comps-analysis-selected-comp-exit-container {
    position: absolute;
    z-index: 1;
    right: 16px;
    top: 8px;
    cursor: pointer;
}

.comps-analysis-selected-comp-similarity-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.comps-analysis-difference-text {
    margin-left: 4px !important;
    display: block;
}

.comps-analysis-selected-comp-top-row-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.comps-analysis-selected-comp-button-container {
    width: 100%;
    max-width: 264px;
}

.comps-analysis-selected-comp-button-icon {
    margin-right: 12px;
}

.comps-analysis-details-right-element {
    justify-content: flex-end;
}

.comps-analysis-details-similarity-element {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.comps-analysis-progress-bar-container {
    max-width: 66px;
}

.comps-analysis-progress-bar-container svg {
    max-width: 66px;
}

.comps-analysis-first-custom-filter {
    top: 16px;
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 16px;
    right: 331px;
    max-width: 200px;
}

.comps-analysis-second-custom-filter {
    top: 16px;
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 16px;
    right: 549px;
    max-width: 190px;
}

.comps-analysis-all-filters-button {
    right: 177px;
    top: 16px;
    position: absolute;
    z-index: 1;
    width: 100%;
}

.comps-analysis-all-filters-button-short {
    max-width: 138px;
}

.comps-analysis-all-filters-button-long {
    max-width: 150px;
}

.rent-comps-analysis-outer-container .comps-analysis-all-filters-button {
    right: 16px;
}

.rent-comps-analysis-outer-container .comps-analysis-first-custom-filter {
    right: 177px;
}

.rent-comps-analysis-outer-container .comps-analysis-second-custom-filter {
    right: 395px;
}

.comps-analysis-add-comp-button {
    max-width: 145px;
    margin-top: 16px;
    margin-left: auto;
    margin-right: 16px;
    width: 100%;
}

.comps-analysis-add-comp-icon {
    margin-right: 12px;
}

.comps-analysis-bottom-bar {
    background-color: var(--blue-blue-03);
    box-shadow: 0px 1px 4px 0px rgba(207, 188, 173, 0.30);
    position: fixed;
    z-index: 5;
    width: 100%;
    top: 56px;
    height: 76px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.comps-analysis-bottom-inner-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    width: calc(100% - 32px);
}

.comps-analysis-bottom-left-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}

.comps-analysis-button-container {
    width: 124px;
}

.comps-analysis-bottom-right-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}

.comps-analysis-switch-container .pricing-cta-long-switch-element-container {
    height: 40px;
}

.comps-analysis-switch-container .pricing-cta-thumb-element {
    width: 105px;
    height: 36px;
    top: 2px;
}

.comps-analysis-switch-container .pricing-cta-move-right {
	animation: 0.5s ease-in-out normal comps-switch-right;
	left: 106px;
    width: 97px;
}

@keyframes comps-switch-right {
	from {
		left: 2px;
        width: 105px;
	}
	to {
		left: 106px;
        width: 97px;
	}
}

.comps-analysis-switch-container .pricing-cta-move-left {
	animation: 0.5s ease-in-out normal comps-switch-left;
	left: 2px;
}

@keyframes comps-switch-left {
	from {
		left: 106px;
        width: 97px;
	}
	to {
		left: 2px;
        width: 105px;
	}
}

.comps-analysis-switch-container .coming-soon-outer-container {
    left: 16px;
    margin-left: 0px;
}

.comps-analysis-market-stats-element {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.comps-analysis-outer-container .map-legend-outer-container {
    position: absolute;
    z-index: 2;
    bottom: 20px;
}

.comps-analysis-map-container .reset-button-outer-container {
    margin-left: unset;
    right: 755px;
}

.rent-comps-analysis-outer-container .reset-button-outer-container {
    right: 601px;
}

.comps-filters-icon {
    width: 16px;
    height: 16px;
}

@media only screen and (max-width: 1399px) {
    .comps-analysis-subject-property-images-container .custom-swiper-image-container,
    .comps-analysis-selected-comp-image-container .custom-swiper-image-container {
        border-radius: 8px;
        height: 150px;
    }
}

@media only screen and (max-width: 999px) {
    .comps-analysis-outer-container {
        display: none;
    }
}