.market-unavailable-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    width: 100%;
}

.market-unavailable-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.market-unavailable-body-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    gap: 16px;
    width: 100%;
}

.market-unavailable-element-container {
    padding: 40px 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    border-radius: var(--corner-regular);
    width: 100%;
}

.market-unavailable-free-trial-container {
    border: 1px solid var(--grayscale-gray-01);
}

.market-unavailable-upgrade-container {
    background-color: rgba(60, 68, 255, 0.10);
}

.market-unavailable-element-body-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
}

.market-unavailable-element-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}

@media only screen and (max-width: 999px) {
    .market-unavailable-body-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: calc(100% - 16px);
    }

    .market-unavailable-element-container {
        padding: 40px 16px;
    }
}