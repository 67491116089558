.financials-calculator-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 16px 24px;
    padding-bottom: 0px;
    width: calc(100% - 48px);
}

.financials-calculator-header-left-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    width: calc(100% - 112px);
    max-width: 408px;
}

.financials-calculator-header-element {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    width: 50%;
}

.financials-calculator-header-element .dropdown-outer-container {
    width: calc(100% - 16px);
}

.financials-calculator-header-sparkle {
    width: 8px;
    height: 8px;
}

.financials-calculator-header-right-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 16px;
}