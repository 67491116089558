.error-page-outer-container {
	background-color: var(--beige-beige-01);
}

.error-page-inner-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding-top: 40px;
	padding-bottom: 150px;
	width: 90vw;
	margin: 0 auto;
	max-width: 1400px;
	min-height: 55vh;
}

.error-page-image {
	width: 200px;
	margin-bottom: 24px;
}

.error-page-body-container {
	max-width: 360px;
}

.error-page-footer-container {
	margin-top: 32px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.error-page-text-margin {
	margin-right: 4px !important;
}

@media only screen and (max-width: 767px) {
	.error-page-inner-container {
		flex-direction: column;
		padding-top: 20px;
		padding-bottom: 80px;
	}

	
}