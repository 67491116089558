.unassigned-counties-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.unassigned-counties-badge-container {
    border-radius: 4px;
    background-color: var(--indigo-indigo-00);
    padding: 2px 8px;
}

@media only screen and (max-width: 999px) {
    .unassigned-counties-inner-container {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .unassigned-counties-inner-container .unassigned-counties-badge-container {
        margin-right: 0px;
        margin-bottom: 8px;
    }
}