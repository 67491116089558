.image-gallery-thumbnails {
	overflow-x: scroll;
}

.image-modal-exit-element {
	width: 20px;
	position: absolute;
	right: 5%;
	top: 10%;
	z-index: 8;
	cursor: pointer;
}