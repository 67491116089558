.black-friday-pricing-bg {
    background: linear-gradient(180deg, #EDF4F7 0%, #FFF 100%);
}

.pricing-inner-container {
    max-width: 1300px;
    margin: 0 auto;
    padding-top: 32px;
    width: 90%;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 32px;
}

.pricing-top-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.pricing-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.pricing-countdown-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pricing-countdown-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.pricing-countdown-element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pricing-yearly-savings-image {
    position: absolute;
    z-index: 1;
    width: 119px;
    top: 30px;
    right: -75px;
}

.pricing-switch-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 164px;
    margin: 0 auto;
}

.pricing-switch-container .pricing-cta-thumb-element {
    width: 68px;
    top: 2px;
}

.pricing-switch-container .pricing-cta-move-right {
    left: 70px;
    width: 55px;
    animation: 0.5s ease-in-out normal pricing-page-switch-right;
}

@keyframes pricing-page-switch-right {
	from {
		left: 2px;
		width: 68px;
	}
	to {
		left: 70px;
		width: 55px;
	}
}

.pricing-switch-container .pricing-cta-move-left {
	animation: 0.5s ease-in-out normal pricing-page-switch-left;
	left: 2px;
}

@keyframes pricing-page-switch-left {
	from {
		left: 70px;
		width: 55px;
	}
	to {
		left: 2px;
		width: 68px;
	}
}

.pricing-cards-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    gap: 24px;
}

.pricing-subtext-container {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 64px;
}

.pricing-subtext-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.pricing-loading-container {
    padding-top: 36px;
    position: relative;
}

@media only screen and (min-width: 1000px) {
    .pricing-mobile-button-container {
        display: none;
    }
}

@media only screen and (max-width: 999px) {
    .pricing-black-friday-switch-container {
        align-items: flex-start;
    }

    .pricing-cards-mobile-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding-top: 32px;
    }

    .pricing-subtext-container {
        max-width: 600px;
    }

    .pricing-mobile-button-container {
        max-width: 256px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        margin-top: 32px;
    }

    .pricing-mobile-button-outer-container {
        /* max-width: 600px; */
    }

    .pricing-mobile-button-sticky {
        position: fixed;
        z-index: 10;
        bottom: 54px;
        left: 0px;
        right: 0px;
        margin-left: auto;
        margin-right: auto;
        background-color: var(--grayscale-white);
        padding-bottom: 5%;
    }

    .pricing-switch-container .pricing-cta-thumb-element {
        width: 82px;
        top: 2px;
        height: 42px;
        top: 3px;
        width: 98px;
    }
    
    .pricing-switch-container .pricing-cta-move-right {
        left: 86px;
        width: 82px;
        animation: 0.5s ease-in-out normal pricing-page-switch-right;
    }
    
    @keyframes pricing-page-switch-right {
        from {
            left: 2px;
            width: 82px;
        }
        to {
            left: 86px;
            width: 82px;
        }
    }
    
    .pricing-switch-container .pricing-cta-move-left {
        animation: 0.5s ease-in-out normal pricing-page-switch-left;
        left: 2px;
    }
    
    @keyframes pricing-page-switch-left {
        from {
            left: 86px;
            width: 82px;
        }
        to {
            left: 2px;
            width: 98px;
        }
    }

    .pricing-yearly-savings-image {
        top: 34px;
        width: 140px;
        right: -80px;
    }

    .pricing-subtext-inner-container {
        flex-direction: column;
    }

    .pricing-subtext-inner-container .subtext-margin-right {
        margin-right: 0px;
    }
}

@media only screen and (max-width: 399px) {
    .pricing-yearly-savings-image {
        width: 130px;
        right: -70px;
    }
}