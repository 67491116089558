.compare-property-neighbourhood-outer-container {
    width: 100%;
    background-color: var(--beige-beige-02);
}

.compare-property-neighbourhood-inner-container {
    padding-top: 24px;
}

.compare-property-neighbourhood-inner-container-bottom-padding {
    padding-bottom: 24px;
}

.compare-property-neighbourhood-outer-container .MuiButtonBase-root {
    justify-content: flex-start;
    padding: 8px 16px;
    margin-left: 0px;
    min-height: 0px;
}

.compare-property-neighbourhood-outer-container .MuiAccordionSummary-content {
    margin: 0px;
}

.compare-property-neighbourhood-category {
    border-bottom: 0.5px solid var(--grayscale-gray-02);
    padding: 0px 16px;
}

.compare-property-neighbourhood-category:last-of-type {
    border-bottom: none;
}

.compare-property-neighbourhood-category-title-container {
    padding: 24px 0px;
}

.compare-property-neighbourhood-outer-container .MuiAccordionDetails-root {
    padding: 0px;
}

.compare-property-neighbourhood-row-container {

}

.compare-property-neighbourhood-subcategory-element {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.compare-property-neighbourhood-subcategory-label-container {
    padding: 8px 0px;
    flex: 1;
}

.compare-property-neighbourhood-right-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex: 2;
    padding: 8px 0px;
}

.compare-property-neighbourhood-schools-column-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 45%;
}

.compare-property-neighbourhood-right-element {
    width: 45%;
}

.compare-property-neighbourhood-row-container .schools-element-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.compare-property-neighbourhood-row-container .schools-element-right-container {
    padding-left: 0px;
    width: auto;
}

.compare-property-neighbourhood-row-container .schools-element-left-container {
    width: 100%;
}

.compare-property-neighbourhood-property-badge {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

@media only screen and (max-width: 999px) {
    .compare-property-neighbourhood-subcategory-element {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .compare-property-neighbourhood-right-container {
        flex: unset;
        width: 100%;
    }

    .compare-property-neighbourhood-row-container .schools-element-right-container {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: flex-start;
        margin-top: 8px;
    }

    .compare-property-neighbourhood-right-element,
    .compare-property-neighbourhood-schools-column-container {
        width: 49%;
    }

    .compare-property-neighbourhood-outer-container .schools-element-right-container .schools-score-text {
        margin-left: 0px;
        margin-bottom: 4px;
    }

    .compare-property-neighbourhood-outer-container .progress-bar-inner-container svg {
        width: 90%;
    }

    .progress-bar-inner-container {
        align-items: flex-start;
    }
}