.list-card-outer-container {
    width: 100%;
    max-width: 270px;
}

.list-card-inner-container  {
	display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-start;
	gap: 16px;
}

.list-card-image-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    padding: var(--corner-small);
    gap: var(--corner-regular);
    border-radius: var(--corner-medium);
    background-color: var(--grayscale-white);
    box-shadow: 0px 0px 8px 0px rgba(152, 151, 147, 0.40);
}

.list-card-image-element {
    background-repeat: no-repeat;
	object-fit: cover;
	width: 100%;
	height: 250px;
	position: relative;
	border-radius: 4px;
    object-position: center;
}

.list-card-text-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
}

.list-card-image-hover-dark-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 250px;
	background-color: rgba(0, 0, 0, 0);
	transition: background-color 300ms ease-in-out;
	border-radius: var(--corner-regular);
	z-index: 4;
}

.list-card-selected .list-card-image-hover-dark-bg {
    height: calc(250px - 6px);
    border-radius: 4px;
}
  
.list-card-image-hover-dark-bg.view-none {
	background-color: rgba(0, 0, 0, 0);
}
  
.list-card-image-hover-dark-bg:hover {
	background-color: rgba(0, 0, 0, 0.5);
}

.list-card-image-hover-dark-bg:hover .property-select-container {
    opacity: 1;
	visibility: visible;
}

.list-card-image-hover-light-bg {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: calc(250px - 6px);
	background-color: rgba(255, 255, 255, 0.50);
	transition: background-color 300ms ease-in-out;
	border-radius: 4px;
	z-index: 4;
}

.list-card-image-hover-light-bg .property-select-container {
	opacity: 1;
	visibility: visible;
}

.list-card-selected {
	border-radius: var(--corner-regular);
	border: 3px solid var(--purple-purple-02);
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}