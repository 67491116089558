.mobile-subscription-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 32px;
    width: calc(100% - 2px);
}

.mobile-subscription-card-outer-container {
    border-radius: 4px;
    border: 1px solid var(--blue-blue-01);
    background-color: var(--grayscale-white);
    width: 100%;
}

.mobile-subscription-card-inner-container {
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.mobile-subscription-card-title-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.mobile-subscription-card-body-container {
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 20px);
}

.mobile-subscription-card-top-row-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.mobile-subscription-card-bottom-row-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.mobile-subscription-card-upgrade-button {
    width: 100px;
}

@media only screen and (min-width: 1000px) {
    .mobile-subscription-grid {
        display: none;
    }
}