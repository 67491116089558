.property-not-found-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 80vh;
    gap: 32px;
    padding-top: 32px;
    max-width: 480px;
    margin: 0 auto;
}

.property-not-found-image {
    width: 200px;
}

.property-not-found-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.property-not-found-input-container {
    width: 100%;
}

.property-not-found-button-container {
    width: 100%;
    max-width: 80px;
}

.commercial-property-button-container {
    width: 100%;
    max-width: 110px;
}

.commercial-property-arrow {
    width: 16px;
    height: 16px;
    transform: rotate(90deg);
}

@media only screen and (max-width: 999px) {
    .property-not-found-inner-container {
        width: calc(100% - 32px);
        padding-top: 0px;
        gap: 16px;
    }

    .property-not-found-image {
        width: unset;
        height: 200px;
    }

    .commercial-property-button-container {
        max-width: 130px;
    }
}