.search-input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px 8px;
    border-radius: var(--corner-regular);
    border: 1px solid var(--grayscale-gray-01);
    background-color: var(--grayscale-white);
}

.search-input-wrapper:hover {
    border: 1px solid var(--blue-blue-02);
    transition: border 0.3s ease-in-out;
}
  
.search-input-wrapper:focus-within {
    border: 1px solid var(--blue-blue-03);
}

.search-input {
    flex: 1;
    border: none;
    outline: none;
    font-size: 14px;
    padding: 4px;
    width: calc(100% - 8px);
}

.search-magnifying-glass {
    width: 16px;
}

.search-exit-icon {
    width: 8px;
}

.search-input-icon {
    position: absolute;
    z-index: 1;
    right: 8px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
}