.creative-analysis-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    gap: 8px;
    width: 100%;
    margin-top: auto;
}

.creative-analysis-element-small {
    width: 32%;
}

.creative-analysis-element-large {
    width: 68%;
}

.creative-analysis-element {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.creative-analyis-element-body-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
}

.creative-analysis-element-body-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 2px;
}

.creative-analyis-element-list-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}