.strategies-panel-top-level-values-outer-container {
    width: 100%;
}

.strategies-panel-top-level-values-inner-container {
    width: 100%;
}

.strategies-panel-top-level-double-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
}

.strategies-panel-top-level-triple-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
}

.strategies-panel-top-level-double-grid .strategies-panel-top-level-value-element,
.strategies-panel-top-level-double-grid .strategies-panel-top-level-value-inner-element {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.strategies-panel-top-level-triple-grid .strategies-panel-top-level-value-element,
.strategies-panel-top-level-triple-grid .strategies-panel-top-level-value-inner-element {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.strategies-panel-top-level-value-element {
    width: 100%;
}

.strategies-panel-top-level-value-inner-element {
    padding: 16px;
}