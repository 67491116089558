.reset-button-outer-container {
    border-radius: 4px;
    background-color: var(--beige-beige-01);
    box-shadow: 0px 0px 8px 0px rgba(152, 151, 147, 0.40);
    padding: 10px 8px;
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 60px;
    margin-top: 16px;
    cursor: pointer;
}

@media only screen and (max-width: 999px) {
    .reset-button-outer-container {
        margin-left: 8px;
    }
}