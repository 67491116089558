.property-details-outer-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: calc(100% - 96px);
	margin: 0 auto;
}

.property-details-inner-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	gap: 24px;
}

.property-details-title-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 24px;
}

.property-details-title-icons-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 16px;
	flex-wrap: wrap;
}

.property-details-title-icon-element {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 4px;
}

.property-details-title-icon-container,
.property-details-title-text-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.property-details-description-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 4px;
}

.property-details-icons-grid {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	gap: 0px 16px;
}

.property-details-codes-container {
	word-break: break-word;
  	overflow-wrap: break-word;
}

.property-details-left-grid,
.property-details-right-grid {
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 24px;
}

.property-details-grid-element {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 8px;
}

.property-details-show-more-container {
	display: inline-block;
}

.property-details-legal-grid-element {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 4px;
}

@media only screen and (max-width: 999px) {
	.property-details-outer-container {
		max-width: none;
		width: 100%;
	}
}