.mobile-city-card-outer-container {
    border-radius: 8px;
    background-color: var(--grayscale-white);
    box-shadow: 0px 0px 8px 0px rgba(152, 151, 147, 0.40);
    width: 100%;
}

.mobile-city-card-inner-container {
    padding: 16px 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
}

.mobile-city-card-number-container {
    padding: 0px 8px;
    margin-right: 8px;
}

.mobile-city-card-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.mobile-city-card-city-name-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}