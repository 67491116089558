.mobile-bottom-nav-outer-container {
    border-top: 1px solid var(--grayscale-gray-01);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0px;
    left: 0px;
}

.mobile-bottom-nav-element-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 80px;
    cursor: pointer;
}

.mobile-bottom-nav-inner-container {
    padding: 8px 4px;
}

.mobile-bottom-svg-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}