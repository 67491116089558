.seller-finance-calculator-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.seller-finance-calculator-grid-container {
    /* display: grid; */
    /* grid-template-columns: repeat(2, 1fr); */
    /* grid-gap: 16px; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 16px;
    width: calc(100% - 2px);
    padding-bottom: 16px;
}

.seller-finance-grid-element {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(50% - 16px);
    gap: 16px;
}

.seller-finance-grid-element-small {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
}

.seller-finance-grid-element-third {
    width: calc((100% / 3) - 16px);
}

.seller-finance-input-container {
    width: 100%;
}