.checkbox-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
}

.checkbox-container {
    border-radius: var(--corner-small);
    border: 1px solid var(--blue-blue-01);
    background-color: var(--grayscale-white);
    box-shadow: 0px 0px 8px 0px rgba(152, 151, 147, 0.40);
    width: 16px;
    height: 16px;
    position: relative;
    cursor: pointer;
}

.checkbox-container:hover {
    border: 1px solid var(--blue-blue-02);
    transition: border 0.3s ease-in-out;
}

.checkbox-container.disabled:hover {
    border: 1px solid var(--blue-blue-01);
    transition: border 0.3s ease-in-out;
}

.checkbox-icon {
    background-color: var(--grayscale-black);
    width: 14px;
    height: 14px;
    border-radius: 2px;
    position: absolute;
    z-index: 1;
    left: 1px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: auto;
}