.financials-input-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.financials-input-label-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.financials-input-secondary-label-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
}

.financials-input-label-inside-container {
    justify-content: space-between;
    width: 100%;
}

.financials-input-icon-default {
    width: 16px;
}

.financials-input-icon-small {
    width: 8px;
}

.financials-input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2px 8px;
    border-radius: var(--corner-regular);
    border: 1px solid var(--grayscale-gray-01);
    background-color: var(--grayscale-white);
    width: calc(100% - 16px);
}

.financials-input-wrapper:hover {
    border: 1px solid var(--blue-blue-02);
    transition: border 0.3s ease-in-out;
}
  
.financials-input-wrapper:focus-within {
    border: 1px solid var(--blue-blue-03);
}

.financials-input-wrapper.disabled:hover {
    border: 1px solid var(--grayscale-gray-01);
}

.financials-input-outer-wrapper-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    width: 100%;
}

.financials-input-outer-wrapper-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
}

.financials-input-inner-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.financials-input {
    flex: 1;
    border: none;
    outline: none;
    font-size: 14px;
    padding: 4px;
    width: 100%;
}

.financials-input::-webkit-outer-spin-button,
.financials-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.financials-input[type="number"] {
  -moz-appearance: textfield;
}

.financials-input::placeholder {
    font-family: var(--font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0px;
    color: var(--grayscale-gray-02);
}

.financials-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: var(--font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0px;
    color: var(--grayscale-gray-02);
}
  
.financials-input::-ms-input-placeholder { /* Microsoft Edge */
    font-family: var(--font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0px;
    color: var(--grayscale-gray-02);
}
  
.financials-input::-webkit-input-placeholder { /* Safari */
    font-family: var(--font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0px;
    color: var(--grayscale-gray-02);
}

.financials-input-error .financials-input-wrapper {
    border: 1px solid var(--red-red-02);
}

.financials-input-reset-dot {
    position: absolute;
    z-index: 1;
    left: -10px;
    height: 8px;
    width: 8px;
}