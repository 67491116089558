.subto-calculator-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.subto-grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
    width: 100%;
}

.subto-owner-equity-element {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8px;
}

.subto-divider-line {
    border-radius: 100px;
    background-color: var(--grayscale-gray-00);
    height: 1px;
    margin: 8px 0px;
    width: 100%;
}

.subto-step-category-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.subto-step-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    width: calc(100% - 2px);
}

.subto-step-row-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    gap: 16px;
}

.subto-step-element {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.subto-step-element-small {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
}

.subto-red-bg .financials-input-wrapper {
    background-color: var(--red-red-01-transparent);
}

.subto-red-bg .financials-input-wrapper input {
    background-color: transparent;
}

.subto-accordion-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.subto-accordion-body-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 16px;
    padding-top: 16px;
}

.subto-accordion-panels-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    width: 100%;
}