@media only screen and (min-width: 1000px) {
    .pricing-card-mobile-outer-container {
        display: none;
    }
}

@media only screen and (max-width: 999px) {
    .pricing-card-mobile-outer-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        border-radius: 4px;
        background-color: var(--beige-beige-01);
        margin-bottom: 16px;
        max-width: 600px;
        position: relative;
        width: 100%;
    }

    .pricing-card-mobile-selected {
        border: 1px solid var(--blue-blue-02);
    }

    .bf-pricing-card-mobile-outer-container {
        margin-bottom: 24px;
    }

    .pricing-card-mobile-outer-container:last-of-type,
    .bf-pricing-card-mobile-outer-container:last-of-type {
        margin-bottom: 0px;
    }

    .pricing-card-mobile-inner-container {
        padding: 24px 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        width: calc(100% - 32px);
    }

    .pricing-card-mobile-left-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
    }

    .bf-pricing-card-mobile-outer-container .pricing-card-mobile-left-container {
        width: 75%;
    }

    .bf-pricing-card-mobile-outer-container .pricing-card-mobile-right-container {
        width: 30%;
        max-width: 100px;
    }

    .pricing-card-mobile-right-container {
        position: absolute;
        right: 16px;
        z-index: 1;
    }

    .pricing-card-mobile-radio-container {
        padding-right: 8px;
    }

    .pricing-card-mobile-body-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .pricing-mobile-card-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .pricing-card-mobile-body-inner-container {
        width: 100%;
    }

    .pricing-card-mobile-image {
        width: 66px;
        margin-bottom: 8px;
    }

    .pricing-card-mobile-chevron {
        width: 8px;
        margin-right: 4px;
    }

    .pricing-card-mobile-accordion .MuiAccordionSummary-content {
        margin: 0px;
    }

    .pricing-card-mobile-accordion .MuiButtonBase-root {
        min-height: 0px;
        width: 100%;
        margin-bottom: 8px;
    }

    .pricing-card-mobile-features-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
    }

    .pricing-card-mobile-features-list-element-icon {
        width: 8px;
    }

    .pricing-card-mobile-features-list-element-icon-container {
        margin-right: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-top: 8px;
    }

    .pricing-card-mobile-features-list-element {
        margin-bottom: 8px;
        list-style-type: none;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .pricing-card-mobile-features-list-element:last-of-type {
        margin-bottom: 0px;
    }
}