.strategy-loading-container {
    width: 100%;
}

.strategy-loading-text-container {
    width: 100%;
    position: absolute;
    z-index: 1;
    left: 0px;
    top: 0px;
    background-color: rgba(255, 255, 255, 0.60);
    backdrop-filter: blur(2px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    padding-top: 30%;
}

.strategy-loading-image {
    width: 28px;
    display: block;
    margin-bottom: 16px;
}