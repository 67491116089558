.map-settings-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
}

.map-settings-switch-element {
    width: 100%;
}

.map-settings-select-element {
    width: 100%;
}