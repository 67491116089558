.market-search-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
}

.market-search-modal-body-extra-padding {
    padding-bottom: 480px;
}

.market-search-modal-search-input {
    width: 100%;
}

@media only screen and (max-width: 999px) {
    .market-search-modal-body {
        padding-bottom: 160px;
    }

    .market-search-modal-body .predictive-search-container {
        top: 46px;
    }
}