body {
	font-family: var(--font-family);
	margin: 0px;
	width: 100%;
    display: block;
	position: absolute; 
	bottom: 0;
	z-index: 1000;
	height: 100%;
}

#root {
	height: 100%;
}

.property-title-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.property-title-container h1 {
	margin: 0;
	text-align: center;
}

.no-scroll {
	overflow: hidden;
}