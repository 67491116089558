.share-with-agent-row-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 8px;
}

.share-with-agent-input-container {
    width: calc(100% - 100px);
}

.share-with-agent-button-container {
    width: 100px;
}

@media only screen and (max-width: 999px) {
    .share-with-agent-input-container {
        width: calc(100% - 120px);
    }

    .share-with-agent-button-container {
        width: 120px;
    }
}