.free-trial-expired-outer-container {
    position: absolute;
    z-index: 20;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    top: 15%;
    border-radius: 3px;
    background-color: var(--beige-beige-01);
    box-shadow: 0px 0px 14px 0px rgba(49, 27, 2, 0.17);
    max-width: 500px;
    width: 90%;
}

.free-trial-expired-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 32px;
    padding-bottom: 60px;
    width: 90%;
    margin: 0 auto;
}

.free-trial-expired-image {
    max-width: 200px;
    margin-bottom: 48px;
}

.free-trial-expired-text-container {
    max-width: 347px;
}

.free-trial-expired-button-container {
    max-width: 173px;
    margin: 0 auto;
}

.free-trial-expired-blur {
    position: absolute;
    z-index: 15;
    top: 40px;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 249, 242, 0.75);
    backdrop-filter: blur(4px);
}

@media only screen and (max-width: 999px) {
    .free-trial-expired-inner-container {
        padding-bottom: 24px;
    }

    .free-trial-expired-image {
        margin-bottom: 16px;
    }   
    
    .free-trial-expired-inner-container .margin-x-x-large {
        margin-bottom: 16px;
    }
}