.str-rent-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    min-height: 300px;
}

.str-rent-step-element {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}

.str-rent-inner-step-element {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}

.str-rent-input-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
}

.str-rent-input-container .tooltip-outer-container {
    position: absolute;
    z-index: 1;
    top: 30px;
    left: 0px;
    width: 350px;
}

.str-rent-input-container .tooltip-beak-container {
    display: none;
}

.str-rent-monthly-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
}