.mobile-onboarding-outer-container {
	border-radius: 14px;
	background: var(--grayscale-white);
	box-shadow: 0px 0px 10px 0px rgba(116, 142, 160, 0.50);
	position: absolute;
	z-index: 13;
	margin: auto;
	height: 400px;
	width: 90%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;
	max-width: 550px;
}

.mobile-onboarding-outer-container-first-step {
	height: 200px;
}

.mobile-onboarding-inner-container {
	padding: 24px 16px;
}

.mobile-onboarding-step {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.mobile-onboarding-image {
	width: 100%;
	margin: 12px 0px;
	max-width: 300px;
}

.mobile-onboarding-step-title {
	font-family: var(--font-family);
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	color: var(--grayscale-black);
	margin-top: 0px;
	margin-bottom: 16px;
}

.mobile-onboarding-step-description {
	margin: 0px;
}

.mobile-onboarding-button-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-top: 24px;
}

.mobile-onboarding-button-element {
	width: 100px;
}

.mobile-onboarding-button-container-first-step {
	max-width: 100px;
	margin-left: auto;
	margin-right: 0px;
}

.mobile-onboarding-button-back-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
}

.mobile-onboarding-back-chevron {
	height: 8px;
	transform: rotate(180deg);
	margin-right: 8px;
}

.mobile-onboarding-button-back-text {
	font-family: var(--font-family);
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 18px;
	color: var(--blue-blue-02);
	text-align: center;
}

@media only screen and (min-width: 1000px) {
	.mobile-onboarding-outer-container {
		display: none !important;
	}
}