.compare-property-financials-outer-container {
    border-top: 0.5px solid var(--grayscale-gray-02);
    width: 100%;
}

.compare-property-financials-inner-container {
    padding-top: 24px;
}

.compare-property-financials-inner-container-bottom-padding {
    padding-bottom: 24px;
}

.compare-property-financials-inner-container .MuiButtonBase-root {
    justify-content: flex-start;
    padding: 8px 16px;
    margin-left: 0px;
    min-height: 0px;
}

.compare-property-financials-inner-container .MuiAccordionDetails-root {
    padding: 0px;
}

.compare-property-financials-inner-container .MuiAccordionSummary-content {
    margin: 0px;
}

.compare-property-financials-body-container {
    
}

.compare-property-financials-category {
    border-bottom: 0.5px solid var(--grayscale-gray-02);
    padding: 0px 16px;
}

.compare-property-financials-category-title-container {
    padding: 24px 0px;
}

.compare-property-financials-subcategory-element {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.compare-property-financials-subcategory-element:last-of-type {
    margin-bottom: 16px;
}

.compare-property-financials-subcategory-label-container {
    padding: 8px 0px;
    flex: 1;
}

.compare-property-financials-right-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 2;
    padding: 8px 0px;
}

.compare-property-financials-right-element {
    width: 45%;
}

.compare-property-financials-abstract-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.compare-property-financials-abstract-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.compare-property-financials-abstract-column .compare-property-financials-abstract-element {
    margin-bottom: 8px;
}

.compare-property-financials-abstract-column .compare-property-financials-abstract-element:last-of-type {
    margin-bottom: 0px;
}

.compare-property-financials-abstract-element {
    margin-right: 24px;
}

.compare-property-financials-abstract-element:last-of-type {
    margin-right: 0px;
}

@media only screen and (min-width: 1000px) and (max-width: 1399px) {
    .compare-property-financials-abstract-row .heading-large-semibold {
        font-size: 18px;
        line-height: 22px;
    }
}

@media only screen and (max-width: 999px) {
    .compare-property-financials-abstract-row {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .compare-property-financials-abstract-row .heading-large-semibold {
        font-size: 18px;
        line-height: 22px;
    }

    .compare-property-financials-abstract-element {
        margin-right: 0px;
        margin-top: 16px;
    }

    .compare-property-financials-abstract-element:first-of-type {
        margin-top: 0px;
    }

    .compare-property-financials-subcategory-element {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .compare-property-financials-subcategory-label-container {
        flex: unset;
    }

    .compare-property-financials-right-container {
        width: 100%;
        flex: unset;
    }
}