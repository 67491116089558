.view-best-market-inner-container {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 64px;
    gap: 32px;
    margin: 0 auto;
    padding-bottom: 64px;
}

.view-best-market-content-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 48px;
    width: 100%;
}

.view-best-market-inner-body-container {
   display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 32px;
    width: 100%; 
}

.view-best-market-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 100%;
    max-width: 400px;
}

.view-best-market-body-container {
    width: 100%;
    max-width: 178px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.view-best-market-card-container {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: var(--corner-regular);
    border: 1px solid var(--blue-blue-03);
}

.view-best-market-icon {
    font-size: 32px;
}

.view-best-market-bottom-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 100%;
}

.view-best-market-arrow {
    width: 16px;
    height: 16px;
    transform: rotate(-90deg);
}

.view-best-market-button-container {
    width: 100%;
    max-width: 140px;
}

@media only screen and (max-width: 999px) {
    .view-best-market-inner-container {
        width: calc(100% - 32px);
        padding-top: 16px;
        padding-bottom: 120px;
    }

    .view-best-market-body-container {
        max-width: 240px;
    }

    .view-best-market-button-container {
        max-width: 170px;
    }
}