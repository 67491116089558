.summary-section-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 8px;
}

.summary-section-body-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 8px;
}

.summary-section-element-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.summary-section-element-left-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.summary-section-element-right-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}

.summary-section-roi-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    width: 100%;
}

.summary-section-roi-element-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.summary-section-roi-label-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}

.summary-section-animate-bg,
.summary-section-animate-bg-out {
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.summary-section-animate-bg::before,
.summary-section-animate-bg-out::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: var(--yellow-yellow-03);
    z-index: -1;
    transition: width 1.5s ease-out;
}


.summary-section-animate-bg::before {
    width: 100%;
}

.summary-section-animate-bg-out::before {
    width: 0%;
}

.summary-section-roi-container {
    /* position: sticky;
    z-index: 3;
    bottom: 0px;
    background-color: var(--grayscale-white); */
}