.off-market-badge-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 6px;
    border-radius: 3px;
    background: rgba(255, 254, 252, 0.90);
    box-shadow: 0px 0px 10px 0px rgba(78, 42, 0, 0.10);
    margin-top: 4px;
    margin-right: 4px;
}

.off-market-badge-emoji {
    font-size: 14px;
    margin-right: 4px;
    display: block;
    line-height: 28px;
}

@media only screen and (max-width: 1599px) {
    .off-market-badge-container {
        margin-top: 8px;
        margin-right: 8px;
    }
}