.purchase-tab-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.purchase-tab-inner-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.purchase-tab-price-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.purchase-tab-price-container {
    max-width: 220px;
    width: 100%;
}

.purchase-input-info-icon {
    position: absolute;
    z-index: 1;
    right: 8px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.purchase-tab-slider-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 220px;
}

.purchase-tab-initial-costs-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    width: calc(100% - 2px);
}

.purchase-tab-down-payment-bottom-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.purchase-tab-financials-input,
.purchase-tab-financials-other-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
}

.purchase-tab-financials-input .financials-input-reset-element {
    position: absolute;
    right: 0px;
    bottom: 0px;
}

.purchase-tab-financials-input:last-of-type {
    margin-right: 0px;
}

.purchase-tab-refinance-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.purchase-tab-values-accordion {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.purchase-tab-values-accordion .MuiAccordionDetails-root {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}