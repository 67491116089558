.choicebox-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    aspect-ratio: 1 / 1;
    border-radius: var(--corner-regular);
}

.choicebox-inner-container {
    padding: 16px var(--corner-regular);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100% - 2 * var(--corner-regular));
    gap: var(--corner-regular);
}

.choicebox-unselected-container {
    border: 1px solid var(--blue-blue-01);
    background-color: var(--grayscale-white);
}

.choicebox-selected-container {
    border: 2px solid var(--blue-blue-03);
    background-color: var(--grayscale-white);
}