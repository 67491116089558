.verify-email-inner-container {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 32px);
    margin: 0 auto;
    padding-top: 32px;
    gap: 32px;
}

.verify-email-image {
    width: 100%;
    max-width: 495px;
}

.verify-email-top-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
    max-width: 400px;
}

.verify-email-button-container {
    width: 100%;
    max-width: 66px;
}

.verify-email-resend-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: 100%;
}

@media only screen and (max-width: 999px) {
    .verify-email-inner-container {
        padding-bottom: 120px;
    }
}