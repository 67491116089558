.one-city-loading-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 8px;
    background-color: var(--beige-beige-01);
    backdrop-filter: blur(8px);
}

.one-city-loading-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 410px;
    padding: 56px 0px;
}

.one-city-loading-animation-container {
    max-width: 118px;
}