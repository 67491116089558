.rehab-calculator-inputs-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.rehab-calculator-input-element {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.rehab-calculator-analysis-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

@media only screen and (max-width: 999px) {
    .rehab-calculator-outer-container {
        padding-top: 24px;
    }

    .rehab-calculator-inputs-row {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .rehab-calculator-input-element {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .rehab-calculator-input-element .subtext-margin-left {
        margin-top: 4px;
        margin-left: 0px;
    }

    .rehab-calculator-input-element .subtext-margin-right {
        margin-right: 0px;
        margin-bottom: 4px;
    }
}