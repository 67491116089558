.property-gallery-outer-container {
    width: 100%;
    gap: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.property-gallery-top-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.property-gallery-property-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;
}

.property-gallery-property-row .custom-swiper-outer-container {
    margin-right: 0px;
    margin-left: 0px;
    width: auto;
}

.property-gallery-property-row .new-property-card-images-swiper-container  .property-card-badge-outer-container,
.property-gallery-property-row .new-property-card-images-swiper-container  .price-cut-outer-container {
    height: unset;
}

.property-gallery-button-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.property-gallery-arrow {
    width: 16px;
    height: 16px;
    transform: rotate(270deg);
}

@media only screen and (max-width: 999px) {
    .property-gallery-top-container .text-button {
        display: none;
    }

    .property-gallery-property-row {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 499px) {
    .property-gallery-property-row {
        grid-template-columns: repeat(1, 1fr);
    }
}