.icon-button-element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--corner-regular);
    border-radius: 32px;
}

.icon-button-element-large {
    padding: var(--corner-medium);
}

.icon-button-element-medium {
    padding: var(--corner-regular);
}

.icon-button-element-small {
    padding: var(--corner-regular);
}

.icon-button-large {
    width: 24px;
}

.icon-button-medium {
    width: 16px;
}

.icon-button-small {
    width: 8px;
}

.icon-button-element:hover {
    background-color: var(--blue-blue-0130);
    transition: background-color 0.3s ease-in-out;
}

.icon-button-element .tooltip-context-outer-container {
    position: absolute;
    z-index: 1;
    bottom: -30px;
    white-space: nowrap;
}