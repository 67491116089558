.financials-calculator-footer-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(100% - 48px);
    padding-bottom: 16px;
    gap: 16px;
    margin: 0 auto;
}

.financials-calculator-footer-body-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.financials-calculator-footer-body-left-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
}

.financials-calculator-coc-element {
    gap: 4px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.financials-calculator-footer-body-right-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
}

.financials-calculator-footer-button-container {
    width: 100%;
}

.infinite-cocr-container svg {
    position: absolute;
    z-index: 1;
    top: 0px;
    right: -20px;
}