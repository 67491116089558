.side-panel-trigger-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: fixed;
    margin-top: 16px;
    right: 16px;
    z-index: 1;
    padding: 0px;
    border-radius: 4px;
}

.side-panel-trigger-border-container::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    outline: 2px solid var(--blue-blue-02);
    border-radius: 4px;
    animation: pulse-animation 1s linear 1;
}

.side-panel-trigger-border-container-infinite::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    outline: 2px solid var(--blue-blue-02);
    border-radius: 4px;
    animation: pulse-animation 1s linear infinite;
}

@keyframes pulse-animation {
    0% {
        outline-offset: 2px;
        opacity: 1;
    }
    100% {
        outline-offset: 10px;
        opacity: 0;
    }
}

.side-panel-trigger-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
    border-radius: 4px;
    background-color: var(--beige-beige-01);
    box-shadow: 0px 0px 8px 0px rgba(152, 151, 147, 0.40);
}

.side-panel-trigger-icon {
  width: 20px;  
}