.recommendations-loading-inner-container {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    padding-top: 48px;
}

.recommendations-loading-image-container{
    max-width: 200px;
    width: 100%;
}

.recommendations-loading-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
    max-width: 480px;
}

@media only screen and (max-width: 999px) {
    .recommendations-loading-inner-container {
        width: calc(100% - 32px);
        margin: 0 auto;
        padding-top: 24px;
    }
}