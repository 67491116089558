.settings-section-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    max-width: 572px;
}

.settings-section-title-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
}

.settings-spacing-container {
    border-radius: 100px;
    background-color: var(--grayscale-gray-00);
    height: 1px;
    width: 100%;
    margin: 8px 0px;
}

.settings-section-body-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 24px;
}

.settings-market-input-container {
    width: 100%;
}

@media only screen and (max-width: 999px) {
    .settings-section-container {
        margin: 0 auto;
    }

    .settings-market-button-container {
        max-width: 200px;
    }
}