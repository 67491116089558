.page-banner-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    width: 100%;
    max-width: 1118px;
}

.page-banner-inner-container {
    padding: 24px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 487px;
}

.page-banner-image {
    display: block;
    width: 66px;
}

@media only screen and (max-width: 999px) {
    .page-banner-inner-container {
        padding: 24px;
    }
}