.choose-counties-inner-container {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 32px;
    width: calc(100% - 32px);
    margin-left: auto;
    margin-right: auto;
}

.choose-counties-inner-container .loading-container {
    top: 20%;
    position: fixed;
}

.choose-counties-body-container {
    max-width: 438px;
    width: 100%;
}

.choose-counties-element-container {
    border-radius: 80px;
    border: 1px dashed var(--blue-blue-02);
    width: 100%;
}

.choose-counties-selected-element-container {
    border-radius: 80px;
    border: 1px solid var(--blue-blue-02);
    background-color: var(--grayscale-white);
}

.choose-counties-inner-element-container {
    padding: 24px 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 64px);
}

.choose-counties-button-container {
    width: 100%;
}

.choose-counties-button-container {
    max-width: 244px;
}

.choose-counties-short-button {
    max-width: 170px;
}

.choose-counties-next-step-container {
    max-width: 437px;
    width: 100%;
}

.choose-counties-pick-county-container {
    padding-top: 32px;
}

.choose-counties-pick-county-container .upgrade-city-body-container {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
}

.choose-counties-pick-county-container .pick-plan-map-container {
    width: 100%;
}

@media only screen and (max-width: 1299px) {
    .choose-counties-pick-county-container .pick-plan-checkout-button-container {
        display: flex;
        max-width: 460px;
        margin-left: auto;
        margin-right: auto;
    }

    .choose-counties-pick-county-container .pick-plan-checkout-button-element {
        max-width: 460px;
    }
}

@media only screen and (max-width: 999px) {
    .choose-counties-pick-county-container {
        width: calc(100% - 32px);
        margin: 0 auto;
    }

    .choose-counties-pick-county-container .upgrade-city-zips-container,
    .choose-counties-pick-county-container .upgrade-city-response-container {
        display: none;
    }
}