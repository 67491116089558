.subject-property-bar-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.subject-property-bar-inner-container {
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.subject-property-bar-element {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-right: 16px;
}

.subject-property-bar-element:last-of-type {
    margin-right: 0px;
}