.best-value-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    position: absolute;
    z-index: 1;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    top: -14px;
    border-radius: 100px;
    width: 140px;
    background-color: var(--grayscale-black);
}

@media only screen and (max-width: 999px) {
    .best-value-container {
        width: 170px;
        top: -18px;
    }
}