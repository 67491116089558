.rehab-tab-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.rehab-tab-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    padding-bottom: 100px;
}

.rehab-tab-inputs-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 16px;
    width: 100%;
}

.rehab-tab-input-element {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.rehab-tab-sq-foot-input {
    width: 128px;
}

.rehab-tab-total-input {
    width: calc(100% - 215px);
}

.rehab-tab-analysis-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.rehab-tab-slider-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.rehab-tab-slider-container .MuiStack-root {
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
}

.rehab-tab-arv-title-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.rehab-tab-arv-input-extras {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 4px;
}

.rehab-tab-input-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.rehab-tab-arv-input-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.rehab-tab-arv-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    max-width: 220px;
}

.strategies-panel-financials-input {
    width: 100%;
}

.rehab-tab-arv-input-container .tooltip-outer-container {
    position: absolute;
    z-index: 1;
    top: 40px;
    right: -120px;
}

@media only screen and (max-width: 999px) {
    .rehab-tab-outer-container {
        padding-top: 24px;
    }

    .rehab-tab-inputs-row {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .rehab-tab-input-element {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
}