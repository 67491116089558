.selection-chip-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px var(--corner-medium);
    gap: var(--corner-small);
    flex-shrink: 0;
    border-radius: var(--corner-regular);
}

.selection-chip-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.selection-chip-unselected {
    border: 1px solid var(--grayscale-gray-01);
    background-color: var(--grayscale-white);
}

.selection-chip-unselected:hover {
    background-color: var(--blue-blue-0130);
    transition: background-color 0.3s ease-in-out;
}

.selection-chip-unselected.disabled:hover {
    background-color: var(--grayscale-white);
}

.selection-chip-selected {
    border: 2px solid var(--blue-blue-03);
    background-color: var(--grayscale-white);
}

.selection-chip-left-icon {
    width: 16px;
}

.selection-chip-right-icon {
    width: 16px;
}