.comparables-outer-container {
    width: 100%;
}

.rental-comps-outer-container {
    display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: calc(100% - 96px);
	margin: 0 auto;
    margin-top: -24px;
}

.rental-comps-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
}

.comparables-chips-outer-row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.comparables-run-comps-button-container {
    max-width: 148px;
    width: 100%;
}

.comparables-arrow-icon {
    width: 16px;
    height: 16px;
}

.rental-comps-chips-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
}

.rental-comps-table-container {
    width: 100%;
    display: table;
    border-collapse: collapse;
    position: relative;
}

.rental-comps-table-head {
    width: 100%;
    display: table-header-group;
}

.rental-comps-inner-container table,
.rental-comps-inner-container th {
    border-collapse: collapse;
}

.rental-comps-subject-property-row th {
   padding: 16px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
}

.rental-comps-border {
    box-shadow: 0px -1px 0px 0px var(--beige-beige-04) inset;
}

.rental-comps-label-row {
    width: 100%;
    display: table-row;
    box-shadow: 0px 1px 8px 0px rgba(41, 38, 33, 0.10) inset;
}

.rental-comps-subject-property-row {
    width: 100%;
    display: table-row;
}

.edit-listings-inner-body-container .rental-comps-subject-property-row {
    display: none;
}

.rental-comps-table-label {
    padding: 16px;
    background-color: var(--grayscale-white);
    border-bottom: 1px solid var(--grayscale-gray-00);
}

.rental-comps-icon-container {
    display: inline-block;
    vertical-align: middle;
    margin-left: 2px;
    position: absolute;
    z-index: 1;
}

.rental-comps-table-value {
    padding: 16px;
}

.rental-comps-progress-bar-outer-container {
    max-width: 66px;
}

.rental-comps-progress-bar-outer-container svg {
    max-width: 66px;
}

.rental-comps-outer-body-container {
    width: 100%;
}

.rental-comps-body-container {
    width: 100%;
    overflow-y: scroll;
    max-height: 450px;
}

.new-property-blurred .rental-comps-blur-subject {
    filter: blur(2px);
}

.rental-comps-unit-chips-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.rental-comps-outer-container thead th,
.rental-comps-outer-container thead tr:first-child th { 
    position: sticky;
    top: 0px;
    display: table-cell;
    z-index: 2;
}

.rental-comps-outer-container .rental-comps-subject-property-row th { 
    position: sticky;
    top: 52px;
    display: table-cell;
    z-index: 1;
}

.property-mobile-bar-active .rental-comps-outer-container thead tr:first-child th,
.rental-comps-outer-container .rental-comps-subject-property-row th {
    z-index: 0;
}

.rental-comps-link-container:hover span {
    text-decoration: underline;
    cursor: pointer;
}

.rental-comps-table-value-address {
    width: 141px;
}

.rental-comps-multi-format-address-container {
    width: 150px;
}

.rental-comps-distance-container {
    width: 50px;
}

.rental-comps-similarity-container {
    width: 70px;
}

.rental-comps-multi-format-correlation-container {
    width: 100px;
}

.rental-comps-multi-format-small-container {
    width: 50px;
}

.rental-comps-address-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.rental-comps-table-checkbox-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 48px;
    padding: 16px 0px !important;
}

.rental-comps-table-checkbox-container .checkbox-container {
    margin-right: unset;
    margin: 0 auto;
    z-index: 0;
}

.rental-comps-table-row:hover {
    background-color: var(--beige-beige-02);
}

.rental-comps-table-row-selected {
    border-top: 1px solid var(--blue-blue-02);
    border-bottom: 1px solid var(--blue-blue-02);
    background-color: var(--blue-blue-01) !important;
}

.comps-table-bulk-action-container {
    border-radius: 8px;
    background-color: var(--grayscale-white);
    box-shadow: 0px 2px 12px 0px rgba(207, 188, 173, 0.50);
    width: 100%;
    max-width: 352px;
    margin-left: 8px;
    margin-top: 6px;
}

.comps-table-bulk-action-inner-container {
    padding: 8px 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.comps-table-bulk-action-right-container {
    width: 190px;
}

.show-hide-excluded-comps {
    width: 166px;
}

.rental-comps-excluded-element span {
    color: var(--grayscale-gray-03);
}

.excluded-comps-table-row span {
    text-decoration: line-through !important;
}

.comparables-sources-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    max-width: 504px;
}

@media only screen and (max-width: 999px) {
    .rental-comps-outer-container {
        width: calc(100% - 32px);
    }

    .rental-comps-body-container {
        overflow-x: scroll;
    }

    .rental-comps-outer-body-container .rental-comps-multi-format-address-container {
        min-width: 200px;
    }

    .rental-comps-outer-body-container .rental-comps-multiple-layouts-container .rental-comps-multi-format-correlation-container {
        min-width: 125px;
    }
}