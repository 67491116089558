.contact-agent-outer-container {
	width: 100%;
}

.contact-agent-inner-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 4px;
}

.contact-agent-body-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 4px;
}