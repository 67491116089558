.owner-info-outer-container {
    display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: calc(100% - 96px);
	margin: 0 auto;
}

.owner-info-inner-container {
    display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
    gap: 24px;
    width: 100%;
}

.owner-info-title-container {
    display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
    gap: 4px;
}

.owner-info-details-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}

.owner-info-details-element {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}

.owner-info-details-icon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.owner-info-personal-info-container {
    display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
    gap: 4px;
    width: 100%;
}

.owner-info-seller-motivation-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
}

.owner-info-badge-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    border-radius: 4px;
}

.owner-details-equity-element {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
}

.owner-info-distress-flags-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
}

.owner-info-distress-flags-title-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
}

.owner-info-distrss-flags-heading-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}

.owner-info-distress-flags-grid {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
}

.owner-info-sources-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
}

.owner-info-sources-element {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}

.owner-info-sources-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}

.owner-details-badge-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    border-radius: 4px;
}

@media only screen and (max-width: 999px) {
    .owner-info-outer-container {
        width: 100%;
    }

    .owner-info-sources-element {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}