.vertical-tab-outer-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 2 * var(--corner-regular));
    padding: var(--corner-regular);
}

.vertical-tab-inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    padding: var(--corner-regular);
    border-radius: var(--corner-regular);
}

.vertical-tab-badge-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}

.vertical-tab-unselected-container:hover {
    background-color: var(--blue-blue-0130);
    transition: background-color 0.3s ease-in-out;
}

.vertical-tab-unselected-container.disabled:hover {
    background-color: unset;
}

.vertical-tab-selected-container {
    background-color: var(--blue-blue-01);
}

.vertical-tab-icon {
    width: 16px;
}

.vertical-tab-external-link {
    width: 8px;
}

.vertical-tab-left-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
}

.vertical-tab-account-pic {
    width: 28px;
    height: 28px;
    border-radius: 14px;
    margin-right: 12px;
    display: block;
}