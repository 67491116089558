.toast-message-outer-container {
    border-radius: 8px;
    background-color: var(--grayscale-black);
    position: fixed;
    bottom: 5%;
    right: 16px;
    z-index: 10000;
}

.toast-message-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
}

.toast-message-close-container {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.toast-message-close-icon {
    width: 20px;
}

@media only screen and (min-width: 1000px) {
    .toast-message-slide-in {
        animation: 1s ease-in-out normal toast-slide-in;
    }
    
    .toast-message-slide-out {
        animation: 1s ease-in-out normal toast-slide-out;
        right: -50%;
    }
    
    @keyframes toast-slide-in {
        from {
          right: -50%;
        }
      
        to {
          right: 16px;
        }
    }
    
    @keyframes toast-slide-out {
        from {
          right: 16px;
        }
      
        to {
          right: -50%;
        }
    }
    
    .toast-message-inactive {
        right: -50%;
    }
}

@media only screen and (max-width: 999px) {
    .toast-message-outer-container {
        bottom: 20%;
        right: 4px;
    }

    .toast-message-slide-in {
        animation: 1s ease-in-out normal toast-slide-in;
    }
    
    .toast-message-slide-out {
        animation: 1s ease-in-out normal toast-slide-out;
        right: -200%;
    }
    
    @keyframes toast-slide-in {
        from {
          right: -200%;
        }
      
        to {
          right: 16px;
        }
    }
    
    @keyframes toast-slide-out {
        from {
          right: 16px;
        }
      
        to {
          right: -200%;
        }
    }
    
    .toast-message-inactive {
        right: -200%;
    }
}

@media only screen and (max-width: 399px) {
    .toast-message-outer-container {
        max-width: 280px;
    }
}