.discount-code-outer-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0px;
}

.discount-code-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.discount-code-input-container {
    margin-right: 16px;
    max-width: 362px;
    width: 100%;
}

.discount-code-button-container {
    width: 64px;
}

.discount-code-chip-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    padding: 8px 12px;
}

@media only screen and (max-width: 999px) {
    .discount-code-outer-container {
        margin-top: 0px;
        margin-bottom: 32px;
    }
}