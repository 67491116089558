.default-tooltip-container {
	position: absolute;
	z-index: 2;
	background-color: var(--grayscale-white);
	box-shadow: 0px 0px 10px 0px #99959180;
	padding: 24px 16px;
	width: 308px;
	right: -200px;
	top: 40px;
    border-radius: 14px;
}

.default-tooltip-container .chip-change-icon {
    margin-right: 4px;
}

.default-tooltip-beak {
    left: 41%;
    top: -15px;
    position: absolute;
    z-index: 2;
    width: 20px;
}

.default-tooltip-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 8px;
}

.default-tooltip-row-inner-column {
    margin-left: 8px;
}

.default-tooltip-container span {
    white-space: pre-wrap;
}

/* .side-panel-outer-container .default-tooltip-container {
    width: 200px;
    right: -138px;
} */

.default-tooltip-link-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

@media only screen and (max-width: 999px) {
    .neighbourhood-scores-grid-container .default-tooltip-container {
        left: 0px;
    }

    .neighbourhood-scores-grid-container .default-tooltip-beak {
        display: none;
    }
}