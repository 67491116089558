.modal-outer-container {
    border-radius: var(--corner-regular);
    background-color: var(--grayscale-white);
    box-shadow: 0px 8px 24px 8px rgba(41, 38, 33, 0.15);
    max-width: 596px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;
    z-index: 11;
}

.modal-exit-outer-container {
    width: 100%;
}

.modal-exit-container {
    position: absolute;
    z-index: 2;
    right: 16px;
    top: 16px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-exit-icon {
    width: 16px;
    height: 16px;
}

.modal-heading-container {
    padding: 0px 32px;
    padding-top: 24px;
    width: calc(100% - 64px);
    gap: 4px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.modal-body-container {
    padding: 32px;
    width: calc(100% - 64px);
}

.modal-buttons-container {
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 32px);
}

.modal-buttons-single-cta {
    width: 100%;
}

.modal-buttons-secondary-cta {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}

.modal-outer-container .loading-container {
    max-width: 100px;
}

.modal-cancel-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.modal-cancel-arrow {
    width: 16px;
    height: 16px;
    transform: rotate(90deg);
}