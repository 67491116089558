.horizontal-tab-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 16px var(--corner-regular) 16px;
    gap: var(--corner-regular);
    align-self: stretch;
    border-radius: var(--Corner-Regular, 8px) var(--Corner-Regular, 8px) 0px 0px;
}

.horizontal-tab-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.horizontal-tab-icon {
    width: 16px;
    display: block;
    margin-right: 8px;
}

.horizontal-tab-unselected-container {
    border-bottom: 1px solid var(--grayscale-gray-01);
}

.horizontal-tab-unselected-container:hover {
    background-color: var(--blue-blue-0130);
    transition: background-color 0.3s ease-in-out;
}

.horizontal-tab-unselected-container.disabled:hover {
    background-color: unset;
}

.horizontal-tab-selected-container {
    border-bottom: 2px solid var(--grayscale-black);
}

.horizontal-tab-selected-container .horizontal-tab-icon {
    filter: brightness(0) saturate(100%);
}