.penny-thoughts-inner-container {
	margin: 0 auto;
	margin-top: 32px;
	max-width: 400px;
	min-height: 80vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 32px;
}

.penny-thoughts-title-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	gap: 4px;
}

.penny-thoughts-body-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 16px;
}

.penny-thoughts-button-container {
	max-width: 200px;
	width: 100%;
}

@media only screen and (max-width: 999px) {
	.penny-thoughts-inner-container {
		width: calc(100% - 32px);
		margin-top: 16px;
	}
	
	.penny-thoughts-body-container {
		width: 100%;
	}

	.penny-thoughts-button-container {
		max-width: 400px;
	}
}