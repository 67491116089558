.login-component-outer-container {
	width: 100%;
}

.login-component-inner-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 40px;
	width: 100%;
}

.login-component-inner-container .loading-container {
	top: 20%;
}

.login-component-body-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	gap: 24px;
}

.login-component-element-container {
	max-width: 432px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 8px;
	width: 100%;
}

.login-component-sign-up-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
}

.login-component-button-container {
	max-width: 432px;
	margin: 0 auto;
	width: 100%;
}

.login-component-bottom-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	gap: 24px;
}

@media only screen and (max-width: 999px) {
	
}