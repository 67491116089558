.dropdown-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 2px;
    flex-shrink: 0;
    border-radius: var(--corner-regular);
    background-color: var(--grayscale-white);
}

.dropdown-closed-container {
    border: 1px solid var(--blue-blue-01);
}

.dropdown-closed-container:hover {
    border: 1px solid var(--blue-blue-02);
    transition: border 0.3s ease-in-out;
}

.dropdown-closed-container.disabled:hover {
    border: 1px solid var(--blue-blue-01);
}

.dropdown-open-container {
    border: 2px solid var(--blue-blue-03);
}

.dropdown-inner-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
}

.dropdown-left-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}

.dropdown-icon {
    width: 16px;
}

.dropdown-arrow-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dropdown-arrow {
    width: 8px;
}

.dropdown-options-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    border-radius: var(--corner-regular);
    box-shadow: 0px 0px 8px 0px rgba(153, 149, 145, 0.50);
    position: absolute;
    z-index: 100;
    max-height: 200px;
    overflow-y: scroll;
    background-color: var(--grayscale-white);
}

.dropdown-exit-screen-container {
    position: absolute;
    z-index: 99;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.dropdown-options-align-bottom {
    top: 38px;
}

.dropdown-options-align-top {
    top: -216px;
}

.dropdown-options-align-left {
    left: 0;
}

.dropdown-options-align-right {
    right: 0;
}

.dropdown-option-element-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
}

.dropdown-option-label {
    background-color: var(--grayscale-white);
    padding: 8px 16px;
    width: calc(100% - 32px);
    cursor: default;
}

.dropdown-option-unselected {
    background-color: var(--grayscale-white);
    padding: 8px 16px;
    width: calc(100% - 32px);
}

.dropdown-option-unselected:hover {
    background-color: var(--blue-blue-0130);
    transition: background-color 0.3s ease-in-out;
}

.dropdown-option-unselected.disabled:hover {
    background-color: var(--grayscale-white);
}

.dropdown-option-selected {
    background-color: var(--blue-blue-02);
    padding: 8px 16px;
    width: calc(100% - 32px);
}

.dropdown-option-unselected-multi-select {
    background-color: var(--grayscale-white);
    padding: 8px 40px 8px 8px;
    width: calc(100% - 48px);
}

.dropdown-option-unselected-multi-select:hover {
    background-color: var(--blue-blue-0130);
    transition: background-color 0.3s ease-in-out;
}

.dropdown-option-unselected-multi-select.disabled:hover {
    background-color: var(--grayscale-white);
}

.dropdown-option-selected-multi-select {
    background-color: var(--grayscale-white);
    padding: 8px 40px 8px 8px;
    width: calc(100% - 48px);
}

.dropdown-outer-container .tooltip-context-outer-container {
    position: fixed;
    z-index: 1;
    width: 168px;
    /* top: var(--context-tooltip-top); */
    top: 280px;
    left: var(--context-tooltip-left);
}