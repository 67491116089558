.full-screen-button-outer-container {
    background-color: var(--grayscale-white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    cursor: pointer;
    position: fixed;
    z-index: 2;
    margin-left: 16px;
    margin-top: 16px;
    border-radius: var(--corner-regular);
    box-shadow: 0px 0px 8px 0px rgba(152, 151, 147, 0.40);
}

.full-screen-button-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
}

.expand-icon {
    height: 18px;
    width: 20px;
}

@media only screen and (max-width: 1399px) {
    .full-screen-button-outer-container {
        display: none;
    }
}