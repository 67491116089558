.tooltip-outer-container {
    display: flex;
    padding: var(--corner-large) 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--corner-regular);
    border-radius: 14px;
    background-color: var(--grayscale-white);
    box-shadow: 0px 8px 24px 8px rgba(41, 38, 33, 0.15);
}

.tooltip-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.tooltip-exit-container {
    position: absolute;
    z-index: 2;
    right: -8px;
    top: -16px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tooltip-exit-icon {
    width: 16px;
    height: 16px;
}

.tooltip-text-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
}

.tooltip-beak-container {
    width: 100%;
}

.tooltip-beak {
    position: absolute;
    z-index: 1;
}

.tooltip-beak-left {
    left: -30px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    transform: rotate(90deg);
}

.tooltip-beak-right {
    right: -30px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    transform: rotate(270deg);
}

.tooltip-beak-top {
    top: -38px;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    transform: rotate(180deg);
}

.tooltip-beak-bottom {
    bottom: -38px;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
}

.tooltip-context-outer-container {
    display: flex;
    padding: var(--corner-small) var(--corner-regular);
    justify-content: center;
    align-items: center;
    gap: var(--corner-regular);
    border-radius: var(--corner-regular);
    box-shadow: 0px 8px 24px 8px rgba(41, 38, 33, 0.15);
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}

.tooltip-context-outer-container.visible {
    opacity: 1;
}

.tooltip-context-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tooltip-context-dark-bg {
    background-color: var(--grayscale-black);
}

.tooltip-context-light-bg {
    background-color: var(--grayscale-white);
}