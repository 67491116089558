.new-feature-outer-container {
    border-radius: 4px;
    background-color: var(--indigo-indigo-00);
    position: absolute;
    z-index: 1;
    top: -15px;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    max-width: 86px;
    padding: 2px 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}