.new-property-list-inner-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
}

.new-property-list-left-container {
	width: 40%;
	height: calc(100vh - 111px);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	overflow-y: scroll;
	max-width: 900px;
}

.new-property-list-strategies-outer-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: calc(100% - 32px);
	padding: 16px 0px;
}

.new-property-list-strategies-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.new-property-list-strategies-left-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 8px;
}

.new-property-list-not-recommended-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 4px;
}

.new-property-list-not-recommended-container .tooltip-outer-container {
	position: absolute;
	z-index: 100;
	top: 40px;
	left: 25px;
	width: 250px;
}

.new-property-list-property-count-container {
	min-width: 145px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
}

.new-property-list-left-container-strategies {
	height: calc(100vh - 111px);
}

.new-property-list-cards-container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 24px;
	width: calc(100% - 32px);
}

.new-property-list-inner-container .custom-swiper-outer-container {
	margin-right: 0px;
	margin-left: 0px;
	width: auto;
}

.new-property-list-map-container {
	width: 60%;
}

.new-property-list-inner-container .onboarding-tooltip-first-step {
	position: absolute;
	z-index: 15;
	left: 40%;
	top: 200px;
}

.new-property-list-inner-container .onboarding-tooltip-second-step {
	position: absolute;
	z-index: 15;
	left: 282px;
	top: 320px;
}

.new-property-list-onboarding-hover-0 .new-property-card-outer-container {
	opacity: unset;
}

.new-property-list-onboarding-hover-0 .new-property-card-outer-container .new-property-card-images-swiper-container {
	position: relative;
}

.new-property-list-onboarding-hover-0 .new-property-card-outer-container .new-property-card-bottom-container .new-property-card-onboarding-container {
	position: relative;
	z-index: 14;
	background-color: var(--grayscale-white);
	border-radius: var(--corner-regular);
	padding: 4px;
	transition: all 0.3s ease-in-out;
}

.new-property-list-onboarding-hover-1 .new-property-list-map-container  {
	background-color: var(--grayscale-white);
	position: relative;
	z-index: 14;
	border-radius: var(--corner-regular);
	transition: all 0.3s ease-in-out;
}

.new-property-list-onboarding-hover-1 .new-property-map-outer-container {
	border-radius: var(--corner-regular);
	transition: all 0.3s ease-in-out;
}

.new-property-list-onboarding-hover-2 .new-property-list-strategies-left-container {
	background-color: var(--grayscale-white);
	position: relative;
	z-index: 14;
	border-radius: var(--corner-regular);
	padding: 4px;
	transition: all 0.3s ease-in-out;
}

.onboarding-step-1-active .new-property-card-outer-container:first-of-type {
	z-index: 14;
	background-color: var(--grayscale-white);
	border-radius: var(--corner-regular);
	opacity: 1;
	padding: 8px;
}

@media only screen and (min-width: 2250px) {
	.new-property-list-map-container {
		width: calc(100% - 850px);
	}

	.new-property-list-cards-container {
		grid-template-columns: repeat(3, 1fr);
	}
}

.favourite-icon-container {
	position: absolute;
	z-index: 4;
	right: 4%;
	top: 2%;
	height: 40px;
	width: 40px;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	opacity: 1;
	cursor: pointer;
}

.favourite-icon {
	width: 25px;
}

.new-property-card-outer-container .swiper-slide {
	width: 100% !important;
}

.new-property-list-left-container .new-property-card-images-swiper-container .property-card-badge-outer-container,
.new-property-list-left-container .new-property-card-images-swiper-container .price-cut-outer-container {
    height: unset;
}

.new-property-list-outer-container .modal-outer-container {
	left: 0px;
	right: 0px;
	top: 30%;
	margin-left: auto;
	margin-right: auto;
	z-index: 16;
}

.new-property-list-outer-container .filters-modal-container {
	top: 10%;
}

.new-property-list-left-container .clear-filters-outer-container {
	width: calc(100% - 32px);
	margin-top: 16px;
	height: 100%;
}

.new-property-list-outer-container .modal-upgrade-container {
	top: 10%;
}

.new-property-list-inner-container .toast-outer-container {
	position: fixed;
	bottom: 32px;
	right: 32px;
	z-index: 2;
	min-width: 220px;
}

.new-property-list-outer-container .inactive-market-modal {
	top: 10%;
	max-width: 664px;
	width: 100%;
}

.new-property-list-outer-container .inactive-market-modal .modal-body-container {
	max-height: calc(100dvh - 400px);
	overflow-y: scroll;
}

.new-property-list-outer-container .inactive-market-modal .modal-buttons-secondary-cta {
	width: 100%;
}

.new-property-list-outer-container .modal-buy-box-container {
	top: 78px;
}

.new-property-list-outer-container .modal-buy-box-container .modal-body-container {
	max-height: calc(100dvh - 425px);
	overflow-y: scroll;
}

@media only screen and (min-width: 1000px) {
	.new-property-list-left-container {
		position: relative;
	}

	.new-property-list-outer-container .inactive-market-modal .modal-buttons-secondary-cta {
		justify-content: flex-end;
	}
}

@media only screen and (min-width: 1000px) and (max-width: 1399px) {
	.new-property-list-left-container {
		width: 50%;
	}

	.new-property-list-map-container {
		width: 50%;
	}

	.new-property-list-inner-container .onboarding-tooltip-first-step {
		left: 50%;
	}
}

@media only screen and (max-width: 999px) {
	.new-property-list-outer-container .loading-container {
		top: 20%;
	}

	.new-property-list-inner-row {
		position: relative;
		overflow-y: hidden;
	}

	.new-property-list-cards-container {
		margin: 0 auto;
	}
	
	.new-property-map-element {
		height: 69.5vh;
		height: calc(100dvh - 151px);
	}

	.new-property-list-left-container {
		width: 100%;
		position: absolute;
		z-index: 2;
		bottom: 0%;
		background-color: var(--grayscale-white);
		box-shadow: 0px -2px 12px 0px rgba(181, 164, 148, 0.26);
		height: 69.5vh;
		height: calc(100dvh - 151px);
		max-width: none;
		overflow-x: hidden;
	}
	.new-property-list-strategies-outer-container {
		margin: 0 auto;
	}

	.new-property-list-inner-container-desktop .new-property-map-element,
	.new-property-list-inner-container-desktop .new-property-list-left-container {
		height: calc(100dvh - 151px);
	}

	.new-property-list-map-container {
		width: 100%;
		position: relative;
		z-index: 1;
	}

	.new-property-list-overlay-animate-in {
		animation: 0.5s ease-in-out normal mobile-overlay-slidein;
	}

	.new-property-list-hide-overlay {
		bottom: -150%;
		animation: 0.5s ease-in-out normal mobile-overlay-slideout;
		border-radius: 40px 40px 0px 0px;
		overflow-y: hidden;
	}

	.new-property-list-inner-container .toast-outer-container {
		bottom: 120px;
	}

	@keyframes mobile-overlay-slidein {
		from {
		  bottom: -150%;
		  border-radius: 40px 40px 0px 0px;
		}
	  
		to {
		  bottom: 0%;
		  border-radius: 0px;
		}
	}
	
	@keyframes mobile-overlay-slideout {
		from {
			bottom: 0%;
		  	border-radius: 0px;
		}
	  
		to {
		  bottom: -150%;
		  border-radius: 40px 40px 0px 0px;
		}
	}

	.new-property-list-inner-container-desktop .new-property-list-overlay-animate-in {
		animation: 0.5s ease-in-out normal small-desktop-overlay-slidein;
	}

	.new-property-list-inner-container-desktop .new-property-list-hide-overlay {
		bottom: -150%;
		animation: 0.5s ease-in-out normal small-desktop-overlay-slideout;
	}

	@keyframes small-desktop-overlay-slidein {
		from {
		  bottom: -150%;
		  border-radius: 40px 40px 0px 0px;
		}
	  
		to {
		  bottom: 0%;
		  border-radius: 0px;
		}
	}
	
	@keyframes small-desktop-overlay-slideout {
		from {
			bottom: 0%;
		  	border-radius: 0px;
		}
	  
		to {
		  bottom: -150%;
		  border-radius: 40px 40px 0px 0px;
		}
	}

	.new-property-list-left-container {
		align-items: flex-start;
	}

	.new-property-list-left-container .clear-filters-outer-container {
		width: calc(100% - 16px);
		margin-left: auto;
		margin-right: auto;
	}

	.new-property-list-outer-container .modal-upgrade-container {
		top: 110px;
	}

	.new-property-list-outer-container .modal-upgrade-container .modal-body-container {
		max-height: calc(100dvh - 400px);
		overflow-y: scroll;
	}

	.new-property-list-outer-container .modal-upgrade-container .modal-cancel-button {
		display: none;
	}

	.new-property-list-outer-container .modal-upgrade-container .modal-buttons-secondary-cta {
		width: 100%;
		justify-content: space-between;
	}

	.property-list-top-bar-mobile-right-buttons-row {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		gap: 8px;
	}

	.new-property-list-outer-container .map-settings-modal {
		bottom: 0px;
		top: unset;
	}

	.new-property-list-outer-container .map-settings-modal .dropdown-options-align-top {
		top: -204px;
	}

	.new-property-list-map-container .new-property-map-loading-container {

	}

	.new-property-list-mobile-sorting {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.new-property-list-outer-container .mobile-sorting-modal {
		bottom: 0px;
		top: unset;
	}

	.new-property-list-outer-container .mobile-sorting-modal .modal-body-container {
		max-height: calc(100dvh - 347px);
		overflow-y: scroll;
	}

	.new-property-list-outer-container .neighbourhood-grade-heatmap-modal {
		top: 110px;
	}

	.new-property-list-outer-container .neighbourhood-grade-heatmap-modal .modal-body-container {
		max-height: calc(100dvh - 380px);
		overflow-y: scroll;
	}

	.new-property-list-outer-container .inactive-market-modal .modal-buttons-secondary-cta {
		justify-content: center;
	}
}

@media only screen and (max-width: 449px) {
	.new-property-list-cards-container {
		grid-template-columns: repeat(1, 1fr);
	}
}

.new-property-list-cards-strategies-loading-container {
	grid-template-columns: repeat(1, 1fr);
}

@media only screen and (min-width: 400px) and (max-width: 999px) {
	.new-property-list-outer-container .inactive-market-modal .modal-secondary-button-element,
	.new-property-list-outer-container .inactive-market-modal .modal-primary-button-element {
		width: 50%;
	}
}

@media only screen and (max-width: 399px) {
	.new-property-list-outer-container .inactive-market-modal .modal-secondary-button-element,
	.new-property-list-outer-container .inactive-market-modal .modal-primary-button-element {
		width: auto;
	}
}