.add-comparable-modal-outer-container {
    position: fixed;
    z-index: 2;
    border-radius: 3px;
    box-shadow: 0px 0px 14px 0px rgba(49, 27, 2, 0.17);
    top: 10%;
    width: 90%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    left: 0px;
    right: 0px;
    padding: 32px 0px;
    max-height: 500px;
    overflow-y: scroll;
}

.add-comparable-modal-inner-container {
    position: relative;
    width: calc(100% - 32px);
    margin: 0 auto;
}

.add-comparable-modal-exit-container {
    position: absolute;
    z-index: 1;
    right: 0px;
    top: 0px;
}

.add-comparable-modal-exit-icon {
    width: 16px;
}

.add-comparable-modal-body-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.add-comparable-modal-element-container {
    width: 100%;
}

.add-comparable-modal-date-container div {
    font-family: var(--font-family) !important;
}

.add-comparable-modal-date-container .MuiFormControl-root {
    width: 100%;
}

.add-comparable-modal-body-container input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.add-comparable-modal-body-container input[type=number]{
    -moz-appearance:textfield;
}

.add-comparable-modal-body-container input,
.add-comparable-modal-body-container .MuiInputAdornment-root {
	margin-top: auto;
    margin-bottom: auto;
}

.add-comparable-modal-buttons-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 16px;
}

.add-comparable-modal-cancel-button {
    width: 120px;
    margin-right: 16px;
}

.add-comparable-modal-submit-button {
    width: 130px;
}

.add-comparable-modal-loading-container .loading-container {
    position: fixed;
    top: 20%;
    width: 100px;
}