.pricing-tiers-outer-container {
    border-radius: 8px;
    border: 1px solid var(--blue-blue-01);
    width: 100%;
}

.pricing-tiers-inner-container {
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.pricing-tiers-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.pricing-tiers-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.pricing-tier-element {
    width: 85px;
}

.pricing-tier-dot {
    width: 85px;
    margin-top: -8px;
    margin-bottom: 0px;
    margin-left: -4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.pricing-tiers-mobile-outer-row {
    width: 100%;
}

@media only screen and (min-width: 1000px) {
    .pricing-tiers-text-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
}

@media only screen and (max-width: 999px) {
    .pricing-tiers-row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .pricing-tier-element {
        width: auto;
    }

    .pricing-tiers-text-row {
        border-left: 2px solid var(--blue-blue-02);
        padding-left: 24px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: flex-start;
    }

    .pricing-tier-element {
        margin-bottom: 24px;
    }

    .pricing-tier-element:first-of-type {
        margin-bottom: 0px;
    }

    .pricing-tier-dot {
        position: absolute;
        z-index: 2;
        left: -1px;
    }

    .pricing-tier-dot:first-of-type {
        top: 14px;
    }

    .pricing-tier-dot:nth-of-type(2n) {
        top: 118px;
    }

    .pricing-tier-dot:last-of-type {
        bottom: 89px;
    }
}