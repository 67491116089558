.listings-onboarding-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.listings-onboarding-text-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 8px;
}

.listings-onboarding-element {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}

.listings-onboarding-icon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.listings-onboarding-animation {
    position: absolute;
    z-index: -1;
    width: 24px;
    margin: auto;
    top: -4px;
}