.settings-element-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 32px;
}

.settings-element-left-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    width: calc(100% - 177px);
}

.settings-element-title-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
}

.settings-element-right-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 8px;
    width: 145px;
    margin-left: auto;
}

.settings-element-button-container {
    width: 48px;
}

.settings-element-chip-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.settings-element-right-container .dropdown-outer-container {
    width: 120px;
}

@media only screen and (max-width: 999px) {
    .settings-element-container {
        gap: 16px;
    }

    .settings-element-right-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .settings-element-chip-row {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
    }

    .settings-element-input-container {
        width: calc(100% - 4px);
    }

    .settings-element-right-container .dropdown-outer-container {
        width: calc(100% - 20px);
    }

    .settings-element-right-container .dropdown-options-container {
        top: 46px;
    }

    .settings-element-button-container {
        width: 100%;
    }

    .settings-element-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
    }

    .settings-element-left-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }

    .settings-mobile-section-active .settings-element-container,
    .settings-mobile-section-active .settings-section-container,
    .settings-mobile-section-active .settings-right-section-container,
    .settings-mobile-section-active .settings-section-row,
    .settings-mobile-section-active .settings-inner-body-container,
    .settings-mobile-section-active .settings-body-container {
        height: calc(100dvh - 200px);
    }

    .settings-section-mobile-sticky-button {
        position: sticky;
        z-index: 15;
        bottom: 90px;
        width: 100%;
        background-color: var(--grayscale-white);
        left: 0px;
        display: flex;
        flex-direction: column;
        justify-self: center;
        align-items: center;
    }

    .settings-section-mobile-sticky-button .primary-button {
        width: calc(100% - 32px);
    }
}