.custom-plan-inner-container {
    min-height: calc(100vh - 100px);
    padding-top: 32px;
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 64px;
}

.custom-plan-image {
    width: 94px;
}

.custom-plan-title-container {
    max-width: 328px;
}

.custom-plan-body-container {
    max-width: 438px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.custom-plan-input-container {
    width: 100%;
}

.custom-plan-button-container {
    margin-left: auto;
    margin-right: 0px;
    max-width: 155px;
    width: 100%;
}

.custom-plan-finish-container {
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
}

@media only screen and (max-width: 999px) {
    .custom-plan-button-container {
        max-width: none;
        width: 100%;
        margin: 0 auto;
    }
}