.verify-email-complete-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 80vh;
    gap: 16px;
    padding-top: 40px;
    margin: 0 auto;
}

.verify-email-complete-image {
    width: 100%;
    max-width: 275px;
}

.verify-email-complete-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 8px;
}

.verify-email-complete-inner-container .new-property-list-loading-container {
    max-width: 150px;
}

@media only screen and (max-width: 999px) {
    .verify-email-complete-inner-container {
        padding-top: 24px;
        width: calc(100% - 32px);
    }
}