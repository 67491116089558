.upgrade-modal-outer-container {
    position: absolute;
    z-index: 20;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    top: 100%;
    background-color: var(--beige-beige-01);
    max-width: 500px;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0px 2px 12px 0px rgba(207, 188, 173, 0.50);
}

.upgrade-metro-modal-outer-container {
    top: 80px;
}

.my-cities-outer-container .upgrade-metro-modal-outer-container {
    position: fixed;
}

.upgrade-modal-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 32px;
    padding-bottom: 16px;
    width: 90%;
    margin: 0 auto;
    position: relative;
}

.upgrade-modal-exit-container {
    position: absolute;
    right: 0px;
    top: 16px;
    z-index: 1;
}

.upgrade-modal-exit {
    width: 16px;
    cursor: pointer;
}

.upgrade-modal-body-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.upgrade-modal-pin {
    width: 16px;
    margin-right: 4px;
}

.upgrade-modal-button-container {
    margin-top: 120px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.upgrade-modal-action-button-container {
    width: 200px;
}