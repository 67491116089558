.comp-filters-outer-container {
    position: absolute;
    z-index: 13;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
    max-width: 488px;
    width: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 4px;
    box-shadow: 0px 2px 12px 0px rgba(207, 188, 173, 0.50);
    overflow-y: scroll;
    max-height: 630px;
}

.comp-filters-rent-mode {
    max-height: 470px;
}

.comp-filters-inner-container {
    padding: 48px 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(100% - 64px);
}

.comp-filters-exit-container {
    position: absolute;
    z-index: 1;
    top: 18px;
    right: 18px;
    cursor: pointer;
}

.comp-filters-section-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.comp-filters-element-container {
    width: calc(50% - 12px);
}

.comp-filters-label-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.comp-filters-icon {
    width: 16px;
    margin-right: 4px;
}

.comp-filters-element-container input::-webkit-outer-spin-button,
.comp-filters-element-container input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

.comp-filters-element-container input[type=number] {
	-moz-appearance: textfield;
}

.comp-filters-ctas-row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.comp-filters-cta-complete-button {
    max-width: 120px;
    width: 100%;
}