:root {
    --font-family: Figtree;

    /* Beige */
    --beige-beige-01: #FFF9F2;;
    --beige-beige-02: #FFF3E7;
    --beige-beige-03: #F2E1D0;
    --beige-beige-04: #CFBCAD;

    /* Light blue */
    --light-blue-01: #4B90CC;

    /* Blue */
    --blue-blue-01: #BCCAD4;
    --blue-blue-02: #748EA0;
    --blue-blue-03: #2F4858;
    --blue-blue-0150: #BCCAD480;
    --blue-blue-0130: #BCCAD44D;
    
    /* Brown */
    --brown-brown-01: #512E18;;

    /* Green */
    --green-green-01: #7FD1AE;
    --green-green-02: #68AB8E;
    --green-green-03: #00A8437F;
    --green-green-04: #6EF0A27F;
    --green-green-05: #BDFF00B2;
    --green-green-06: #7FD1AE66;
    --green-green-07: #1C8557;

    /* Grey */
    --grayscale-black: #292621;
    --grayscale-gray-00: #F5F5F5;
    --grayscale-gray-01: #DFD9D3;
    --grayscale-gray-02: #989793;
    --grayscale-gray-03: #716E69;
    --grayscale-gray-04: #DFD9D399;
    --grayscale-gray-05: #29212199;
    --grayscale-white: #FFFEFC;

    /* Indigo */
    --indigo-indigo-00: #AFC0FCB2;
    --indigo-indigo-01: #456DB97F;
    --indigo-indigo-02: #141B5999;

    /* Red */
    --red-red-01: #FE8A80;
    --red-red-01-transparent: #FE8A8033;
    --red-red-02: #CC564B;
    --red-red-03: #FE8A8066;

    /* Purple */
    --purple-purple-01: #9847FF99;
    --purple-purple-02: #3C44FF;

    /* Pink */
    --pink-pink-01: #FF4A88B2;

    /* Orange */
    --orange-orange-01: #FF955BB2;
    --orange-orange-02: #FFCD70B2;
    --orange-orange-03: #FF955B;

    /* Yellow */
    --yellow-yellow-01: #E8DA78;
    --yellow-yellow-02: #F8DB02BF;
    --yellow-yellow-03: #FFE94AB2;
    --yellow-yellow-04: #FFE94A;
    --yellow-yellow-05: #CBB22B;

    /* Cyan */
    --cyan-cyan-01: #39DBFFB2;

    /* Map Colours */
    --mapcolors-map-zone-a: var(--green-green-03);
    --mapcolors-map-zone-b: var(--green-green-04);
    --mapcolors-map-zone-bc: var(--yellow-yellow-02);
    --mapcolors-map-zone-c: var(--indigo-indigo-01);
    --mapcolors-map-zone-d: var(--indigo-indigo-02);
    --mapcolors-map-zone-inactive: var(--grayscale-gray-04);

    /* Gentrification Map Colour */
    --map-colors-map-gentrification-gentrified: var(--pink-pink-01);
    --map-colors-map-gentrification-inprogress: var(--orange-orange-01);
    --map-colors-map-gentrification-earlysigns: var(--yellow-yellow-03);
    --map-colors-map-gentrification-littlenone: var(--indigo-indigo-00);

    /* Hotspots */
    --map-colors-map-hotspot-hipster: var(--purple-purple-01);
    --map-colors-map-hotspot-college: var(--green-green-05);
    --map-colors-map-hotspot-affordable: var(--cyan-cyan-01);

    /* Buttons */
    --accent-accent-01: #3C44FF;

    /* Corner Values */
    --corner-small: 4px;
    --corner-regular: 8px;
    --corner-medium: 12px;
    --corner-large: 24px;
    --corner-xl: 48px;

    /* Badge colours */
    --value-low: rgba(254, 138, 128, 0.20);
    --value-medium: rgba(255, 233, 74, 0.40);
    --value-high: rgba(127, 209, 174, 0.20);
}

.display-xl-semibold {
    font-family: var(--font-family);
    font-size: 68px;
    font-style: normal;
    font-weight: 700;
    line-height: 74px;
    letter-spacing: -2.04px;
    margin: 0px;
}

.heading-large-semibold {
    font-family: var(--font-family);
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.56px;
    margin: 0px;
}

.heading-large-regular {
    font-family: var(--font-family);
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: -0.56px;
    margin: 0px;
}

.heading-large-light {
    font-family: var(--font-family);
    font-size: 28px;
    font-style: normal;
    font-weight: 300;
    line-height: 34px;
    letter-spacing: -0.56px;
    margin: 0px;
}

.heading-small-semibold {
    font-family: var(--font-family);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.36px;
    margin: 0px;
}

.heading-small-regular {
    font-family: var(--font-family);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.36px;
    margin: 0px;
}

.body-semibold {
    font-family: var(--font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin: 0px;
}

.body-regular {
    font-family: var(--font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0px;
}

.tooltip-regular {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 0px;
}

.body-regular-underline {
    font-family: var(--font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration-line: underline;
    margin: 0px;
}

.label-semibold {
    font-family: var(--font-family);
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.1px;
    margin: 0px;
}

.label-regular {
    font-family: var(--font-family);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.1px;
    margin: 0px;
}

.label-semibold-caps {
    font-family: var(--font-family);
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    margin: 0px;
}

.label-regular-caps {
    font-family: var(--font-family);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    margin: 0px;
}

.xs-label {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.12px;
}

.xs-label-semibold {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.12px;
}

@media only screen and (max-width: 999px) {
    .display-xl-semibold {
        font-family: var(--font-family);
        font-size: 53px;
        font-style: normal;
        font-weight: 700;
        line-height: 62px;
        letter-spacing: -1.59px;
        margin: 0px;
    }
    
    .heading-large-semibold {
        font-family: var(--font-family);
        font-size: 35px;
        font-style: normal;
        font-weight: 700;
        line-height: 42px;
        letter-spacing: -0.7px;
        margin: 0px;
    }
    
    .heading-large-regular {
        font-family: var(--font-family);
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: -0.7px;
        margin: 0px;
    }

    .heading-large-light {
        font-family: var(--font-family);
        font-size: 35px;
        font-style: normal;
        font-weight: 300;
        line-height: 42px;
        letter-spacing: -0.7px;
        margin: 0px;
    }
    
    .heading-small-semibold {
        font-family: var(--font-family);
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: -0.52px;
        margin: 0px;
    }

    .heading-small-regular {
        font-family: var(--font-family);
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: -0.52px;
        margin: 0px;
    }
    
    .body-semibold {
        font-family: var(--font-family);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        margin: 0px;
    }
    
    .body-regular {
        font-family: var(--font-family);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        margin: 0px;
    }
    
    .body-regular-underline {
        font-family: var(--font-family);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        text-decoration-line: underline;
        margin: 0px;
    }
    
    .label-semibold {
        font-family: var(--font-family);
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.15px;
        margin: 0px;
    }
    
    .label-regular {
        font-family: var(--font-family);
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.15px;
        margin: 0px;
    }
    
    .label-semibold-caps {
        font-family: var(--font-family);
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.15px;
        text-transform: uppercase;
        margin: 0px;
    }
    
    .label-regular-caps {
        font-family: var(--font-family);
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.15px;
        text-transform: uppercase;
        margin: 0px;
    }
}

.text-bold {
    font-weight: 700;
}

.text-regular {
    font-weight: 400;
}

/* BG Colour Classes */

.bg-colour-dark {
    background-color: var(--grayscale-black);
}

.bg-colour-medium {
    background-color: var(--beige-beige-03);
}

.bg-colour-light {
    background-color: var(--beige-beige-01);
}

.bg-colour-grey {
    background-color: var(--grayscale-gray-00);
}

.bg-colour-white {
    background-color: var(--grayscale-white);
}

.bg-colour-green {
    background-color: var(--green-green-01);
}

.bg-colour-green-2 {
    background-color: var(--green-green-02);
}

.bg-colour-green-6 {
    background-color: var(--green-green-06);
}

.bg-colour-yellow {
    background-color: var(--yellow-yellow-01);
}

.bg-colour-yellow-2 {
    background-color: var(--yellow-yellow-02);
}

.bg-colour-yellow-5 {
    background-color: var(--yellow-yellow-05);
}

.bg-colour-red {
    background-color: var(--red-red-01);
}

.bg-colour-red-2 {
    background-color: var(--red-red-02);
}

.bg-colour-red-3 {
    background-color: var(--red-red-03);
}

.bg-colour-black {
    background-color: var(--grayscale-black);
}

.bg-colour-orange-2 {
    background-color: var(--orange-orange-02);
}

.bg-colour-orange-3 {
    background-color: var(--orange-orange-03);
}

.bg-colour-yellow-4 {
    background-color: var(--yellow-yellow-04);
}

.bg-colour-brown {
    background-color: var(--brown-brown-01);
}

/* Property Statuses */
.bg-colour-active-status {
    background-color: var(--green-green-04);
}

.bg-colour-sold-status {
    background-color: var(--indigo-indigo-00);
}

.bg-colour-under-contract-status {
    background-color: var(--yellow-yellow-03);
}

.bg-colour-archive-status {
    background-color: var(--grayscale-gray-01);
}

.bg-colour-modal-open {
    background-color: rgba(0, 0, 0, 0.45);
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	bottom: 0px
}

.bg-colour-low-score {
    background-color: var(--value-low);
}

.bg-colour-medium-score {
    background-color: var(--value-medium);
}

.bg-colour-high-score {
    background-color: var(--value-high);
}

/* Colour Classes */

.colour-primary {
    color: var(--grayscale-black);
}

.colour-secondary {
    color: var(--grayscale-gray-03);
}

.colour-tertiary {
    color: var(--grayscale-gray-02);
}

.colour-quaternary {
    color: var(--blue-blue-02);
}

.colour-light-blue {
    color: var(--light-blue-01);
}

.colour-accent {
    color: var(--accent-accent-01);
}

.text-link {
    color: var(--blue-blue-03);
}

.colour-link {
    color: var(--blue-blue-03);
    cursor: pointer;
}

.colour-error {
    color: var(--red-red-02);
}

.colour-medium {
    color: var(--yellow-yellow-05);
}

.colour-green {
    color: var(--green-green-02);
}

.colour-green-7 {
    color: var(--green-green-07);
}

.colour-white {
    color: var(--grayscale-white);
}

.colour-beige-01 {
    color: var(--beige-beige-01);
}

.colour-beige {
    color: var(--beige-beige-04)
}

.colour-brown {
    color: var(--brown-brown-01);
}

.colour-primary-hover:hover {
    color: var(--grayscale-black);
    transition: color 0.3s ease-in-out;
}

/* Spacing Classes */

.margin-x-x-x-small {
    margin-bottom: 2px;
}

.margin-x-x-small {
    margin-bottom: 4px;
}

.margin-x-small {
    margin-bottom: 8px;
}

.margin-medium {
    margin-bottom: 16px;
}

.margin-large {
    margin-bottom: 24px;
}

.margin-x-large {
    margin-bottom: 32px;
}

.margin-x-x-large {
    margin-bottom: 48px;
}

.margin-x-x-x-large {
    margin-bottom: 64px;
}

.subtext-margin-right-small {
    margin-right: 2px;
}

.subtext-margin-right {
    margin-right: 4px;
}

.subtext-margin-right-large {
    margin-right: 8px;
}

.subtext-margin-right-x-large {
    margin-right: 16px;
}

.subtext-margin-right-x-x-large {
    margin-right: 24px;
}

.subtext-margin-right-x-x-x-large {
    margin-right: 32px;
}

.subtext-margin-left-small {
    margin-left: 2px;
}

.subtext-margin-left {
    margin-left: 4px;
}

.subtext-margin-left-large {
    margin-left: 8px;
}

.margin-left-medium {
    margin-left: 16px;
}

.margin-zero-auto {
    margin: 0 auto;
}

.margin-top-x-small {
    margin-top: 4px;
}

.margin-top-small {
    margin-top: 8px;
}

.margin-top-medium {
    margin-top: 16px;
}

.margin-top-x-large {
    margin-top: 24px;
}

.margin-top-x-x-large {
    margin-top: 32px;
}

.margin-top-x-x-x-large {
    margin-top: 48px;
}

.dot-spacing {
    margin: 0px 8px;
}

.text-padding {
    padding: 8px 0px;
}

.block-text {
    display: block;
}

.inline-block-text {
    display: inline-block;
}

.relative-container {
    position: relative;
}

.no-underline {
    text-decoration-line: none;
}

.underline {
    text-decoration-line: underline;
}

.strike-through {
    text-decoration-line: line-through;
}

.text-capitalise {
    text-transform: capitalize;
}

.text-lowercase {
    text-transform: lowercase;
}

.text-italic {
    font-style: italic;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default;
}

.cursor-disabled {
    cursor: not-allowed;
}

.cursor-grab {
    cursor: grab; /* W3C standards syntax, all modern browser */
}

.cursor-grab:active {
    cursor: grabbing;
}

.view-none {
    display: none;
}

.white-space-pre-wrap {
    white-space: pre-wrap;
}

.white-space-no-wrap {
    white-space: nowrap;
}

.text-align-left {
    text-align: left;
}

.text-align-center {
    text-align: center;
}

.text-align-right {
    text-align: right;
}

.float-left {
    float: left;
}

.no-margin {
    margin: 0px;
}

.map-zone-a {
    background-color: var(--mapcolors-map-zone-a);
}

.map-zone-b {
    background-color: var(--mapcolors-map-zone-b);
}

.map-zone-bc {
    background-color: var(--mapcolors-map-zone-bc);
}

.map-zone-c {
    background-color: var(--mapcolors-map-zone-c);
}

.map-zone-d {
    background-color: var(--mapcolors-map-zone-d);
}

.map-zone-inactive {
    background-color: var(--mapcolors-map-zone-inactive);
}

.gentrified {
    background-color: var(--map-colors-map-gentrification-gentrified);
}

.gentrification-progress {
    background-color: var(--map-colors-map-gentrification-inprogress);
}

.gentrification-early-signs {
    background-color: var(--map-colors-map-gentrification-earlysigns);
}

.gentrification-little-none {
    background-color: var(--map-colors-map-gentrification-littlenone);
}

.map-zone-hipster {
    background-color: var(--map-colors-map-hotspot-hipster);
}

.map-zone-college {
    background-color: var(--map-colors-map-hotspot-college);
}

.map-zone-affordable-housing {
    background-color: var(--map-colors-map-hotspot-affordable);
}

.reverse {
    transform: rotate(180deg);
}

.rotate-90 {
    transform: rotate(90deg);
}

.dark-bg {
    background-color: #00000073;
	position: absolute;
	z-index: 12;
	width: 100%;
	height: 100%;
    top: 0px;
}

.blur-bg {
    background-color: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(8px);
	position: absolute;
	z-index: 12;
	width: 100%;
	height: 100%;
    top: 0px;
}

.small-loading-container {
    width: 16px;
}

.disabled {
    cursor: not-allowed !important;
    opacity: 0.7;
}

.margin-sides {
    margin: 0px 8px;
}

.margin-tops {
    margin: 8px 0px;
}

.margin-tops-small {
    margin: 4px 0px;
}

.gap-x-x-small {
    gap: 2px;
}

.gap-x-small {
    gap: 4px;
}

.gap-small {
    gap: 8px;
}

.button-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.button-row-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.icon-button {
    padding: 4px;
    cursor: pointer;
}

.icon-button:hover {
    background-color: var(--beige-beige-03);
    border-radius: 100%;
}

.text-button,
.text-button-inverse {
    padding: 6px 12px;
    cursor: pointer;
}

.text-button:hover {
    background-color: var(--blue-blue-0130);
    border-radius: 1000px;
    transition: background-color 0.3s ease-in-out;
}

.text-button-inverse:hover {
    background-color: var(--blue-blue-02);
    border-radius: 1000px;
    transition: background-color 0.3s ease-in-out;
}

.chip-button:hover {
    border: 2px solid var(--blue-blue-03);
}

.underline-hover:hover {
    text-decoration-line: underline;
}

.margin-none {
    margin: 0px !important;
}

.scroll-shadow {
    background: linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.05) 100%);
    width: 12px;
}

.no-opacity {
    opacity: 0;
}

.no-height {
    height: 0px;
}

.blurred {
    filter: blur(3px);
}

.negative {
    background-color: #FE8A8080;
}

.grey-divider {
    border-radius: 100px;
    background-color: var(--grayscale-gray-01);
    width: 100%;
    height: 1px;
}

.icon-medium {
    width: 16px;
    height: 16px;
}

.column-reverse {
    flex-direction: column-reverse;
}

@media only screen and (min-width: 1000px) {
    .desktop-none {
        display: none !important;
    }
}

@media only screen and (max-width: 999px) {
    .mobile-margin-x-small {
        margin-bottom: 8px;
    }

    .mobile-margin-medium {
        margin-bottom: 16px;
    }

    .mobile-none {
        display: none !important;
    }

    .mobile-text-align-center {
        text-align: center;
    }
}