.condition-filter-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 16px;
    padding-left: 4px;
}

.condition-filter-body-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

@media only screen and (max-width: 999px) {
    .filters-modal-body-row .condition-filter-container {
        width: calc(100% - 4px);
    }
}