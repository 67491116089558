.onboarding-complete-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}

.onboarding-complete-text-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.onboarding-complete-image {
    width: 100%;
    max-width: 218px;
}

@media only screen and (max-width: 499px) {
    .onboarding-complete-image-container {
        max-width: 150px;
    }
}