.pick-plan-outer-container {
    height: 100%; 
    overflow: auto;
}

.pick-plan-inner-container {
    min-height: 80vh;
    max-width: 1440px;
    margin: 0 auto;
}

.pick-plan-title-container {
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pick-plan-options-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.pick-plan-core-cities-container {
    width: 100%;
    max-width: 437px;
    padding-right: 32px;
}

.core-cities-map-container .upgrade-city-map-element {
    height: 300px;
}

.pick-plan-map-container .upgrade-city-map-element {
    max-height: 360px;
}

.pick-plan-core-cities-container .pick-city-body-element {
    cursor: default;
}

.pick-plan-core-cities-subtitles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.pick-plan-input-padding {
	padding-bottom: 24px !important;
}

.pick-plan-sorting-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	border-bottom: 1px solid var(--grayscale-gray-01);
    max-width: 890px;
    margin: 0 auto;
}

.pick-plan-sorting-container .MuiSelect-select {
	font-weight: 400 !important;
}

.pick-plan-sorting-container svg {
    display: none;
}

.pick-plan-sorting-tick {
	width: 16px;
	height: 16px;
	margin-right: 8px;
}

.pick-plan-sorting-container .MuiInputBase-root .pick-plan-sorting-tick {
	display: none;
}

.pick-plan-50-cities-grid {
    display: grid;
	margin: 0 auto;
	width: 100%;
	grid-gap: 24px;
	max-width: 858px;
    padding: 16px;
}

/* .pick-plan-50-cities-fixed-container {
    position: sticky;
    bottom: 0px;
    width: 100%;
    max-width: 890px;
    margin-left: auto;
    margin-right: auto;
    left: 0px;
    right: 0px;
    padding-top: 32px;
    padding-bottom: 16px;
}

.pick-plan-50-cities-button-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-left: auto;
}

.pick-plan-50-cities-button-inner-container {
    width: 100%;
    max-width: 210px;
} */

.pick-plan-inner-container .upgrade-city-body-container {
    margin: 0 auto;
}

.city-filtering-state-text-container {
    padding: 8px 16px;
}

.metro-filtering-element {
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;
}

.metro-filtering-element:hover {
    background-color: var(--blue-blue-01);
}

.metro-filtering-divider-padding {
    padding: 8px 16px;
}

.metro-filtering-divider {
    border-radius: 100px;
    background-color: var(--grayscale-gray-01);
    height: 1px;
    width: 100%;
}

.upgrade-city-click-out-container {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
}

.pick-plan-checkout-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.pick-plan-checkout-button-element {
    width: 100%;
    max-width: 210px;
}

.core-cities-map-container {
    position: sticky;
    z-index: 1;
    top: 0px;
    right: 0px;
    width: 100%;
    max-width: 550px;
}

.pick-plan-map-container {
    width: 100%;
    max-width: 550px;
}

.pick-plan-input-container {
    width: 323px;
    margin-right: 24px;
}

.pick-plan-county-left-container {
    /* margin-right: 24px; */
}

@media only screen and (min-width: 1300px) {
    .pick-plan-50-cities-grid {
		grid-template-columns: repeat(1, 1fr);
		padding-bottom: 16px;
        grid-gap: 16px;
	}

    .pick-plan-mobile-back-container,
    .pick-plan-mobile-progress-bar {
        display: none;
    }

    .pick-plan-mobile-sticky-container {
        display: none;
    }

    .pick-plan-core-cities-container .pick-city-body-element {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .pick-plan-core-cities-container .pick-city-icon  {
        margin-bottom: 0px;
        margin-right: 16px;
        width: 32px;
    }
}

@media only screen and (max-width: 1299px) {
    .pick-plan-inner-container {
        width: calc(100% - 32px);
        padding-top: 16px;
    }

    .pick-plan-mobile-back-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 32px);
    }

    .pick-plan-back-icon {
        margin-right: 4px;
        width: 16px;
        height: 16px;
    }

    .pick-plan-mobile-progress-bar  {
        width: 100%;
    }

    .pick-plan-title-container {
        padding-top: 0px;
    }

    .pick-plan-options-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: calc(100% - 32px);
        margin: 0 auto;
    }

    .pick-plan-inner-container .upgrade-city-body-container {
        flex-direction: column;
    }

    .pick-plan-50-cities-grid {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 16px 8px;
		width: calc(100% - 14px);
		padding-bottom: 16px;
        padding: 0px;
	}

    .pick-plan-50-cities-grid .body-semibold {
        font-size: 14px;
    }

    .pick-plan-50-cities-grid .pick-city-icon {
        width: 35px;
        margin-bottom: 0px;
    }

    .pick-plan-county-left-container {
        width: 100%;
        margin-right: 0px;
    }

    .pick-plan-core-cities-container {
        padding-right: 0px;
        margin: 0 auto;
    }

    .core-cities-map-container {
        margin: 0 auto;
        display: none;
    }

    .pick-plan-mobile-sticky-container {
        position: sticky;
        z-index: 2;
        bottom: 0px;
        width: 100%;
        background-color: var(--grayscale-white);
        box-shadow: 0px -3px 15.8px 0px rgba(38, 41, 33, 0.15);
    }

    .pick-plan-mobile-inner-sticky-container {
        padding: 24px;
        padding-bottom: 48px;
        max-width: 390px;
        margin: 0 auto;
    }

    .pick-plan-mobile-row-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }

    .pick-plan-mobile-button-container {
        padding: 16px;
        width: auto;
    }

    .pick-plan-checkout-button-container {
        display: none;
    }

    .pick-plan-map-container {
        width: 100%;
        max-width: 550px;
        margin: 0 auto;
    }
    
    .pick-plan-input-container {
        width: 100%;
        max-width: 323px;
        margin-right: 0px;
        margin: 0 auto;
    }
}