.budget-filter-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
}

.budget-filter-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.budget-filter-element {
    width: 113px;
}

@media only screen and (max-width: 999px) {
    .budget-filter-container {
        width: 100%;
    }

    .budget-filter-inner-container {
        width: 100%;
    }

    .budget-filter-element {
        width: 100%;
        max-width: 164px;
    }

    .filters-modal-body-row .budget-filter-container {
        width: calc(100% - 4px);
    }
}