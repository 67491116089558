.mobile-radio-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    padding: 16px;
    border-radius: var(--corner-regular);
    width: calc(100% - 36px);
}

.mobile-radio-unselected {
    border: 1px solid var(--grayscale-gray-01);
}

.mobile-radio-selected {
    border: 2px solid var(--blue-blue-03);
}