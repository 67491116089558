.neighbourhood-watch-outer-container {
    background-color: var(--beige-beige-01);
    overflow: hidden;
}

.neighbourhood-watch-inner-container {
    max-width: 1350px;
    padding: 40px;
    margin: 0 auto;
}

.neighbourhood-watch-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 32px;
}

.neighbourhood-watch-row-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    width: 100%;
}

.neighbourhood-watch-text-container {
    width: 50%;
}

.neighbourhood-watch-map-container {
    width: 100%;
    position: relative;
}

.neighbourhood-watch-map-container .map-legend-outer-container {
    position: absolute;
    right: 10px;
}

.neighbourhood-map-element {
    width: 100%;
    height: 600px;
}

.neighbourhood-watch-panel-container {
    background-color: #FFFFFF;
    height: 500px;
    overflow-y: scroll;
    box-shadow: -4px 0px 10px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: calc(100% - 16px);
}

@media only screen and (max-width: 1119px) {
    .neighbourhood-watch-inner-container .map-legend-element {
        width: 34px;
        height: 28px;
    }

    .neighbourhood-watch-inner-container .map-legend-element-gentrification {
        width: 56px;
        height: 28px;
        padding: 0px 8px;
    }

    .neighbourhood-watch-inner-container .map-legend-element-gentrification .body-semibold {
        font-size: 8px;
        line-height: 14px;
    }

    .neighbourhood-watch-inner-container .map-legend-selected-element .body-semibold {
        font-size: 6px;
    }
}

@media only screen and (max-width: 767px) {
    .neighbourhood-watch-inner-container {
        padding: 40px 0px;
        width: 95%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }

    .neighbourhood-watch-text-container {
        display: none;
    }

    .neighbourhood-watch-map-container {
        width: 100%;
    }

    .neighbourhood-watch-map-container .map-legend-outer-container {
        right: 60px;
    }

    .neighbourhood-watch-map-container .side-panel-trigger-outer-container {
        right: 10px;
        position: absolute;
    }

    .neighbourhood-watch-map-container .side-panel-animate-out {
        right: -400px;
    }

    .neighbourhood-watch-map-container .side-panel-animate-out {
        animation: 0.75s ease-in-out normal side-panel-out-neighbourhood;
        right: -400px;
    }
    
    @keyframes side-panel-out-neighbourhood {
        from {
            right: 8px;
        }
        to {
            right: -400px;
        }
    }
    
    .neighbourhood-watch-map-container .side-panel-animate-in {
        animation: 0.75s ease-in-out normal side-panel-in-neighbourhood;
        right: 8px;
        height: 100%;
    }
    
    @keyframes side-panel-in-neighbourhood {
        from {
            right: -400px;
        }
        to {
            right: 8px;
        }
    }
    
    .neighbourhood-watch-map-container .side-panel-hidden {
        right: -400px;
    }
}