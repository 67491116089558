.new-property-characteristics-outer-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 4px;
}

.new-property-characteristics-address-text {
	font-size: 12px;
	font-family: var(--font-family);
	font-style: normal;
	font-weight: 400;
	line-height: 14px;
	color: var(--grayscale-black);
}

.new-property-characteristics-spacer {
	margin: 0px 8px;
}

.new-property-characteristics-element-text {
	font-size: 12px;
	font-family: var(--font-family);
	font-style: normal;
	font-weight: 500;
	line-height: 14px;
	color: var(--grayscale-black);
}