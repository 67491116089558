.new-buy-box-modal-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
    width: 100%;
    min-height: 300px;
}

.new-buy-box-modal-element {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
}

.new-buy-box-modal-container .exit-screen-container {
    width: 100%;
    height: 100%;
    top: 0px;
}