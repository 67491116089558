.size-large {
    width: 100%;
}

.size-large-padding {
    padding: 0px 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    gap: 8px;
}

.size-medium {
    width: 100%;
}

.size-medium-padding {
    padding: 0px 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 32px;
    gap: 4px;
}

.size-small {
    width: 100%;
}

.size-small-padding {
    padding: 0px 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 28px;
    gap: 4px;
}

.size-x-small {
    width: 100%;
}

.size-x-small-padding {
    padding: 0px 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 24px;
    gap: 4px;
}

.button-radius-normal {
    border-radius: var(--corner-regular);
}

.primary-button {
    background-color: var(--blue-blue-03);
    transition: background-color 0.3s ease-in-out;
}

.accent-button {
    background-color: var(--accent-accent-01);
    transition: background-color 0.3s ease-in-out;
}

.secondary-button {
    border: 1px solid var(--blue-blue-03);
    transition: background-color 0.3s ease-in-out;
}

.tertiary-button {
    background: var(--blue-blue-01);
    transition: background-color 0.3s ease-in-out;
}

.destructive-button {
    border: 1px solid var(--red-red-02);
    transition: background-color 0.3s ease-in-out;
}

.light-button {
    background-color: var(--grayscale-white);
    transition: background-color 0.3s ease-in-out;
}

.third-party-button {
    border-radius: var(--corner-regular);
    background-color: var(--grayscale-white);
    box-shadow: 0px 0px 8px 0px rgba(152, 151, 147, 0.40);
    transition: background-color 0.3s ease-in-out;
}

.button-disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
}

.button-icon-large {
    width: 16px;
}

.button-icon-medium {
    width: 16px;
}

.button-icon-small {
    width: 8px;
}

.button-icon-x-small {
    width: 8px;
}

@media only screen and (min-width: 1000px) {
    .primary-button:hover {
        background-color: var(--grayscale-black);
    }

    .primary-button.button-disabled:hover {
        background-color: var(--blue-blue-03);
    }

    .accent-button:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), var(--accent-accent-01);
    }

    .accent-button.button-disabled:hover {
        background: unset;
        background-color: var(--accent-accent-01);
    }

    .secondary-button:hover {
        background-color: var(--grayscale-gray-01);
    }

    .secondary-button.button-disabled:hover {
        background-color: unset;
    }

    .tertiary-button:hover {
        background: var(--blue-blue-02);
    }

    .tertiary-button.button-disabled:hover {
        background-color: var(--blue-blue-01);
    }

    .destructive-button:hover {
        background: var(--grayscale-gray-01);
    }

    .destructive-button.button-disabled:hover {
        background-color: unset;
    }

    .light-button:hover {
        background: var(--grayscale-gray-01);
    }

    .light-button.button-disabled:hover {
        background: var(--grayscale-white);
    }

    .third-party-button:hover {
        background-color: var(--grayscale-gray-01);
    }

    .third-party-button.button-disabled:hover {
        background-color: var(--grayscale-white);
    }
}