.property-type-filter-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
}

.property-type-selectors-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    width: calc(100% - 1px);
}

.property-type-selectors-container svg {
    width: 32px;
    height: 32px;
}