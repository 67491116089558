.repeat-user-outer-container {

}

.repeat-user-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 32px;
    padding: 48px 0px;
    max-width: 600px;
    margin: 0 auto;
    min-height: 80vh;
}

.repeat-user-body-container {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.borat-image {
    width: 100%;
    max-width: 200px;
    border-radius: var(--corner-regular);
}

.repeat-user-button-container {
    width: 100%;
    max-width: 200px;
    padding-bottom: 100px;
}

@media only screen and (max-width: 999px) {
    .repeat-user-inner-container {
        width: calc(100% - 32px);
        padding-top: 16px;
    }
}