.pricing-card-outer-container {
    background-color: var(--grayscale-white);
    box-shadow: 0px 2px 12px 0px rgba(207, 188, 173, 0.50);
    position: relative;
    /* width: 450px; */
}

.pay-row-container .pricing-card-outer-container {
    max-width: 330px;
}

.new-checkout-right-container .pricing-card-outer-container {
    width: unset;
}

.pricing-card-top-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.pricing-card-top-inner-container {
    padding: 32px;
    background-color: var(--beige-beige-01);
    height: 380px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}

.new-checkout-right-container .pricing-card-top-inner-container {
    height: unset;
}

.pay-right-container .pricing-card-top-inner-container {
    align-items: flex-start;
    height: 180px;
}

.pricing-card-row-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 4px;
}

.pricing-card-discount-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.pricing-text-right-space {
    margin-right: 4px !important;
}

.pricing-card-features-container {
    padding: 32px;
}

.pricing-card-best-for-container {
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pricing-card-features-list {
    padding: 16px 32px;
    background-color: var(--grayscale-white);
    margin: 0px;
}

.pricing-card-features-list-ul {
    padding-left: 0px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.pricing-card-features-list-element {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.pricing-card-features-list-element:last-of-type {
    margin-bottom: 0px;
}

.pricing-card-features-list-element-icon {
    margin-right: 8px;
}

.pricing-card-button-container {
    width: calc(100% - 64px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.pricing-card-button-extra-text {
    margin-top: 16px !important;
}

.pricing-card-bow-container {
    width: 100%;
    background-color: var(--beige-beige-01);
    position: relative;
}

.pricing-card-bow-img {
    width: 100%;
    position: absolute;
    top: -19px;
}

.pricing-card-bottom-container {
    padding: 32px;
}

.pricing-card-bottom-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.pricing-card-bottom-right-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.pricing-card-total-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 8px;
    width: 100%;
}

.city-selection-divider-container {
    width: 100%;
    border-top: 0.5px solid var(--grayscale-gray-02);
}

.pricing-card-total-label-container {
    margin-right: 24px;
}

.pricing-card-total-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.pricing-card-total-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.pricing-card-supplementary-value,
.pricing-card-renewal-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.pricing-card-image {
    height: 60px;
}

.pricing-card-bottom-left-container {
    width: 60%;
}

.pricing-card-custom-margin-top {
    margin-top: 15px;
}

.pricing-card-features-list-element-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.pricing-card-ends-soon-container {
    padding: 4px 8px;
    border-radius: 100px;
    margin-left: 4px;
    width: 94px;
}

.pricing-card-features-list-element-text-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

@media only screen and (max-width: 999px) {
    .pricing-cards-row {
        display: none !important;
    }

    .pricing-card-outer-container {
        display: none;
    }

    .pick-single-city-outer-container .pricing-card-outer-container {
        display: unset;
    }

    .pricing-card-top-inner-container {
        padding: 24px 16px;
    }

    .pricing-card-image-container {
        margin-bottom: 8px;
    }

    .pricing-card-row-container {
        margin-bottom: 8px;
    }

    .pricing-card-bow-img {
        width: 100%;
        position: unset;
        top: unset;
    }

    .bf-pricing-card-mobile-outer-container .pricing-card-mobile-accordion {
        margin-top: 24px;
    }

    .pricing-card-bottom-left-container {
        width: 50%;
    }
}

@media only screen and (max-width: 999px) and (min-width: 768px) {
    .pricing-card-bow-container {
        background-color: unset;
        position: absolute;
        left: 0px;
        top: 123px;
    }
}

@media only screen and (max-width: 767px) and (min-width: 450px) {
    .pricing-card-bow-container {
        background-color: unset;
        position: absolute;
        left: 0px;
        top: 130px;
    }
}

@media only screen and (max-width: 767px) and (max-width: 449px) {
    .pricing-card-bow-container {
        background-color: unset;
        position: absolute;
        left: 0px;
        top: 140px;
    }
}