.city-filtering-outer-container {
    position: absolute;
    z-index: 3;
    top: 38px;
    background-color: var(--grayscale-white);
    width: 100%;
    max-height: 250px;
    overflow-y: scroll;
    border-radius: var(--corner-regular);
    box-shadow: 0px 0px 8px 0px rgba(153, 149, 145, 0.50);
}

.city-filtering-loading-container {
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.city-filtering-label-element {
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: calc(100% - 16px);
    border-radius: var(--corner-regular);
}

.city-filtering-element {
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;
    width: calc(100% - 16px);
    border-radius: var(--corner-regular);
}

.city-filtering-element:hover {
    background-color: var(--blue-blue-01);
}

.city-filtering-element-selected {
    background-color: var(--blue-blue-01);
}

.city-filtering-no-padding-bottom {
    padding-bottom: 0px;
}

.city-filtering-no-padding-top {
    padding-top: 0px;
}

@media only screen and (max-width: 999px) {
    .city-filtering-outer-container {
        top: 46px;
    }
}