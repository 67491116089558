@media only screen and (max-width: 999px) {
	.mobile-property-card-overlay-outer-container {
		width: calc(100% - 16px);
		left: 0px;
		right: 0px;
		margin-left: auto;
		margin-right: auto;
		position: absolute;
		z-index: 4;
		background-color: var(--grayscale-white);
		bottom: 83px;
		border-radius: 8px;
		max-width: 465px;
	}

	.mobile-property-card-overlay-image-container {
		background-repeat: no-repeat;
		object-fit: cover;
		width: 100%;
		max-width: 160px;
		height: var(--text-height);
		position: relative;
		border-top-left-radius: 8px;
    	border-bottom-left-radius: 8px;
	}

	.mobile-property-card-overlay-inner-container {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 8px;
	}

	.mobile-property-card-overlay-right-container {
		width: calc(100% - 196px);
		padding: 8px 0px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 16px;
	}

	.mobile-property-card-overlay-price-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		width: 100%;
	}

	.mobile-property-card-overlay-price-container .icon-button-element {
		position: absolute;
		z-index: 1;
		top: 0px;
		right: 0px;
	}
	
	.mobile-property-card-overlay-info-container {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 8px;
		width: 100%;
	}

	.mobile-property-card-details-row {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		gap: 4px;
		flex-wrap: wrap;
	}

	.mobile-property-card-overlay-roi-container {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		gap: 8px;
	}
}