.owner-type-filter-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
}

.owner-type-filter-title-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}

.owner-type-filter-body-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

@media only screen and (max-width: 999px) {
    .owner-type-filter-container {
        
    }

    .owner-type-filter-body-container-mobile {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
        width: 100%;
    }
}