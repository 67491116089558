.option-switch-outer-container {
    background-color: var(--blue-blue-01);
	height: 40px;
    width: var(--switch-width);
	border-radius: 100px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: relative;
}

.option-switch-inner-container {
    display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
    width: calc(100% - 4px);
    height: 36px;
}

.option-switch-element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.option-switch-left-width {
    width: var(--left-width);
}

.option-switch-right-width {
    width: var(--right-width);
}

.option-switch-thumb-element {
	width: 50%;
	height: 36px;
	border-radius: 100px;
	position: absolute;
	z-index: 1;
    left: var(--start);
}

.option-switch-move-right {
	animation: 0.5s ease-in-out normal option-switch-right;
	left: var(--end);
    width: var(--right-width);
}

@keyframes option-switch-right {
	from {
		left: var(--start);
        width: var(--left-width);
	}
	to {
		left: var(--end);
        width: var(--right-width);
	}
}

.option-switch-move-left {
	animation: 0.5s ease-in-out normal option-switch-left;
	left: var(--start);
    width: var(--left-width);
}

@keyframes option-switch-left {
	from {
		left: var(--end);
        width: var(--right-width);
	}
	to {
		left: var(--start);
        width: var(--left-width);
	}
}