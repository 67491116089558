.property-card-note-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: var(--corner-regular);
    width: calc(100% - var(--corner-regular) * 2);
    border-radius: var(--corner-regular);
    background-color: var(--grayscale-gray-00);
    overflow: hidden;
}