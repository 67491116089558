.properties-outer-container {

}

.properties-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    overflow: hidden;
    height: calc(100vh - 56px);
}

.properties-body-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 32px;
    overflow-y: scroll;
    width: calc(100% - 280px);
    gap: 32px;
}

.properties-inner-body-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
}

.property-top-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
}

.properties-top-bar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.properties-chips-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
}

.properties-filters-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.properties-search-icon {
    width: 16px;
}

.properties-sort-container {
    width: 91px;
}

.properties-sort-container .dropdown-options-container {
    width: 120px;
}

.properties-sort-container .dropdown-outer-container {
    height: 14px;
}

.properties-sections-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
    width: 100%;
}

.properties-outer-container .custom-swiper-outer-container {
	margin-right: 0px;
	margin-left: 0px;
	width: auto;
}

.properties-outer-container .modal-outer-container {
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    z-index: 13;
    position: fixed;
    width: calc(100% - 16px);
}

.properties-outer-container .toast-outer-container {
    width: 392px;
    position: fixed;
    right: 2.5%;
    bottom: 15%;
    z-index: 14;
}

.properties-zero-state-icon {
    width: 32px;
    height: 32px;
}

.properties-sections-container .zero-state-inner-container .primary-button {
    max-width: 129px;
}

.properties-inner-body-container .add-to-list-modal .modal-body-container {
	max-height: calc(100dvh - 425px);
	overflow-y: scroll;
}

@media only screen and (max-width: 999px) {
    .properties-body-container {
        padding: 16px;
        width: calc(100% - 32px);
    }

    .properties-top-bar-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 24px;
    }

    .properties-chips-container {
        width: 100%;
        overflow-x: scroll;
        white-space: nowrap;
    }

    .properties-sections-container .zero-state-inner-container .primary-button {
        max-width: 170px;
    }
}