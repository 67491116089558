.filters-modal-body-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    gap: 24px;
}

.filters-modal-categories-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 184px;
}

.filters-modal-content-container {
    width: calc(100% - 184px);
    max-height: 450px;
    overflow-y: scroll;
}

.property-type-units-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.property-type-heading-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.property-type-units-selector {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.filters-buttons-right-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    white-space: nowrap;
}

@media only screen and (max-width: 999px) {
    
    .new-property-list-outer-container .filters-modal-container {
        top: 110px !important;
        height: calc(100% - 110px);
    }

    .filters-modal-container .modal-body-container {
        padding-top: 0px;
        overflow-x: hidden;
    }

    .filters-modal-body-row {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
    }

    .filters-modal-mobile-tabs-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        overflow-x: scroll;
        white-space: nowrap;
        gap: 32px;
        width: 100%;
    }

    .filters-modal-content-container {
        width: 100%;
        max-height: unset;
    }
    
    .filters-buttons-right-container {
        gap: 8px;
    }
}