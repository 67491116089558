.report-financials-bug-modal-outer-container {
	border-radius: 3px;
	background-color: var(--beige-beige-01);
	box-shadow: 0px 0px 14px 0px rgba(49, 27, 2, 0.17);
	padding: 24px 48px;
	padding-bottom: 0px;
	position: absolute;
	z-index: 2;
	bottom: 10%;
	left: -70%;
}

.report-financials-bug-modal-inner-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.report-financials-bug-modal-exit-icon {
	right: 5%;
	top: 5%;
	position: absolute;
	z-index: 3;
	width: 16px;
	cursor: pointer;
}

.report-financials-bug-modal-title {
	font-family: var(--font-family);
	font-size: 32px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	color: var(--blue-blue-03);
	margin: 0px;
}

.report-financials-bug-modal-text {
	font-family: var(--font-family);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	color: var(--grayscale-black);
	margin-top: 24px;
	margin-bottom: 0px;
}

.report-financials-bug-modal-body-container {
	margin-top: 24px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
}

.report-financials-bug-modal-body-title {
	font-family: var(--font-family);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px;
	color: var(--grayscale-black);
	margin-top: 0px;
	margin-bottom: 8px;
}

.report-financials-bug-button-container {
	padding-top: 70px;
	padding-bottom: 8px;
	margin-left: auto;
	margin-right: 0px;
	width: 100px;
}

@media only screen and (min-width: 900px) {
	.report-financials-bug-modal-outer-container {
		width: 600px;
	}	
}

@media only screen and (max-width: 899px) {
	.report-financials-bug-modal-outer-container {
		width: 85%;
		padding: 24px;
		padding-bottom: 0px;
	}

	.report-financials-bug-button-container {
		padding-top: 16px;
	}
}