.monthly-cash-flow-badge-outer-container {
    border-radius: 3px;
    box-shadow: 0px 0px 10px 0px rgba(78, 42, 0, 0.10);
}

.monthly-cash-flow-badge-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 6px;
}