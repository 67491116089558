.recommended-tab-container {
    border-radius: 4px;
    width: 95px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    top: -14px;
}

.recommended-tab-inner-container {
    padding: 4px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.recommended-strategy-0,
.recommended-strategy-1 {
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
}

.recommended-strategy-2 {
    left: -12px;
}

.recommended-strategy-3 {
    left: -7px;
}