.email-verification-outer-container {
	background-color: var(--beige-beige-01);
}

.email-verification-inner-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 40px;
	padding-bottom: 150px;
	width: 90vw;
	margin: 0 auto;
	max-width: 1400px;
	min-height: 80vh;
}

.email-verification-title-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.email-verification-title-text {
	font-family: var(--font-family);
	font-size: 56px;
	font-style: italic;
	font-weight: 700;
	line-height: 66px;
	letter-spacing: 0em;
	text-align: center;
	color: var(--grayscale-black);
	margin-top: 0px;
	margin-bottom: 16px;
}

.email-verification-subtitle-text {
	margin: 0px;
	font-family: var(--font-family);
	font-size: 32px;
	font-weight: 600;
	line-height: 38px;
	letter-spacing: 0em;
	text-align: center;
	color: var(--grayscale-black);
}

.email-verification-button-container {
	width: 100%;
	max-width: 250px;
	margin-top: 24px;
}

.email-verification-subtitle-text-anchor {
	font-weight: 700;
	text-decoration-line: underline;
}

@media only screen and (max-width: 767px) {
	.email-verification-inner-container {
		flex-direction: column;
		padding-top: 20px;
		padding-bottom: 80px;
	}

	.email-verification-title-text {
		font-size: 36px;
		line-height: 40px;
	}
}