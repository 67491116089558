.ai-summary-outer-container {
    border-radius: var(--corner-regular);
    border: 1px solid var(--purple-purple-02);
    background: rgba(60, 68, 255, 0.05);
    width: 100%;
    max-width: 601px;
}

.ai-summary-inner-container {
    padding: 32px 16px;
    width: calc(100% - 32px);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
}

.ai-summary-icon {
    width: 24px;
    height: 24px;
}

.ai-summary-text-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}