.pricing-radio-outer-container {
    border-radius: 3px;
    background-color: var(--grayscale-white);
    border: 1px solid var(--blue-blue-01);
    box-shadow: 0px 0px 8px 0px rgba(153, 149, 145, 0.50);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 220px;
    cursor: pointer;
    height: 172px;
}

.pricing-radio-icon {
    width: 20px;
}

.pricing-radio-selected {
    border: 2px solid var(--blue-blue-03);
}

.pricing-radio-inner-container {
    padding: 16px 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
}

.pricing-radio-text-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.pricing-radio-icon-container {
    background-color: var(--beige-beige-02);
    width: 32px;
    height: 32px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media only screen and (min-width: 1300px) {
    .pricing-radio-arrow-container {
        display: none;
    }
}

@media only screen and (max-width: 1299px) {
    .pricing-radio-outer-container {
        width: 100%;
        max-width: 392px;
        height: unset;
    }

    .pricing-radio-inner-container {
        width: calc(100% - 48px);
        padding: 24px;
        justify-content: space-between;
    }

    .pricing-radio-arrow-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: auto;
        margin-bottom: auto;
    }

    .pricing-radio-radio-container {
        display: none;
    }

    .pick-plan-mobile-selected-option .pricing-radio-inner-container {
        gap: unset;
    }

    .pick-plan-mobile-selected-option .pricing-radio-arrow-container {
        display: none;
    }
}