.off-market-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 432px;
    height: 343px;
    border-radius: 4px;
    background-color: var(--grayscale-gray-01);
}

.off-market-image-icon {
    width: 32px;
    margin-bottom: 4px;
}

.off-market-image-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.off-market-image-inner-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.off-market-image-container-arrow {
    width: 12px;
    margin-left: 4px;
}

@media only screen and (max-width: 999px) {
    .off-market-image-container {
        width: 100%;
        max-width: 200px;
        height: 200px;
        margin-top: 16px;
    }
}