.selected-interest-point-outer-container {
    width: 209px;
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: var(--grayscale-white);
    border-radius: 14px;
    box-shadow: 0px 0px 10px 0px rgba(116, 142, 160, 0.50);
}

.interest-point-element-container {
    position: relative;
}

.selected-interest-point-outer-container {
    position: absolute;
    z-index: 3;
    left: -108px;
    top: -130px;
}

.selected-interest-point-inner-container {
    width: 100%;
    position: relative;
}

.interest-point-beak {
    position: absolute;
    z-index: 5;
    bottom: -38px;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    transform: rotate(180deg);
}

.map-interest-point-icon {
    cursor: pointer;
}

.selected-interest-point {
    width: 24px;
    height: 29px;
}