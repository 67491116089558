.view-split-outer-container {
    border-radius: var(--corner-regular);
    border: 1px solid var(--blue-blue-03);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.view-split-left-element-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: var(--corner-regular);
    gap: var(--corner-regular);
    border-right: 1px solid var(--blue-blue-03);
    width: 50%;
}

.view-split-right-element-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: var(--corner-regular);
    gap: var(--corner-regular);
    width: 50%;
}

.view-split-selected {
    background: var(--grayscale-gray-01);
}

.view-split-left-element-selected {
    border-radius: 7px 0px 0px 7px;
}

.view-split-right-element-selected {
    border-radius: 0px 7px 7px 0px;
}