.distress-flag-badge-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: var(--corner-small);
    height: 28px;
    padding: 0px 8px;
    background-color: var(--grayscale-white);
    box-shadow: 0px 0px 10px 0px rgba(78, 42, 0, 0.10);
}

.distress-flag-emoji {
    font-size: 12px;
}