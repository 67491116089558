.sticky-roi-outer-container {
	position: fixed;
	z-index: 10;
	top: 54px;
	left: 0px;
	width: 100%;
	background-color: rgba(255, 254, 252, 0.7);
	backdrop-filter: blur(3px);
}

.partner-view-bar-visible .sticky-roi-outer-container {
	top: 100px;
}

.sticky-roi-inner-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: calc(100% - 96px);
	margin: 0 auto;
	padding: 16px 0px;
	max-width: 1800px;
}

.sticky-roi-price-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 8px;
	flex-wrap: wrap;
}

.sticky-roi-coc-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.sticky-roi-lock-icon {
	width: 12px;
}

.sticky-roi-element-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.sticky-roi-coc-label {
	font-family: var(--font-family);
	font-size: 16px;
	font-weight: 600;
	line-height: 19px;
	letter-spacing: 0em;
	text-align: left;
	color: var(--grayscale-black);
	margin: 0px;
}

.sticky-roi-coc-value,
.sticky-roi-costs-value {
	font-family: var(--font-family);
	font-size: 40px;
	font-weight: 700;
	line-height: 47px;
	letter-spacing: 0em;
	text-align: left;
	margin: 0px;
}

.sticky-roi-costs-value {
	color: #CC564B;
}

.sticky-roi-coc-red .sticky-roi-coc-value {
	color: var(--red-red-01);
}

.sticky-roi-coc-green .sticky-roi-coc-value {
	color: var(--green-green-02);
}

.sticky-roi-coc-amber .sticky-roi-coc-value {
	color: var(--yellow-yellow-01);
}

.new-property-interactive-buttons-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 16px;
}

.sticky-roi-download-csv-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
	margin-right: 24px;
	position: relative;
}

.sticky-roi-download-icon,
.sticky-roi-heart-icon {
	width: 24px;
	height: 24px;
}

.sticky-roi-download-text {
	font-family: var(--font-family);
	font-size: 16px;
	font-weight: 600;
	line-height: 19px;
	letter-spacing: 0em;
	text-align: left;
	color: var(--blue-blue-03);
}

.sticky-roi-save-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.sticky-roi-add-favourites-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-right: 24px;
	cursor: pointer;
	position: relative;
}

.sticky-roi-heart {
	margin-right: 12px;
	width: 20px;
}

.sitcky-roi-unlock-container {
	width: 250px;
}

.lock-white-icon {
	width: 14px;
	margin-right: 12px;
}

.sticky-roi-pending-status {
	background-color: rgba(41, 33, 33, 0.80);
}

.sticky-roi-pending-status .sticky-roi-coc-element .label-semibold-caps {
	color: #FFFEFC;
}

.sticky-roi-pending-status .new-property-favourite,
.sticky-roi-pending-status .new-property-download {
	filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}

.sticky-roi-outer-container  .infinite-cocr-container .default-tooltip-container {
	top: 40px;
}

@media only screen and (max-width: 999px) {
	.sticky-roi-outer-container {
		top: 78px;
	}

	.sticky-roi-inner-container {
		width: calc(100% - 32px);
	}

	/* .new-property-interactive-buttons-container .icon-button-element:first-of-type {
		display: none;
	} */
}