.or-divider-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 100%;
}

.or-divider-line {
    height: 1px;
    width: 100%;
    background-color: var(--grayscale-gray-00);
}