.thanksgiving-countdown-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.thanksgiving-countdown-inner-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.garland-img {
    /* position: absolute;
    z-index: 1;
    bottom: -15px; */
    width: 90%;
    max-width: 450px;
    margin: 4px auto;
}

.thanksgiving-countdown-numbers-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.thanksgiving-countdown-numbers-element {
    margin: 0px 8px;
}

.thanksgiving-countdown-shape-img {
    width: 34px;
}

@media only screen and (max-width: 999px) {
    .thanksgiving-countdown-outer-container,
    .thanksgiving-countdown-inner-container {
        align-items: center;
    }

    .garland-img {
        margin: 4px 0px;
    }
}