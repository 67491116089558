@media only screen and (min-width: 1000px) {
    .mobile-financials-outer-container {
        display: none;
    }
}

@media only screen and (max-width: 999px) {
    .new-property-mobile-bar-active {

    }

    .mobile-financials-outer-container {
        height: 75vh;
        width: 100%;
        position: absolute;
        z-index: 9;
        bottom: 0%;
        background-color: var(--grayscale-white);
        border-radius: 8px 8px 0px 0px;
        box-shadow: 0px 2px 12px 0px rgba(207, 188, 173, 0.50);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        left: 0px;
    }

    .mobile-financials-outer-container-hide-overlay {
        bottom: -1400%;
        animation: 0.5s ease-in-out normal mobile-financials-overlay-slideout;
        border-radius: 40px 40px 0px 0px;
        overflow-y: hidden;
    }

    .mobile-financials-outer-container-show-overlay {
		animation: 0.5s ease-in-out normal mobile-financials-overlay-slidein;
	}

    .mobile-financials-inner-container {
        width: calc(100% - 24px);
        padding: 16px 0px;
        padding-bottom: 140px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow-x: hidden;
        margin: 0 auto;
    }

    .mobile-financials-options-row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 0px;
        border-bottom: 0.5px solid var(--grayscale-gray-02);
        width: 100%;
        flex-wrap: wrap;
    }

    @media only screen and (max-width: 399px) {
        .mobile-financials-options-row .chip-container:last-of-type {
            margin-top: 8px;
        }
    }

    .mobile-financials-sidepanel-element {
        list-style-type: none;
        cursor: pointer;
        margin-right: 24px !important;
    }

    .mobile-financials-sidepanel-element:last-of-type {
        margin-right: 0px !important;
    }

    .mobile-financials-outer-container input[type=number] {
        -moz-appearance: textfield;
    }
    
    .mobile-financials-outer-container input::-webkit-outer-spin-button,
    .mobile-financials-outer-container input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .mobile-financials-outer-container .initial-costs-renovation-select {
        display: none;
    }

    @keyframes mobile-financials-overlay-slidein {
		from {
		  bottom: -1400%;
		}
	  
		to {
		  bottom: 0%;
		}
	}
	
	@keyframes mobile-financials-overlay-slideout {
		from {
			bottom: 0%;
		}
	  
		to {
		  bottom: -1400%;
		}
	}

    .mobile-financials-outer-container .mobile-financials-income-container,
    .mobile-financials-outer-container .initial-costs-outer-container,
    .mobile-financials-outer-container .recurring-costs-outer-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 80%;
        max-width: 320px;
        padding-top: 24px;
    }

    .mobile-financials-inner-container .income-new-outer-container .MuiOutlinedInput-root,
    .mobile-financials-inner-container .initial-costs-outer-container .MuiOutlinedInput-root,
    .mobile-financials-inner-container .recurring-costs-outer-container .MuiOutlinedInput-root {
        height: 48px;
    }

    .mobile-financials-outer-container .financials-panel-info-tooltip {
        display: none;
    }
}