.stripe-form-outer-container {
    position: fixed;
	z-index: 14;
	background-color: var(--beige-beige-01);
	box-shadow: 0px 0px 14px 0px rgba(49, 27, 2, 0.17);
	border-radius: 3px;
	left: 0px;
	right: 0px;
	top: 20%;
	margin-left: auto;
	margin-right: auto;
	max-width: 600px;
	width: 100%;
}

.stripe-form-inner-container {
    padding: 32px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
    position: relative;
}

.stripe-form-exit-container {
    right: 32px;
    position: absolute;
    z-index: 14;
    top: 16px;
}

.stripe-form-exit {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.stripe-form-button-container {
    margin-top: 16px;
}

@media only screen and (max-width: 999px) {
	.stripe-form-outer-container {
		top: 80px;
	}
}