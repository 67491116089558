.monthly-costs-tab-section-container {
    width: 100%;
}

.monthly-costs-tab-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(100% - 2px);
}

.monthly-costs-tab-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    padding-bottom: 16px;
}

.monthly-costs-grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    width: 100%;
    padding-bottom: 16px;
}

.monthly-costs-grid-element {
    width: 100%;
}

.monthly-costs-input-container {
    width: 100%;
}

.monthly-costs-input-container .tooltip-outer-container {
    position: absolute;
    z-index: 1;
    top: 35px;
    left: 0px;
}

.monthly-costs-input-container .tooltip-beak-container {
    display: none;
}

.monthly-costs-fix-flip-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 16px;
}

.monthly-costs-fix-flip-total-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
}

.monthly-costs-holding-period-input {
    width: 50%;
}

.holding-period-adornment p {
    color: var(--grayscale-gray-03) !important;
}

.monthly-costs-tooltip-container {
    position: absolute;
    z-index: 2;
    right: 4px;
    top: 4px;
    cursor: pointer;
}

.monthly-costs-tooltip-container .default-tooltip-beak {
    display: none;
}

.monthly-costs-tooltip-container .default-tooltip-container {
    right: unset;
    left: -200px;
    max-width: 200px;
}

.monthly-costs-total-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
}

.monthly-costs-financial-preferences-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}

.monthly-costs-fix-flip-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    width: calc(100% - 2px);
}

.monthly-costs-holding-cost-input {
    width: 50%;
}