.property-search-disclaimer-outer-container {
    border-radius: 200px;
    width: 100%;
    max-width: 1170px;
    margin: 16px auto;
}

.property-search-disclaimer-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px 32px;
}

.property-search-disclaimer-icon-container {
    margin-right: 16px;
}

.property-search-disclaimer-text-container {
    margin-right: 48px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.property-search-disclaimer-button-container {
    max-width: 233px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
}

@media only screen and (max-width: 999px) {
    .property-search-disclaimer-outer-container {
        border-radius: 8px;
        width: calc(100% - 32px);
    }

    .property-search-disclaimer-inner-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .property-search-disclaimer-button-container {
        height: unset;
        margin-top: 32px;
    }
}