.flag-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 32px;
    padding: 0px 6px;
    gap: var(--corner-small);
    flex-shrink: 0;
    position: absolute;
    z-index: 2;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 32px);
    border-radius: 3px;
}