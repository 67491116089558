.mobile-bottom-bar-outer-container {
    bottom: 54px;
    z-index: 3;
    position: fixed;
    background-color: var(--grayscale-white);
    padding: 16px;
    width: calc(100% - 32px);
    border-radius: 24px 24px 0px 0px;
    box-shadow: 0px -3px 15.8px 0px rgba(38, 41, 33, 0.15);
    cursor: pointer;
}

.mobile-bottom-bar-outer-container-new {
    bottom: 54px;
    z-index: 3;
    position: fixed;
    background-color: var(--grayscale-white);
    padding: 8px 16px;
    width: calc(100% - 32px);
    box-shadow: 0px -3px 15.8px 0px rgba(38, 41, 33, 0.15);
    cursor: pointer;
}

.mobile-bottom-bar-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding-top: 8px;
}

.mobile-bottom-bar-inner-container-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding-top: 8px;
    max-width: 510px;
}

.mobile-bottom-bar-action-button-width {
    max-width: 280px;
    width: 100%;
}

.mobile-bottom-bar-icon-container {
    border-radius: 17px;
    background-color: var(--beige-beige-01);
    box-shadow: 0px 0px 8px 0px rgba(153, 149, 145, 0.50);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 34px;
    width: 34px;
}

.mobile-bottom-bar-chevron {
    width: 24px;
}

.new-property-mobile-bar-active .mobile-bottom-bar-chevron {
    transform: rotate(180deg);
}

.mobile-bottom-bar-toggle-icon {
    border-radius: 16px;
    background-color: var(--grayscale-gray-01);
    height: 4px;
    width: 86px;
    position: absolute;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    top: 8px;
}

.mobile-bottom-bar-favourite-icon {
    width: 16px;
    height: 16px;
}

@media only screen and (min-width: 1000px) {
    .mobile-bottom-bar-outer-container {
        display: none;
    }
}