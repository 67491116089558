.report-loading-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 672px;
    margin: 0 auto;
    padding-top: 32px;
    height: 100%;
    min-height: calc(100vh - 56px);
}

.report-loading-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 32px);
}

.report-loading-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.report-loading-image {
    width: 100%;
    max-width: 200px;
}

.report-loading-steps-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 230px;
}

.report-loading-step-element {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.report-loading-icon {
    width: 16px;
    margin-right: 8px;
}

@media only screen and (max-width: 999px) {
    .report-loading-steps-container {
        max-width: 270px;
    }
}