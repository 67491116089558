.multiple-badges-outer-container {
	left: 8px;
	top: 8px;
	position: absolute;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	z-index: 2;
}

.multiple-badges-element {
	border-radius: 3px;
	background: var(--grayscale-white);
	box-shadow: 0px 0px 10px 0px rgba(78, 42, 0, 0.10);
	padding: 0px 6px;
	height: 28px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-right: 4px;
}

.multiple-badges-text {
	font-family: var(--font-family);
	font-size: 4px;
	font-style: normal;
	font-weight: 600;
	line-height: 18px;
	color: var(--grayscale-black);
	width: 0px;
}

.multiple-badges-text-none .multiple-badges-text {
	display: none;
}

@media only screen and (min-width: 1000px) {
	
	.multiple-badges-element-hover .multiple-badges-text {
		width: auto;
		display: block;
		animation: 0.25s ease-in-out normal badge-expand;
		font-size: 14px;
	}

	.multiple-badges-element-hover-out .multiple-badges-text {
		animation: 0.25s ease-in-out normal badge-exit;
		font-size: 4px;
		width: auto;
		/* display: none; */
	}

	.multiple-badges-element-hover .multiple-badges-emoji,
	.multiple-badges-element-hover .multiple-badges-icon {
		margin-right: 4px;
	}
	
	.multiple-badges-emoji {
		font-size: 14px;
	}
	
	.multiple-badges-icon {
		width: 16px;
	}
	
	@keyframes badge-expand {
		0% {
		  width: 0px;
		  display: none;
		  font-size: 0px;
		}
	
		50% {
			width: auto;
			display: block;
			font-size: 4px;
		}
	  
		100% {
		  width: auto;
		  display: block;
		  font-size: 14px;
		}
	}
	
	@keyframes badge-exit {
		0% {
			width: auto;
			display: block;
			font-size: 14px;
		}
		75% {
			width: auto;
			display: block;
			font-size: 4px;
		}
		100% {
			width: 0px;
			display: none;
			font-size: 0px;
		  }
	}
}

@media only screen and (max-width: 999px) {
	.multiple-badges-text {
		display: none !important;
	}

	.multiple-badges-icon {
		width: 16px;
	}
}