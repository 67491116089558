@media only screen and (max-width: 999px)  {
    .selected-line-container {
        border-radius: 32px;
        width: 100%;
        height: 2px;
    }
    
    .selected-line-container-visible {
        background-color: var(--grayscale-black);
    }
    
    .selected-line-container-invisbile {
        background-color: transparent;
    }
}