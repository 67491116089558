.financials-calculator-outer-wrapper {
    position: sticky;
    top: 140px;
    width: 100%;
    max-width: 664px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.financials-calculator-outer-wrapper .exit-screen-container {
    width: 100%;
    height: 100%;
}

.financials-calculator-outer-container {
    border-radius: 8px;
    background: var(--grayscale-white);
    box-shadow: 0px 2px 12px 0px rgba(207, 188, 173, 0.50);
    width: 100%;
    gap: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.financials-calculator-inner-container {
    padding: 0px 24px;
    width: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.financials-calculator-body-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.financials-calculator-tabs-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 128px;
}

.financials-calculator-section-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(100% - 160px);
    overflow-y: scroll;
    max-height: 43vh;
    padding-left: 32px;
}

.financials-calculator-summary-tab-active .financials-calculator-section-container {
    max-height: 55vh;
}

.financials-calculator-sf-section-container {
    max-height: 34vh;
}

.new-property-blurred .financials-calculator-title-container .body-semibold,
.new-property-blurred .financials-input-long-width,
.new-property-blurred .mobile-financials-income-container h3 span {
    filter: blur(1px);
}

.new-property-blurred .financials-calculator-footer-body-right-container h2,
.new-property-blurred .financials-calculator-footer-body-right-container .sticky-roi-coc-element h2,
.new-property-blurred .sticky-roi-inner-container .sticky-roi-coc-element h2,
.new-property-blurred .top-pick-element-inner-container,
.new-property-blurred .mobile-bottom-bar-element-container h2,
.new-property-blurred .financials-calculator-rent-accordion {
    filter: blur(3px);
}

.new-property-blurred .sticky-roi-coc-container .sticky-roi-coc-element:first-of-type h2,
.new-property-blurred .mobile-bottom-bar-element-container:first-of-type h2 {
    filter: blur(0px);
}

.new-property-blurred .initial-costs-renovation-select,
.new-property-blurred .initial-costs-renovation-radios,
.new-property-blurred .report-financials-bug-inner-container .colour-link {
    cursor: not-allowed;
}

.financials-calculator-outer-wrapper-fullscreen {
    position: fixed;
    z-index: 99;
    top: 78px;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 96px);
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.financials-calculator-outer-wrapper-fullscreen .financials-calculator-outer-container {
    height: calc(100vh - 94px);
}

.financials-calculator-outer-wrapper-fullscreen .financials-calculator-inner-container {
    height: 100%;
}

.financials-calculator-outer-wrapper-fullscreen .financials-calculator-section-container {
    max-height: calc(100vh - 370px);
}

.financials-calculator-outer-wrapper-fullscreen.financials-calculator-summary-tab-active .financials-calculator-section-container {
    max-height: calc(100vh - 308px);
}

.financials-calculator-outer-wrapper .onboarding-tooltip-third-step,
.financials-calculator-outer-wrapper-fullscreen .onboarding-tooltip-third-step {
    position: absolute;
    z-index: 2;
    left: -170px;
    top: 265px;
}

.financials-calculator-outer-wrapper .onboarding-tooltip-third-step-sf,
.financials-calculator-outer-wrapper-fullscreen .onboarding-tooltip-third-step-sf {
    position: absolute;
    z-index: 2;
    left: -170px;
    top: 85px;
}

.financials-calculator-outer-wrapper .onboarding-tooltip-third-step-sub {
    position: absolute;
    z-index: 2;
    left: -170px;
    top: 265px;
}

.financials-calculator-outer-wrapper-fullscreen .onboarding-tooltip-third-step-sub {
    position: absolute;
    z-index: 2;
    left: -170px;
    top: 265px;
}

.financials-calculator-outer-wrapper .onboarding-tooltip-fourth-step {
    position: absolute;
    z-index: 2;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    bottom: 120px;
}

@media only screen and (max-width: 999px) {
    .financials-calculator-outer-wrapper {
        display: none;
    }
}