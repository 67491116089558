.unable-to-accept-inner-container {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
    gap: 24px;
}

.unable-to-accept-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding-top: 32px;
    max-width: 438px;
}

.unable-to-accept-button-container {
    width: 100%;
    max-width: 150px;
}

@media only screen and (max-width: 999px) {
    .unable-to-accept-button-container {
        max-width: 200px;
    }
}