.no-credits-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 80vh;
    margin: 0 auto;
    padding-top: 32px;
}

.no-credits-title-container {
    max-width: 450px;
    margin: 0 auto;
}

.no-credits-button-container {
    width: 180px;
}