.combo-list-outer-container {
    border-radius: var(--corner-regular);
    background-color: var(--grayscale-white);
    box-shadow: 0px 8px 24px 8px rgba(41, 38, 33, 0.15);
    position: absolute;
    z-index: 11;
}

.combo-list-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.combo-list-element-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.combo-list-element-container:hover {
    background-color: var(--blue-blue-0130);
}

.combo-list-element-left-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.combo-list-element-container-no-icons {
    padding: 10px 16px;
    width: calc(100% - 32px);   
}

.combo-list-element-container-no-icon {
    padding: 10px 0px;
    padding-left: 40px;
    padding-right: 10px;
    width: calc(100% - 50px);
}

.combo-list-element-container-icon {
    padding: 10px 16px;
    padding-right: 10px;
    width: calc(100% - 26px);
}

.combo-list-icon {
    width: 16px;
}

.combo-list-icon-right {
    display: block;
    width: 16px;
    float: right;
}

.buy-box-card-right-container {

}