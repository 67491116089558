.view-list-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    overflow: hidden;
    height: calc(100vh - 56px);
}

.view-list-body-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 32px;
    overflow-y: scroll;
    width: calc(100% - 280px);
}

.view-list-inner-body-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
}

.view-list-inner-body-container .modal-outer-container {
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    z-index: 13;
    position: fixed;
}

.view-list-outer-top-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.view-list-top-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
}

.view-list-top-right-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
}

.view-list-sort-container {
    width: 91px;
}

.view-list-sort-container .dropdown-options-container {
    width: 120px;
}

.view-list-sort-container .dropdown-outer-container {
    height: 14px;
}
.view-list-text-button-margin {
    margin-left: -12px;
}

.view-list-inner-title-row-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}

.view-list-properties-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;
    width: 100%;
}

.view-list-properties-grid .custom-swiper-outer-container {
	margin-right: 0px;
	margin-left: 0px;
	width: auto;
}

.view-list-properties-grid .modal-outer-container {
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    z-index: 13;
    position: fixed;
}

.view-list-properties-grid .new-property-card-images-swiper-container  .property-card-badge-outer-container,
.view-list-properties-grid .new-property-card-images-swiper-container  .price-cut-outer-container {
    height: unset;
}

.view-list-outer-container .toast-outer-container {
    width: 392px;
    position: fixed;
    right: 10%;
    bottom: 10%;
    z-index: 14;
}

.view-list-body-container .add-to-list-modal .modal-body-container {
	max-height: calc(100dvh - 425px);
	overflow-y: scroll;
}

@media only screen and (max-width: 999px) {
    .view-list-body-container {
        padding: 16px;
        width: calc(100% - 32px);
    }

    .view-list-properties-grid {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;
    }

    .view-list-title-container .icon-button-element {
        display: none;
    }
}

@media only screen and (max-width: 499px) {
    .view-list-properties-grid {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 24px;
    }
}