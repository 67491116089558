.property-select-container {
    width: 28px;
    height: 28px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--corner-regular);
    flex-shrink: 0;
    border: 2px solid #FFFFFF;
    background-color: transparent;
    position: absolute;
    z-index: 4;
    top: 8px;
    left: 8px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
}

.property-select-container-filled {
    background-color: var(--purple-purple-02);
}