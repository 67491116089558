.condition-badge-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: var(--corner-small);
    background-color: var(--grayscale-white);
    position: absolute;
	z-index: 2;
	left: 8px;
	top: 8px;
    height: 28px;
    padding: 0px 8px;
}