.map-market-outer-container {
    border-radius: var(--corner-regular);
    background-color: var(--grayscale-white);
    box-shadow: 0px 0px 8px 0px rgba(152, 151, 147, 0.40);
    position: absolute;
    z-index: 2;
    left: 112px;
    margin-top: 16px;
}

.map-market-inner-container {
    width: calc(100% - 16px);
    padding: 10px 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.map-market-left-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0px;
    transition: gap 0.5s ease-in-out;
}

.badge-element-free-size span {
    color: var(--grayscale-black);
}

.map-market-left-text-container {
    overflow: hidden;
    white-space: nowrap;
}

.map-market-left-text-container span {
    display: inline-block;
    max-width: 0;
    transition: max-width 0.5s ease-in-out;
}

.map-market-outer-container:hover .map-market-left-container .map-market-left-text-container span {
    max-width: 200px;
}

.map-market-outer-container:hover .map-market-left-container {
    gap: 4px;
}

@media only screen and (max-width: 999px) {
    .map-market-outer-container {
        left: 16px;
    }
}

@media only screen and (max-width: 499px) {
    .map-market-outer-container {
        display: none;
    }
}