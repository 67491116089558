.sfh-rent-house-hack-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.sfh-rent-house-hack-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
}

.sfh-rent-house-hack-dot {
    position: absolute;
    z-index: 1;
    left: -10px;
    height: 8px;
    width: 8px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
}

.sfh-rent-house-hack-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
}

.sfh-rent-house-hack-element {
    border-radius: 8px;
    border: 1px solid var(--blue-blue-01);
    cursor: pointer;
}

.sfh-rent-house-hack-element-selected {
    border: 2px solid var(--blue-blue-03);
}

.sfh-rent-house-hack-inner-element {
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.sfh-rent-house-hack-icon {
    width: 32px;
    height: 32px;
}

.sfh-rent-house-hack-text-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.sfh-rent-house-hack-total-container {
    width: calc(50% - 8px);
}

.sfh-rent-house-hack-total-label-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.sfh-rent-house-hack-tooltip-container {
    cursor: pointer;
}

.sfh-rent-house-hack-tooltip-container .default-tooltip-container {
    top: 20px;
}