.side-panel-outer-container {
    background-color: #FFFFFF;
    position: absolute;
    z-index: 2;
    right: 8px;
    top: 8px;
    height: calc(100vh - 140px);
    overflow-y: scroll;
    box-shadow: -4px 0px 10px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 322px;
}

.side-panel-animate-out {
    animation: 0.75s ease-in-out normal side-panel-out;
    right: -370px;
}

@keyframes side-panel-out {
	from {
        right: 8px;
	}
	to {
		right: -370px;
	}
}

.side-panel-animate-in {
    animation: 0.75s ease-in-out normal side-panel-in;
    right: 8px;
}

@keyframes side-panel-in {
	from {
		right: -370px;
	}
	to {
		right: 8px;
	}
}

.side-panel-hidden {
    right: -370px;
}

.side-panel-inner-container {
    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
}

.side-panel-close-container {
    right: 5%;
    top: 16px;
    position: absolute;
    z-index: 1;
}

.side-panel-close-icon {
    cursor: pointer;
    width: 16px;
}

.side-panel-body-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.side-panel-neighbourhood-grade-badge {
    padding: 4px 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
}

.side-panel-row-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.side-panel-beige-element {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 50%;
}

.side-panel-beige-inner-element {
    padding: 8px 16px;
}

.side-panel-beige-element:first-of-type {
    margin-right: 8px;
}

.side-panel-divider-line {
    width: 100%;
    background-color: var(--grayscale-gray-02);
    height: 1px;
}

.side-panel-economics-container,
.side-panel-market-container,
.side-panel-metrics-container,
.side-panel-property-attributes-container {
    padding-top: 24px;
}

.economics-vals-element-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.economics-vals-comparison-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

.economics-vals-inner-comparison-container {
    padding: 4px 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.economics-vals-comparison-icon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.side-panel-grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 auto;
    grid-gap: 24px 16px;
}

.side-panel-grid-element {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.side-panel-grid-element-title-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.side-panel-tooltip-icon-container {
    margin-left: 4px;
}

.side-panel-tooltip-icon {
    width: 16px;
}

.side-panel-outer-container .default-tooltip-container {
    width: 200px;
    right: unset;
    left: -50px;
    top: 20px;
}

.side-panel-outer-container .side-panel-tooltip-left-position {
    right: unset;
    left: -200px;
}

.economics-vals-element-container .default-tooltip-container {
    left: -60px;
}

.economics-vals-element-container:first-of-type .default-tooltip-container {
    right: unset;
    left: -100px;
}

.side-panel-citations-container {
    padding-top: 24px;
}

@media only screen and (max-width: 999px) {
    .side-panel-tooltip-icon {
        display: none;
    }
}

@media only screen and (max-width: 999px) and (min-width: 400px) {
    .side-panel-outer-container {
        height: calc(100vh - 250px);
        width: 370px;
    }
}

@media only screen and (max-width: 399px) {
    .side-panel-outer-container {
        height: calc(100vh - 250px);
        width: 95%;
    }
}