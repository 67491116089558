.property-position-icon {
    width: 24px;
    height: 24px;
}

.property-position-container {
    width: 100%;
}
  
.gm-ui-hover-effect {
	display: none !important;
}

.gm-style-iw {
    padding-left: 17px !important;
}