.upgrade-city-map-element {
    width: 100%;
    height: calc(100vh - 450px);
    border-radius: 24px;
}

.upgrade-city-map-element .loading-container {
    max-width: 100px;
}

@media only screen and (max-width: 999px) {
    .upgrade-city-map-container {
        max-width: unset;
        margin: 0 auto;
    }

    .upgrade-city-map-element {
        width: 100%;
        max-width: 460px;
        margin: 0 auto;
    }
}