.sign-up-component-outer-container {
	width: 100%;
}

.sign-up-component-inner-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	gap: 40px;
	padding-bottom: 80px;
	width: 100%;
	max-width: 432px;
	margin: 0 auto;
}

.sign-up-component-login-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.sign-up-component-body-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 24px;
	width: 100%;
}

.sign-up-component-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 24px;
	width: 100%;
}

.sign-up-component-row .sign-up-component-element-container {
	width: 100%;
}

.sign-up-component-long-element-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 8px;
}

.sign-up-component-bottom-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 24px;
	width: 100%;
}

.sign-up-component-incorrect-password-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.sign-up-component-buttons-outer-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 24px;
	width: 100%;
}

.sign-up-component-button-container,
.sign-up-component-google-button-container {
	width: 100%;
	margin: 0 auto;
}

@media only screen and (max-width: 999px) {
	.sign-up-component-row {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.sign-up-component-body-container {
		gap: 24px;
	}
}