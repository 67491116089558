.request-city-modal-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;
    z-index: 13;
    border-radius: 3px;
    background-color: var(--beige-beige-01);
    box-shadow: 0px 0px 14px 0px rgba(49, 27, 2, 0.17);
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    max-width: 620px;
}

.request-city-inner-container {
    padding: 32px 60px 0px 48px;
    position: relative;
    margin: 0 auto;
}

.request-city-exit-modal {
    right: 32px;
    position: absolute;
    z-index: 2;
    top: 16px;
    cursor: pointer;
}

.request-city-body-container {
    margin-top: 48px;
}

.request-city-error-container {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.request-city-places-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 16px;
    padding-bottom: 48px;
}

.request-city-border-bottom {
    border-bottom: 1px solid var(--grayscale-gray-02);
}

.request-city-places-element {
    border-radius: 100px;
    background-color: var(--grayscale-gray-01);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    margin-right: 8px;
    margin-top: 8px;
}

.request-city-places-element:last-of-type {
    margin-right: 0px;
}

.request-city-places-element-exit {
    cursor: pointer;
    width: 8px;
}

.request-city-email-container {
    margin-top: 48px;
}

.request-city-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 16px;
}

.request-city-inner-button-container {
    width: 250px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.request-city-submission-received-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.request-city-success-button-container {
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
}

.request-city-body-container .css-1nmdiq5-menu {
    z-index: 2;
}

@media only screen and (max-width: 999px) {
    .request-city-inner-container {
        padding: 32px 16px;
    }

    .request-city-exit-modal {
        right: 16px;
        position: absolute;
        z-index: 2;
        top: 16px;
    }

    .request-city-body-container {
        margin-top: 24px;
    }

    .request-city-places-list {
        padding-bottom: 24px;
    }

    .request-city-buttons-container {
        margin-top: 24px;
    }
}