.buy-box-market-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    width: calc(100% - 2px);
    max-width: 572px;
}

.buy-box-market-map-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.buy-box-market-input-container {
    width: 100%;
}

.buy-box-market-chosen-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 16px;
}

.buy-box-market-chosen-options-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.buy-box-market-container .dropdown-outer-container {
    width: calc(100% - 18px);
}

.buy-box-market-container .zip-code-filter-container {
    gap: 24px;
}

@media only screen and (max-width: 999px) {
    .buy-box-market-container .predictive-search-container {
        top: 47px;
    }

    .buy-box-market-container {
        padding-bottom: 80px;
    }
}