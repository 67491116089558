.home-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    overflow: hidden;
    height: calc(100vh - 56px);
}

.home-body-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 32px;
    overflow-y: scroll;
    width: calc(100% - 280px);
    gap: 80px;
}

.home-body-container .loading-container {
    top: 20%;
}

.home-inner-body-container {
    max-width: 1220px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
}

.home-title-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.home-galleries-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 48px;
}

.home-inner-container .modal-outer-container  {
    position: fixed;
    z-index: 15;
    top: 15%;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 999px) {
    .home-inner-container {
        flex-direction: column;
    }

    .home-body-container {
        width: calc(100% - 32px);
        padding-left: 16px;
    }

    .home-inner-container .modal-outer-container {
        top: 80px;
    }

    .home-inner-container .modal-outer-container .modal-body-container {
        max-height: calc(100dvh - 370px);
		overflow-y: scroll;
    }
}

@media only screen and (max-width: 499px) {
    .home-inner-container .modal-outer-container .modal-secondary-button-element {
        display: none;
    }
}