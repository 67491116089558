.owner-details-outer-container {
    width: 100%;
	padding: 48px 0px;
	border-bottom: 0.5px solid var(--grayscale-gray-02);
	max-width: 440px;
}

.owner-details-inner-container {
    width: 100%;
    display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

@media only screen and (max-width: 999px) {
    .owner-details-outer-container {
        max-width: none;
    }
}