.welcome-inner-container {
    min-height: 80vh;
    padding-top: 48px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 48px;
}

.welcome-body-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 32px;
}

.welcome-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    max-width: 368px;
}

.welcome-button-container {
   max-width: 106px;
   width: 100%;
}

@media only screen and (max-width: 999px) {
    .welcome-inner-container {
        width: calc(100% - 32px);
        margin: 0 auto;
    }

    .welcome-button-container {
        max-width: 254px;
    }
}