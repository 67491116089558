.subscription-table-outer-container {

}

.subscription-table-fixed-container {

}

.subscription-table-row-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.subscription-table-label-cell {
    padding: 8px 16px;
    min-width: 82px;
}

.subscription-table-pin-icon {
    width: 16px;
    margin-right: 4px;
    vertical-align: middle;
}

.subscription-table-cell {
    padding: 24px 16px;
    min-width: 82px;
}

.subscription-table-value-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.no-padding-left {
    padding-left: 0px !important;
}

@media only screen and (max-width: 999px) {
    .subscription-table-outer-container {
        display: none;
    }
}