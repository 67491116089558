.filter-spacing-container {
    padding: 32px 0px;
    width: 100%;
}

.filter-spacing-border {
    border-radius: 100px;
    background-color: var(--grayscale-gray-00);
    height: 1px;
    width: 100%;
    margin: 8px 0px;
}