.favourites-grid-outer-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 24px;
    margin: 0 auto;
    margin-bottom: 40px;
}

.favourites-grid-element {
    padding: 12px 24px;
    background-color: var(--grayscale-white);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.property-grid-element-selected {
    border-radius: 8px;
    border: 1px solid var(--border-border-02);
    background-color: var(--grayscale-white);
    box-shadow: 0px 0px 8px 0px rgba(152, 151, 147, 0.40);
}

.favourites-grid-element .custom-swiper-outer-container {
    width: 100%;
}

.favourites-grid-element-compare-container {
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.favourites-grid-element-compare-container .favourites-table-checkbox-container {
    margin-right: 12px;
}

@media only screen and (max-width: 1200px) and (min-width: 1000px) {
    .favourites-grid-outer-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 999px) and (min-width: 768px) {
    .favourites-grid-outer-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 767px) {
    .favourites-grid-outer-container {
        grid-template-columns: repeat(1, 1fr);
    }
}