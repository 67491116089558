.credit-card-outer-container {
    border-radius: 3px;
}

.credit-card-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.credit-card-icon {
    width: 75px;
}

.credit-card-text-container {
    margin-left: 16px;
}