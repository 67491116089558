.generate-url-inner-container {
    padding: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.generate-url-pick-city {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.generate-url-pick-city .MuiBox-root {
    width: 100%;
}

.generate-url-url-container {
    padding-top: 32px;
}

.generate-url-type-container {
    margin-top: 32px;
}

.generate-url-button-container {
    margin-top: 24px;
    width: 100%;
    max-width: 225px;
    padding-bottom: 300px;
}