.financials-panel-bug-report-container {
	position: relative;
	margin: 0 auto;
}

.report-financials-bug-inner-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}