.neighbourhood-grades-filter-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
}

.neighbourhood-grades-filter-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 8px;
}

.neighbourhood-grades-filter-row {
    width: 100%;
}

.neighbourhood-grades-filter-element {
    max-width: 116px;
    width: 100%;
}

.neighbourhood-grades-filter-container .exit-screen-container {
    width: 100%;
    height: 350px;
}

@media only screen and (max-width: 999px) {
    .neighbourhood-grades-filter-element {
        width: 100%;
        max-width: 164px;
    }

    .neighbourhood-grades-filter-container {}
}